import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AUTH_ROLE, UserModel } from '@ezspeek/models';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminHeaderComponent implements OnInit {

  @Input() user: UserModel;
  @Input() newReportCount = 0;
  @Input() newMessageCount = 0;
  @Input() pendingReportCount = 0;
  @Input() isSidebarCollapsed = false;
  @Input() hideSidebarToggle = false;
  @Input() isMenuOpen = false;

  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  get isAcctMgr(): boolean {
    return this.user && this.user.role === AUTH_ROLE.ACCOUNT_MGR;
  }

  get isAdmin(): boolean {
    return this.user && (this.user.role === AUTH_ROLE.ACCOUNT_MGR || this.user.role === AUTH_ROLE.ADMIN);
  }

  get isAuthorized(): boolean {
    return this.isAdmin && !!this.user.displayName && !!this.user.email;
  }

  get displayedNewReportsCount(): string {
    return this.newReportCount > 99 ? '99+' : '' + this.newReportCount;
  }

  get displayedNewMessageCount(): string {
    return this.newMessageCount > 99 ? '99+' : '' + this.newMessageCount;
  }

  get displayedPendingReportsCount(): string {
    return this.pendingReportCount > 99 ? '99+' : '' + this.pendingReportCount;
  }

  get showNewReportsIcon(): boolean {
    return this.isAdmin && this.newReportCount > 0;
  }

  get showNewMessageIcon(): boolean {
    return this.isAdmin && this.newMessageCount > 0;
  }

  get showPendingReportsIcon(): boolean {
    return this.isAcctMgr && this.pendingReportCount > 0;
  }
}
