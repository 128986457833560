import { StripeJSLoader, StripeJSScriptStatus } from './services/stripe-js-loader';

import {
  StripeJS,
  StripeOptions,
  StripeSourceResult,
  StripeSourceParams,
  StripeSourceData,
  BankAccount,
  Pii,
  StripeElements,
  StripeElementsOptions,
  BankAccountData, PiiData, CardDataOptions, StripeTokenResult,
  PaymentRequestOptions, StripeElement, StripeElementOptions
} from './models';

import { StripeUtils } from './stripe.utils';
import { StripeServiceInterface } from './services/stripe.service';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

type TokenData = CardDataOptions | BankAccountData | PiiData;

export class StripeInstance implements StripeServiceInterface {
  private stripe$: BehaviorSubject<StripeJS | undefined> = new BehaviorSubject<
    StripeJS | undefined
    >(undefined);

  constructor(
    private loader: StripeJSLoader,
    private window: Window,
    private key: string,
    private options?: StripeOptions
  ) {
   this._init(key, options);
  }

  private async _init(key: string, options?: StripeOptions) {
    const stripe: StripeJS = await this.loader.getStripeJS(key, options);
    this.stripe$.next(stripe);
  }

  public elements(options?: StripeElementsOptions): Observable<StripeElements> {
    return this._waitForStripeJS().pipe(
      map(stripe => (stripe as StripeJS).elements(options))
    );
  }

  public createToken(a: StripeElement | BankAccount | Pii, b: TokenData ): Promise<StripeTokenResult> {
    return this._waitForStripeJS().pipe(
        switchMap((stripe: StripeJS) => {
          return from(stripe.createToken(a as any, b as any));
        })
      ).toPromise();
  }

  public createSource(a: StripeElement | StripeSourceData, b?: StripeSourceData): Promise<StripeSourceResult> {
    return this._waitForStripeJS().pipe(
      switchMap((stripe: StripeJS) => {
        if (StripeUtils.isSourceData(a)) {
          return from(stripe.createSource(a as StripeSourceData));
        }
        return from(stripe.createSource(a as StripeElement, b));
      })
    ).toPromise();
  }

  public retrieveSource(source: StripeSourceParams): Observable<StripeSourceResult> {
    return this._waitForStripeJS().pipe(
      switchMap((stripe: StripeJS) => {
        return from(stripe.retrieveSource(source));
      })
    );
  }

  public paymentRequest(options: PaymentRequestOptions) {
    const stripe = this.get();
    if (stripe) {
      return stripe.paymentRequest(options);
    }
    return undefined;
  }

  get(): StripeJS | undefined {
    return this.stripe$.getValue();
  }

  get instancePromise(): Promise<StripeJS> {
    return this.stripe$.asObservable().pipe(first(s => Boolean(s))).toPromise();
  }

  get fromWindow(): Observable<any> {
    return this.loader.Stripe;
  }

  private _waitForStripeJS(): Observable<StripeJS> {
    return this.stripe$.asObservable().pipe(
      first(stripe => Boolean(stripe)),
      map(stripeJS => stripeJS)
    );
  }
}
