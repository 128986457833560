import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardContainer} from './admin/dashboard/dashboard.container';
import { SignUpContainer } from './sign-up/sign-up.container';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { ReportFormContainer } from './client/report-form/report-form.container';
import { AdminRouter } from './admin/admin.router';
import { ClientRouter } from './client/client.router';
import { AdminReportsContainer } from './admin/reports/admin-reports.container';
import { StatsContainer } from './admin/stats/stats.container';
import { UsersContainer } from './admin/users/users.container';
import { AdminSignInContainer } from './admin/sign-in/admin-sign-in.container';
import { ClientSignInContainer } from './client/sign-in/client-sign-in.container';
import { HomeContainer } from './home/home.container';
import {AccountActiveGuard, AdminGuard, ClientGuard, RegistrationIncompleteGuard, UnauthenticatedGuard} from './guards/auth.guard';
import { ClientDashboardContainer } from './client/dashboard/dashboard.container';
import { ClientReportsContainer } from './client/reports/client-reports.container';
import { AccountRegistrationContainer } from './admin/registration/account-registration.container';
import { ContinueRegistrationContainer } from './admin/registration/continue/continue-registration.container';
import { EmailRegistrationContainer } from './admin/registration/email/email-registration.container';
import { AlertsContainer } from './admin/alerts/alerts.container';

const routes: Routes = [
  { path: 'home', component: HomeContainer, data: { animation: 'home' } },
  { path: 'sign-up', component: SignUpContainer, canActivate: [ UnauthenticatedGuard ], data: { animation: 'sign-up' } },
  { path: 'inactive', component: HomeContainer },
  {
    path: 'client',
    component: ClientRouter,
    children: [
      { path: 'dashboard', component: ClientDashboardContainer, canActivate: [ ClientGuard ], data: { animation: 'dashboard' } },
      { path: 'report-form', component: ReportFormContainer, canActivate: [ ClientGuard ], data: { animation: 'report-form' } },
      {
        path: 'reports',
        children: [
          { path: '', component: ClientReportsContainer, canActivate: [ ClientGuard ] },
          { path: ':reportId', component: ClientReportsContainer, canActivate: [ ClientGuard ] }
        ]
      },
      { path: 'sign-in', component: ClientSignInContainer, canActivate: [ UnauthenticatedGuard ] },
      { path: '', redirectTo: '/client/sign-in', pathMatch: 'full' },
    ]
  },
  {
    path: 'admin',
    component: AdminRouter,
    children: [
      { path: 'dashboard', component: DashboardContainer, canActivate: [ AdminGuard, AccountActiveGuard ], data: { animation: 'dashboard' } },
      {
        path: 'reports',
        children: [
          { path: '', component: AdminReportsContainer, canActivate: [ AdminGuard, AccountActiveGuard ] },
          { path: ':reportId', component: AdminReportsContainer, canActivate: [ AdminGuard, AccountActiveGuard ] }
        ]
      },
      { path: 'stats', component: StatsContainer, canActivate: [ AdminGuard, AccountActiveGuard ] },
      { path: 'users', component: UsersContainer, canActivate: [ AdminGuard, AccountActiveGuard ] },
      { path: 'alerts', component: AlertsContainer, canActivate: [ AdminGuard, AccountActiveGuard ] },
      { path: 'sign-in', component: AdminSignInContainer, canActivate: [ UnauthenticatedGuard ] },
      { path: 'registration', component: AccountRegistrationContainer},
      { path: 'email-registration', component: EmailRegistrationContainer, canActivate: [ UnauthenticatedGuard ] },
      { path: 'continue-registration', component: ContinueRegistrationContainer, canActivate: [ RegistrationIncompleteGuard ]},
      { path: '', redirectTo: '/admin/sign-in', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'report-form', redirectTo: '/client/report-form', pathMatch: 'full' },
  { path: 'dashboard', redirectTo: '/admin/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const ROUTING_ANIMATIONS = [
  trigger('routerAnimation', [
    transition('* <=> *', [
      // Initial state of new route
      query(':enter',
        style({
          position: 'absolute',
          opacity: 0,
          transform: 'scale(0)',
          left: 0,
          right: 0
        }),
        {optional: true}),

      // fade out
      query(':leave',
        animate('500ms ease-in',
          style({
            position: 'absolute',
            opacity: 0,
            transform: 'scale(2)',
            left: 0,
            right: 0
          })
        ),
        {optional: true}),

      // fade in
      query(':enter',
        animate('500ms ease-out',
          style({
            opacity: 1,
            transform: 'scale(1)'
          })
        ),
        {optional: true}),
    ])
  ])
];
