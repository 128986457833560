import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductFeatures} from '@ezspeek/models/stripe.models';
import {ChartConfig, ChartData} from '@ezspeek/models/chart.models';
import {REQUEST_STATUS} from '@ezspeek/models';
import {FormBuilder, FormGroup} from '@angular/forms';

export enum StatsInterval {
  ALL_TIME,
  YEAR,
  SIX_MONTHS,
  THREE_MONTHS,
  THIRTY_DAYS
}

export interface Statistics {
  type: string;
  interval: StatsInterval;
}

@Component({
  selector: 'ezs-statistics',
  template: `
    <div *ngIf="enabled" class="ezs-page-container">
      <!--<mat-card class="statistics-options">
        <h2 class="stats-heading">Enter options to generate a <span>Statistics Chart.</span></h2>
        <form *ngIf="!!form">
          
        </form>
      </mat-card>

      <div [class.showing-chart]="showChart" class="monthly-stats-wrapper ezs-chart-container">
        <ezs-chart *ngIf="showChart" [data]="chartData" [config]="chartConfig"></ezs-chart>
      </div>-->
      <div class="feature-unavailable-message">
        <h2>The Statistics feature is still under development, but will be available soon</h2>
      </div>
    </div>
    <div *ngIf="!enabled" class="feature-unavailable-message">
      <h2>Uh oh! Statistics are not included with your current plan.</h2>
      <button
        mat-raised-button
        color="primary"
        class="button button-accent2"
        [routerLink]="['/admin', 'dashboard']">Go To Dashboard</button>     
    </div>
  `,
  styleUrls: ['./stats.scss']
})
export class StatsComponent implements OnInit {
  @Input() accountFeatures: ProductFeatures;
  @Input() status: REQUEST_STATUS;
  @Input() data: any;

  @Output() getStats: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      stat: []
    });
  }

  get chartData(): ChartData {
    return null;
  }
  get chartConfig(): ChartConfig {
    return null;
  }

  get enabled(): boolean { return !!this.accountFeatures && this.accountFeatures.stats; }
  get showChart(): boolean { return true; }
}
