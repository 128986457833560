
    <div class="filters" *ngIf="!isClientView">
      <mat-chip-list class="bordered-list-wrapper" [multiple]="true">
        <mat-chip (click)="toggleFilter('new')" class="mat-chip-accent2-light" [selected]="showNew">
          New <mat-icon *ngIf="showNew">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('viewed')" [selected]="showViewed">
          Viewed <mat-icon *ngIf="showViewed">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('active')" class="mat-chip-primary" [selected]="showActive">
          Active <mat-icon *ngIf="showActive">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('pending')" class="mat-chip-caution" [selected]="showPending">
          Pending <mat-icon *ngIf="showPending">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('resolved')" class="mat-chip-success" [selected]="showResolved">
          Resolved <mat-icon *ngIf="showResolved">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('false_report')" class="mat-chip-warn" [selected]="showFalseReports">
          False Report <mat-icon *ngIf="showFalseReports">close</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <mat-slide-toggle class="mat-slide-toggle-sm ezs-accent2"
                        [checked]="showArchived"
                        (change)="toggleArchived.emit()">
        <span>Show Archived</span>
      </mat-slide-toggle>
      <mat-slide-toggle class="mat-slide-toggle-sm ezs-alert"
                        [checked]="applyNewMessageFilter"
                        (change)="toggleNewMessageFilter.emit()">
        <span>New Messages</span>
      </mat-slide-toggle>
    </div>
    <mat-card *ngIf="hasReports" class="reports-list fadeInUp animated">
      <mat-card-content>
        <ezs-reports-list-item 
          *ngFor="let report of filteredReports" 
          [report]="report" 
          [authRole]="user.role">
        </ezs-reports-list-item>
        <div *ngIf="showNoFilteredResultsMessage" [class.client-message]="isClientView" class="no-results-message">
          <h2>There are no incident reports that match the current filters</h2>
        </div>
      </mat-card-content>
    </mat-card>
    <div *ngIf="showNoReportsMessage" [class.client-message]="isClientView" class="no-reports-message">
      <h2>{{noReportsMessage}}</h2>
      <button
        *ngIf="isClientView"
        mat-raised-button
        color="primary"
        class="button"
        [routerLink]="[routeRoot, 'report-form']">Report An Incident</button>
      <button 
        mat-raised-button 
        color="primary"
        class="button button-accent2"
        [routerLink]="[routeRoot, 'dashboard']">Go To Dashboard</button>
    </div>
  