import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReportModel } from '@ezspeek/models';

@Component({
  selector: 'client-dashboard',
  templateUrl: './client-dashboard.html',
  styleUrls: ['./client-dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDashboardComponent {
  @Input() reports: ReportModel[];
  @Input() displayedKeyword: string;
  @Input() keywordColorClass: string;

  private _enableVoiceChat = false;

  constructor() {}

  get reportCount(): number { return this.reports ? this.reports.length : null; }

  get reportsButtonColor(): string { return this.reportCount && this.reportCount > 0 ? 'accent' : 'disabled'; }

  get voiceChatEnabled(): boolean { return this._enableVoiceChat; }

  get hasNewMessage(): boolean {
    if (!this.reports)
      return false;

    return this.reports.filter(report => report.newMessageFromAdmin).length > 0;
  }
}
