import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminRouter } from './admin.router';
import { AdminHeaderComponent } from './header/admin-header.component';
import { DashboardContainer } from './dashboard/dashboard.container';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppCommonModule } from '@ezspeek/common/app-common.module';
import { AdminSidebarComponent } from './sidebar/admin-sidebar.component';
import { AdminReportsContainer } from './reports/admin-reports.container';
import { StatsContainer } from './stats/stats.container';
import { StatsComponent } from './stats/stats.component';
import { UsersContainer } from './users/users.container';
import { UsersComponent } from './users/users.component';
import { AccountSettingsContainer } from './settings/account-settings.container';
import { AccountSettingsComponent } from './settings/account-settings.component';
import { AdminSignInContainer } from './sign-in/admin-sign-in.container';
import { AdminSignInComponent } from './sign-in/admin-sign-in.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { UserRowComponent } from './users/user-row.component';
import { AdminRegistrationModule } from './registration/admin-registration.module';
import { AlertsContainer } from './alerts/alerts.container';
import { AlertsComponent } from './alerts/alerts.component';

const DECLARATIONS = [
  AdminRouter,
  AdminHeaderComponent,
  AdminSidebarComponent,
  DashboardContainer,
  DashboardComponent,
  AdminReportsContainer,
  StatsContainer,
  StatsComponent,
  UsersContainer,
  UsersComponent,
  UserRowComponent,
  AccountSettingsContainer,
  AccountSettingsComponent,
  AdminSignInContainer,
  AdminSignInComponent,
  ProfileModalComponent,
  AlertsContainer,
  AlertsComponent
];

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    AppCommonModule,
    RouterModule,
    AdminRegistrationModule,
  ],
  exports: [
    ...DECLARATIONS
  ]
})
export class AdminModule {}
