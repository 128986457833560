import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { UserModel } from '@ezspeek/models';

@Component({
  selector: 'client-header',
  template: `
    <header [class.scrolled]="displayScrolled || !!user">
      <nav class="navbar">
        <span class="spacer"></span>
        <a [routerLink]="['/home']" class="brand">
          <img src="assets/images/ezSpeekLogo2.svg" alt="ezspeek"> ez<span>Speek</span>
        </a>
        <span class="spacer"></span>
      </nav>
    </header>
  `,
  styleUrls: ['./client-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientHeaderComponent {

  @Input() user: UserModel;
  @Input() displayScrolled: boolean;

  constructor() { }
}
