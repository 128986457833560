import { REPORT_STATUS } from '@ezspeek/models';
import { AbstractControl, FormGroup } from '@angular/forms';

export function getReportStatusText(status: REPORT_STATUS): string {
  switch (status) {
    case REPORT_STATUS.NEW:
      return 'New';
    case REPORT_STATUS.VIEWED:
      return 'Viewed';
    case REPORT_STATUS.ACTIVE:
      return 'Active';
    case REPORT_STATUS.PENDING:
      return 'Pending';
    case REPORT_STATUS.RESOLVED:
      return 'Resolved';
    case REPORT_STATUS.FALSE_REPORT:
      return 'False Report';
    default:
      return 'N/A';
  }
}

export function isEmptyString(value: string): boolean {
  return typeof value === 'string' && value === '';
}

export function updateControls(controlGroup: any, onlySelf: boolean = false) {
  const group: FormGroup = <FormGroup>controlGroup;

  if (group.controls) {
    for (const ctrl in group.controls) {
      group.controls[ctrl].updateValueAndValidity({ onlySelf: onlySelf });
    }
  }
}

export function runDiscreteValidation(control: AbstractControl) {
  control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
}
