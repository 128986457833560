import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Injectable } from '@angular/core';
import { AccountModel, AUTH_ROLE, FirebaseAuthUser, MessageModel, ReportModel, UserModel } from '@ezspeek/models';
import { Observable } from 'rxjs/observable';
import { map, take } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import {ContactUsMessage, EmergencyAlertModel, NotificationModel} from '@ezspeek/models/common.models';
import { PlanModel, ProductModel } from '@ezspeek/models/stripe.models';
import { auth as FirebaseAuth } from 'firebase';

export interface AccountCreationData {
  source: string;
  plan: string;
}

@Injectable({ providedIn: 'root' })
export class AngularFireService {

  constructor(
    private fireAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private functions: AngularFireFunctions,
    private storage: AngularFireStorage,
    private msg: AngularFireMessaging
  ) {}

  verifyEmailLink(emailLink: string): boolean {
    return this.auth.isSignInWithEmailLink(emailLink);
  }

  async createUser(email: string, password: string, displayName: string, title?: string): Promise<any> {
    const { user: { uid } } = await this.auth.createUserWithEmailAndPassword(email, password);

    await this._call('setAccountManager')({
      uid,
      email,
      displayName,
      title: title || 'Account Manager',
      role: AUTH_ROLE.ACCOUNT_MGR
    }).toPromise();

    return uid;
  }

  deleteAdmin(uid: string, aid: string): Promise<any> {
    return this._call('deleteAdmin')({ uid, aid }).toPromise();
  }

  async createAccount(data): Promise<any> {
    return this._call('createAccount')(data).toPromise();
  }

  sendWelcomeEmail(): Promise<any> {
    return this._call('sendWelcomeEmail')(null).toPromise();
  }

  sendVerificationEmail(url: string): Promise<any> {
    return this.auth.currentUser.sendEmailVerification({
      handleCodeInApp: true,
      url
    });
  }

  sendSignInLinkToEmail(email: string, aid: string): Promise<any> {
    return this.auth.sendSignInLinkToEmail(email, {
      handleCodeInApp: true,
      url: `${window.location.origin}/admin/email-registration?aid=${aid}`
    });
  }

  sendSignInLinkForContinueRegistration(email: string) {
    return this.auth.sendSignInLinkToEmail(email, {
      handleCodeInApp: true,
      url: `${window.location.origin}/admin/continue-registration`
    }).then(_ => {
      window.localStorage.setItem('ezsReAuthEmail', email);
    });
  }

  reAuthenticateWithEmailLink(url) {
    let email: string = window.localStorage.getItem('ezsReAuthEmail');

    if (!email) {
      email = prompt('Verify your email');
    }

    if (this.verifyEmailLink(url)) {
      const credential = FirebaseAuth.EmailAuthProvider.credentialWithLink(email, url);

      return this.fireAuth.auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
        .then(_ => {
          window.localStorage.removeItem('ezsReAuthEmail');
          console.warn('ReAuth Successful!');
          return true;
        });
    }
  }

  registerNewAdminWithEmailLink(url, email, aid) {
    const validLink = this.fireAuth.auth.isSignInWithEmailLink(url);

    if (validLink) {
      return this.fireAuth.auth.signInWithEmailLink(email, url)
        .then(({ user }) => {
          const userData = {
            email,
            uid: user.uid,
            aid,
            role: null,
            emailVerified: user.emailVerified
          };
          return this.getUserDocRef(user.uid).set(userData)
            .then(_ => {
              return this.getAccountDocRef(aid).collection('admins').doc(user.uid).set({
                uid: user.uid
              });
            });
        });
    }
  }

  completeNewAdminRegistration(email: string, password: string, displayName: string) {
    return this.auth.currentUser.updatePassword(password)
      .then(updatePasswordRes => {
        const credential = FirebaseAuth.EmailAuthProvider.credential(email, password);
        return this.auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
      })
      .then(userCred => {
        return this.getUserDocRef(userCred.user.uid).update({
          displayName,
          role: AUTH_ROLE.ADMIN
        });
      });

  }

  updateNewUserPassword(email: string, password: string) {
    const credential = FirebaseAuth.EmailAuthProvider.credential(email, password);

    this.auth.currentUser.linkAndRetrieveDataWithCredential(credential).then(userCred => {
    }, (error) => {
    });
  }

  signIn(email, password): Promise<any> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signOut(): Promise<any> {
    return this.auth.signOut();
  }

  getAccountDocRef(aid: string): AngularFirestoreDocument<AccountModel> {
    return this.fireStore.doc<AccountModel>(`accounts/${aid}`);
  }


  getUserDocRef(uid: string): AngularFirestoreDocument<UserModel> {
    return this.fireStore.doc<UserModel>(`users/${uid}`);
  }

  getAccessIdDocRef(accessId): AngularFirestoreDocument<{ [key: string]: boolean }> {
    return this.fireStore.doc<{ [key: string]: boolean }>(`accessIds/${accessId}`);
  }

  getClientDocRef(aid, uid): AngularFirestoreDocument<UserModel> {
    return this.fireStore.doc<UserModel>(`accounts/${aid}/clients/${uid}`);
  }

  getReportDocRef(aid, reportId): AngularFirestoreDocument<ReportModel> {
    return this.fireStore.doc<ReportModel>(`accounts/${aid}/reports/${reportId}`);
  }

  getEmergencyAlertDocRef(aid, alertId): AngularFirestoreDocument<EmergencyAlertModel> {
    return this.fireStore.doc<EmergencyAlertModel>(`accounts/${aid}/emergency-alerts/${alertId}`);
  }

  getReportsCollectionRef(aid: string, queryFn?: QueryFn): AngularFirestoreCollection<ReportModel> {
    return this._getCollection<ReportModel>(`accounts/${aid}/reports`, queryFn);
  }

  getReportChatMessagesCollectionRef(aid: string, reportId: string, queryFn?: QueryFn): AngularFirestoreCollection<MessageModel> {
    return this._getCollection<MessageModel>(`accounts/${aid}/reports/${reportId}/messages`, queryFn);
  }

  getReportNotesCollectionRef(aid: string, reportId: string, queryFn?: QueryFn): AngularFirestoreCollection<MessageModel> {
    return this._getCollection<MessageModel>(`accounts/${aid}/reports/${reportId}/notes`, queryFn);
  }

  getEmergencyAlertsCollectionRef(aid: string, queryFn?: QueryFn): AngularFirestoreCollection<EmergencyAlertModel> {
    return this._getCollection<EmergencyAlertModel>(`accounts/${aid}/emergency-alerts`, queryFn);
  }

  getNotificationsCollectionRef(aid: string, queryFn?: QueryFn): AngularFirestoreCollection<NotificationModel> {
    return this._getCollection<NotificationModel>(`accounts/${aid}/notifications`, queryFn);
  }

  getProductsCollectionRef(): AngularFirestoreCollection<ProductModel> {
    return this._getCollection<ProductModel>(`products`);
  }

  getPlansCollectionRef(): AngularFirestoreCollection<PlanModel> {
    return this._getCollection<PlanModel>(`plans`);
  }

  getMetaRef(uid: string): AngularFirestoreDocument {
    return this.fireStore.doc(`metadata/${uid}`);
  }

  uploadProfileImage(blob: Blob, uid: string) {
    return this.storage.ref(`profile-photo/${uid}`)
      .put(blob)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      })
      .then((photoURL: string) => {
        return this.auth.currentUser.updateProfile({ photoURL } as any)
          .then(_ => {
            return this.getUserDocRef(uid).update({ photoURL });
          });
      });
  }

  updateUserDisplayName(displayName: string, uid: string) {
    return this.auth.currentUser.updateProfile({ displayName } as any)
      .then(_ => {
        return this.getUserDocRef(uid).update({ displayName });
      });
  }

  updateUserJobTitle(title: string, uid: string) {
    return this.getUserDocRef(uid).update({ title });
  }

  getFileUrl(aid, reportId, fileName): Observable<string> {
    return this.storage.ref(`${aid}/${reportId}/${fileName}`).getDownloadURL();
  }

  authorizeClient(accessId) {
    return this.accountsCollectionRef.ref.where('accessId', '==', accessId).get()
      .then(accountsQuery => {
        if (accountsQuery.empty)
          throw new Error('invalid accessId');

        const aid = accountsQuery.docs[0].id;

        return this.fireAuth.auth.signInAnonymously()
          .then(({ user }) => {
            return this.getUserDocRef(user.uid).set({
              uid: user.uid,
              aid,
              role: AUTH_ROLE.CLIENT,
              isClient: true
            }).then(_ => {
              return this.getClientDocRef(aid, user.uid).set({
                uid: user.uid
              });
            }).then(_ => {
              return { uid: user.uid, aid };
            });
          });
      });
  }

  addReport(report: ReportModel, files: File[]): Promise<any> {
    return this.getReportsCollectionRef(report.aid).add(report)
      .then(doc => {
        if (files && files.length > 0)
          return Promise.all(files.map(file => {
            return this.storage.upload(`${report.aid}/${doc.id}/${file.name}`, file);
          })).then(uploadSnapshots => {
            return Promise.all(uploadSnapshots.map(uploadSnapshot => {
              return uploadSnapshot.ref.getDownloadURL();
            })).then(downloadUrls => {
              const evidence = {
                images: [],
                videos: [],
                audio: [],
                other: []
              };

              downloadUrls.forEach((url, i) => {
                const fileType = uploadSnapshots[i].metadata.contentType.split('/')[0];
                const name = uploadSnapshots[i].metadata.name;
                let key = '';
                switch (fileType) {
                  case 'image':
                    key = 'images';
                    break;
                  case 'video':
                    key = 'videos';
                    break;
                  case 'audio':
                    key = 'audio';
                    break;
                  default:
                    key = 'other';
                }
                evidence[key].push({ url, name });
              });
              return doc.update({ evidence });
            });
          });
        else
          return null;
        // return doc.update({ id: doc.id, createdAt: this._timestamp.now().toMillis() })
        //   .then(_ => {
        //
        //   });
      });
  }

  addChatMessage(message: MessageModel) {
    return this.getReportDocRef(message.aid, message.reportId).collection('messages').add(message);
  }

  addNote(message: MessageModel) {
    return this.getReportDocRef(message.aid, message.reportId)
      .collection('notes').add(message);
  }

  activateEmergencyAlert(aid: string, alert: EmergencyAlertModel) {
    return this.getEmergencyAlertsCollectionRef(aid).add({
      ...alert,
      aid,
      activatedAt: this._timestamp.now().toMillis()
    }).then(doc => doc.update({ id: doc.id, isActive: true }));
  }

  dismissEmergencyAlert(uid: string, alertId: string, aid: string) {
    return this.getEmergencyAlertDocRef(aid, alertId)
      .update({
        dismissedAt: this._timestamp.now().toMillis(),
        dismissedBy: uid,
        isActive: false
      });
  }

  sendNotificationAlert(aid: string, notification: NotificationModel) {
    return this.getNotificationsCollectionRef(aid).add({
      ...notification,
      aid,
      createdAt: this._timestamp.now().toMillis()
    }).then(doc => doc.update({ id: doc.id }));
  }

  sendContactUsMessage(msg: ContactUsMessage) {
    return this._getCollection('contactUsMessages').add(msg);
  }

  getCoupon(coupon: string): Promise<any> {
    return this._call('getStripeCoupon')(coupon).toPromise();
  }

  private _getCollection<T>(path, queryFn?): AngularFirestoreCollection<T> {
    if (queryFn)
      return this.fireStore.collection<T>(path, queryFn);

    return this.fireStore.collection<T>(path);
  }

  private _get(ref: AngularFirestoreDocument, mapFn = res => res): Promise<any> {
    return ref.valueChanges().pipe(take(1), map(mapFn)).toPromise();
  }

  private _call(fnName: string) {
    return this.functions.httpsCallable(fnName);
  }

  get reCaptcha() { return FirebaseAuth.RecaptchaVerifier; }

  private get auth(): FirebaseAuth.Auth { return this.fireAuth.auth; }

  private get _timestamp() { return firestore.Timestamp; }

  get authState(): Observable<FirebaseAuthUser | null> { return this.fireAuth.authState; }

  get accountsCollectionRef() { return this.fireStore.collection('accounts'); }

  static get timestamp() { return firestore.Timestamp; }
}
