import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './components/loading/loading.component';
import { AppMaterialModule } from '@ezspeek/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { ReportStatusBadgeComponent } from './components/report-status-badge.component';
import { AutofitContentDirective } from './directives/autofit-content.directive';
import { ReportDetailsComponent } from './components/reports/details/report-details.component';
import { ReportsListComponent } from './components/reports/list/reports-list.component';
import { ChatMessageComponent } from './components/reports/details/chat-message.component';
import { RouterModule } from '@angular/router';
import { ReportNoteComponent } from './components/reports/details/report-note.component';
import { ChartComponent } from './components/chart/chart.component';
import { ReportsListItemComponent } from './components/reports/list/reports-list-item.component';
import { CroppieDirective } from './directives/croppie.directive';
import { AnimateInViewDirective } from './directives/animate-in-view.directive';
import { FirebaseTimestampPipe } from './pipes/firebase-timestamp.pipe';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { AnimatedCheckComponent } from './components/animated-check/animated-check.component';

const DECLARATIONS = [
  LoadingComponent,
  MomentFormatPipe,
  FirebaseTimestampPipe,
  ReportStatusBadgeComponent,
  AutofitContentDirective,
  ReportDetailsComponent,
  ReportsListComponent,
  ReportsListItemComponent,
  ChatMessageComponent,
  ReportNoteComponent,
  ChartComponent,
  CroppieDirective,
  AnimateInViewDirective,
  RecaptchaDirective,
  AnimatedCheckComponent
];

const MODULES = [
  CommonModule,
  AppMaterialModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule
];

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...MODULES,
    ...DECLARATIONS
  ]
})
export class AppCommonModule {}
