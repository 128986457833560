import { Component, OnInit } from '@angular/core';
import { SetAdminPageTitle } from '@ezspeek/store/actions/app.actions';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { AppState } from '@ezspeek/store/state';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { ToasterService } from 'angular2-toaster';
import { ProductFeatures } from '@ezspeek/models/stripe.models';

@Component({
  template: `
    <ezs-users
      [authUser]="user$ | async"
      [users]="users$ | async"
      [sendEmailStatus]="sendEmailStatus"
      [deleteUserStatus]="deleteUserStatus"
      [features]="features$ | async"
      (addUser)="addUser($event)"
      (deleteUser)="deleteUser($event)"
      (resendEmail)="resendEmail($event)">
    </ezs-users>
  `,
})
export class UsersContainer implements OnInit {

  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;
  @Select(AppState.accountUsers) users$: Observable<UserModel[]>;
  @Select(AppState.accountFeatures) features$: Observable<ProductFeatures>;

  private _sendEmailStatus: REQUEST_STATUS;
  private _sendEmailError = null;

  private _deleteUserStatus: REQUEST_STATUS;
  private _deleteUserError: null;

  constructor(private store: Store, private af: AngularFireService, private toaster: ToasterService) {}

  ngOnInit() {
    this.store.dispatch(new SetAdminPageTitle('Users', 'Manage Users'));
  }

  async addUser(email: string) {
    this._sendEmailError = null;
    this._sendEmailStatus = REQUEST_STATUS.LOADING;

    const aid = this.store.selectSnapshot(AppState.authenticatedUser).aid;

    try {
      await this.af.sendSignInLinkToEmail(email, aid);
      this._sendEmailStatus = REQUEST_STATUS.SUCCESS;
      this.toaster.pop('success', '', 'registration request sent to ' + email);
    } catch (err) {
      this._sendEmailError = err;
      this._sendEmailStatus = REQUEST_STATUS.ERROR;
      this.toaster.pop('error', 'Request failed', err);
    }
  }

  async deleteUser(uid: string) {
    this._deleteUserError = null;
    this._deleteUserStatus = REQUEST_STATUS.LOADING;

    try {
      await this.af.deleteAdmin(uid, this.aid);
      this._deleteUserStatus = REQUEST_STATUS.SUCCESS;
      this.toaster.pop('success', 'User Removed Successfully');
    } catch (err) {
      this._deleteUserError = err;
      this._deleteUserStatus = REQUEST_STATUS.ERROR;
      this.toaster.pop('error', 'Failed To Remove User', err);
    }
  }

  async resendEmail(email: string) {
    try {
      await this.af.sendSignInLinkForContinueRegistration(email);
      this.toaster.pop('success', '', 'email sent to ' + email);
    } catch {
      this.toaster.pop('error', '', 'failed to send email to ' + email);
    }
  }

  get sendEmailStatus(): REQUEST_STATUS { return this._sendEmailStatus; }

  get deleteUserStatus(): REQUEST_STATUS { return this._deleteUserStatus; }

  get aid(): string {
    return this.store.selectSnapshot(AppState.authenticatedUser).aid;
  }
}
