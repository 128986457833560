
    <header [class.scrolled]="isWindowScrolled()">
      <div class="container">
        <nav class="navbar">
          <a [routerLink]="['/home']" class="brand">
            <img src="assets/images/ezSpeekLogo2.svg" alt="ezspeek"> ez<span>Speek</span>
          </a>
          <span class="spacer"></span>
        </nav>
      </div>
    </header>
  