<div class="profile-modal-overlay" *ngIf="isOpen">
  <mat-card class="fadeInUp animated">
    <mat-card-content [class.crop-mode]="isCropPhotoMode">
      <div class="profile-modal-header">
        <div class="header-actions">
          <span *ngIf="isViewMode" class="fadeIn animated" (click)="edit()"><mat-icon>edit</mat-icon> Edit</span>
          <span class="flex-spacer"></span>
          <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        </div>
        <div [class.edit-mode]="isEditMode" *ngIf="!isCropPhotoMode" class="profile-photo-wrapper">
          <img class="elevation-2" [src]="userPhotoURL">
          <div (click)="selectImage(imageInput)" *ngIf="isEditMode" class="edit-photo-overlay">
            <mat-icon>photo_camera</mat-icon>
          </div>
        </div>
        <input #imageInput type="file" accept="image/jpeg|image/png" (change)="cropImage($event)">
      </div>
      <div class="profile-modal-body">
        <div *ngIf="isViewMode" class="profile-view fadeInUp animated">
          <span class="display-name">{{user.displayName}}</span>
          <span class="user-title" *ngIf="user.title">{{user.title}}</span>
          <span class="email">{{user.email}}</span>
        </div>
        <div *ngIf="isEditMode && !!form" class="profile-edit fadeInUp animated">
          <form [formGroup]="form">
            <div class="form-field">
              <input [disabled]="isRequestLoading" [class.has-changes]="nameHasChanges" #displayName formControlName="name" placeholder="Name">
              <mat-icon *ngIf="editingName" (click)="saveName()" class="fadeIn animated">check</mat-icon>
              <mat-icon *ngIf="editingName" (click)="name.reset(user.displayName)" class="fadeIn animated">close</mat-icon>
              <img *ngIf="updatingName" src="assets/images/bars_primary-dark.svg">
            </div>
            <div class="form-field">
              <input [disabled]="isRequestLoading" [class.has-changes]="titleHasChanges" formControlName="title" placeholder="Job Title">
              <mat-icon *ngIf="editingTitle" (click)="saveTitle()" class="fadeIn animated">check</mat-icon>
              <mat-icon *ngIf="editingTitle" (click)="title.reset(user.title || '')" class="fadeIn animated">close</mat-icon>
              <img *ngIf="updatingTitle" src="assets/images/bars_primary-dark.svg">
            </div>
            <button [disabled]="isRequestLoading" mat-raised-button class="reset-pswd-button">Request Password Reset</button>
          </form>
        </div>
        <div *ngIf="isCropPhotoMode" class="profile-crop-photo fadeInUp animated">
          <img [croppieOptions]="croppieOptions" *ngIf="selectedPhotoSrc" [src]="selectedPhotoSrc" alt="cropped photo">
          <div *ngIf="uploadingImage" class="image-crop-overlay"></div>
          <mat-icon class="rotate-left" (click)="rotateImg('left')">rotate_left</mat-icon>
          <mat-icon class="rotate-right" (click)="rotateImg('right')">rotate_right</mat-icon>
        </div>
      </div>
      <div *ngIf="isEditMode" class="profile-modal-actions">
        <button [disabled]="isRequestLoading" mat-button class="fadeIn animated" (click)="cancelEdit()">back</button>
      </div>
      <div *ngIf="isCropPhotoMode" class="profile-modal-actions">
        <button
          [disabled]="uploadingImage"
          mat-raised-button
          class="upload-image-button fadeIn animated"
          (click)="upload()">
          <img *ngIf="uploadingImage" class="loading-icon" src="assets/images/bars.svg"> {{uploadPhotoButtonText}}
        </button>
        <button [disabled]="uploadingImage" mat-button class="fadeIn animated" (click)="cancelCrop()">cancel</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
