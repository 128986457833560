import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import {AppState, AuthState, FirestoreState} from '@ezspeek/store/state';
import {Observable, of} from 'rxjs';
import { map, first, tap, switchMap } from 'rxjs/operators';
import {AccountModel, AUTH_ROLE} from '@ezspeek/models';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.selectOnce(AuthState.isAuthenticated);
  }
}

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(AppState.authenticatedUser)
      .pipe(
        first(user => user !== undefined),
        map(user => {
          if (!user) {
            this.store.dispatch(new Navigate(['/admin', 'sign-in']));
            return false;
          }

          const isAdmin = !!user && (user.role === AUTH_ROLE.ADMIN || user.role === AUTH_ROLE.ACCOUNT_MGR);
          if (!isAdmin) {
            if (user.role === AUTH_ROLE.CLIENT)
              this.store.dispatch(new Navigate(['/client']));
            else
              this.store.dispatch(new Navigate(['/admin', 'continue-registration']));
          }
          return isAdmin;
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class RegistrationIncompleteGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(AppState.authenticatedUser)
      .pipe(
        first(user => user !== undefined),
        map(user => {
          if (!user) {
            this.store.dispatch(new Navigate(['/admin', 'sign-in']));
            return false;
          }

          if (user.role !== undefined && user.role !== null) {
            if (user.role === AUTH_ROLE.CLIENT)
              this.store.dispatch(new Navigate(['/client']));
            else
              this.store.dispatch(new Navigate(['/admin']));

            return false;
          }

          return true;
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class ClientGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(AppState.authenticatedUser)
      .pipe(
        first(user => user !== undefined),
        map(user => {
          const isClient = !!user && user.role === AUTH_ROLE.CLIENT;

          if (!isClient)
            this.store.dispatch(new Navigate(['/client', 'sign-in']));

          return isClient;
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class UnauthenticatedGuard implements  CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(AppState.authenticatedUser)
      .pipe(
        first(user => user !== undefined),
        map(user => {
          const isUnauthenticated = user === null;

          if (!isUnauthenticated) {
            if (user.role === AUTH_ROLE.CLIENT)
              this.store.dispatch(new Navigate(['/client', 'dashboard']));
            else
              this.store.dispatch(new Navigate(['/admin', 'dashboard']));
          }

          return isUnauthenticated;
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class AccountActiveGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const paths: string[] = route.url.map(url => url.path);
    return this.store.select(AppState.authenticatedUser)
      .pipe(
        first(user => user !== undefined),
        switchMap(user => user === null ? of(null) : this.store.select(FirestoreState.account)),
        first(account => account !== undefined),
        map(account => {
          if (account === null) {
            return false;
          }
          const status = account.status;
          if (status === 'inactive') {
            if (!state.url.includes('/admin/dashboard'))
              this.store.dispatch(new Navigate(['/dashboard']));
          }

          return paths.includes('dashboard') || status === 'active' || status === 'trialing';
        })
      );
  }
}
