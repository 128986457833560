import { Component, OnInit } from '@angular/core';
import { SetAdminPageTitle } from '@ezspeek/store/actions/app.actions';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ProductFeatures} from '@ezspeek/models/stripe.models';
import {AppState} from '@ezspeek/store/state';
import {REQUEST_STATUS} from '@ezspeek/models';

@Component({
  template: `
    <ezs-statistics [status]="requestStatus" [accountFeatures]="features$ | async"></ezs-statistics>
  `,
})
export class StatsContainer implements OnInit {
  @Select(AppState.accountFeatures) features$: Observable<ProductFeatures>;

  private _requestStatus: REQUEST_STATUS;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new SetAdminPageTitle('Statistics', 'Incident Report Data and Response Performance'));
  }

  get requestStatus(): REQUEST_STATUS {
    return this._requestStatus;
  }
}
