import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

const ADDITIONAL_AF_MODULES = [
  AngularFireAuthModule,
  AngularFirestoreModule,
  AngularFireFunctionsModule,
  AngularFireStorageModule,
  AngularFireMessagingModule,
];

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    ...ADDITIONAL_AF_MODULES
  ],
  exports: [ AngularFireModule, ...ADDITIONAL_AF_MODULES ]
})
export class EzsAngularFireModule {}
