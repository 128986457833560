
    <ezs-header></ezs-header>
    <ezs-home 
      [displayedPhrase]="displayedPhrase"
      [user]="user$ | async"
      [contactUsStatus]="contactUsRequestStatus"
      [contactUsErrorMsg]="contactUsErrorMsg"
      [plans]="plans$ | async" 
      (submitMessage)="sendContactUsMessage($event)">
    </ezs-home>
  