
    <ezs-alerts
      [account]="account$ | async"
      [user]="user$ | async"
      [features]="features$ | async"
      [emergencyAlertRequestStatus]="emergencyAlertRequestStatus"
      [notificationRequestStatus]="notificationRequestStatus"
      (sendNotification)="sendNotification($event)"
      (previewNotification)="previewNotification($event)"
      (activateEmergencyAlert)="activateEmergencyAlert($event)">
    </ezs-alerts>
  