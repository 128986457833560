import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PASSWORD_VALIDATORS } from '../common/validators';
import { REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { PlanModel, ProductModel } from '@ezspeek/models/stripe.models';
import {Coupon} from '@stripe/models';

interface SignUpPayload {
  email: string;
  password: string;
  displayName: string;
  title?: string;
  plan: string;
}

@Component({
  selector: 'ezs-sign-up',
  templateUrl: 'sign-up.html',
  styleUrls: ['sign-up.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent implements AfterContentChecked, OnInit {

  @Input() errorMsg: string;
  @Input() stripeCardError: string;
  @Input() stripeCardComplete: boolean;
  @Input() selectedPlanId: string;
  @Input() plans: PlanModel[];
  @Input() requestError: string;
  @Input() requestStatus: REQUEST_STATUS;
  @Input() appliedCoupon: Coupon;

  @Output() signUp: EventEmitter<SignUpPayload> = new EventEmitter<SignUpPayload>();
  @Output() complete: EventEmitter<void> = new EventEmitter<void>();
  @Output() validateCoupon: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeCoupon: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('testCard') testCard;

  form: FormGroup;

  private _isAuthenticated = false;

  private _enteredCoupons: Array<string> = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

  }

  ngAfterContentChecked() {
    if (this.plans && !this.form)
      this.form = this.fb.group({
        displayName: ['', Validators.required],
        title: [''],
        email: ['', [ Validators.required, Validators.email]],
        password: ['', PASSWORD_VALIDATORS],
        plan: [this.planId, Validators.required],
        coupon: ['']
      });
  }

  applyCoupon() {
    this.validateCoupon.emit(this.coupon.value);
  }

  submitForm(e) {
    e.preventDefault();

    if (this.submitDisabled)
      return;

    const data = {
      ...this.form.value,
      coupon: !!this.appliedCoupon ? this.appliedCoupon.id : null
    };

    this.signUp.emit(data);
  }

  get showForm() {
    return !this.isRegistrationComplete && !!this.plans && !!this.form;
  }

  get isRegistrationComplete() {
    return this.requestStatus === REQUEST_STATUS.SUCCESS;
  }

  get headlineText(): string {
    return 'Get started today! <span class="no-wrap">Risk Free</span>';
  }

  get submitDisabled(): boolean {
    return this.form.invalid || this.processing || !this.stripeCardComplete;
  }

  get processing(): boolean {
    const isProcessing = this.requestStatus === REQUEST_STATUS.LOADING;

    if (this.form) {
      if (isProcessing)
        this.form.disable();
      else if (this.form.disabled)
        this.form.enable();
    }

    return isProcessing;
  }

  get submitButtonText(): string {
    return this.processing ? 'Creating Account...' : 'Sign Up';
  }

  get planId(): string {
    const plan = this.plans.find(p => p.id === this.selectedPlanId);
    return !!plan ? plan.id : '';
  }

  get userComplete(): boolean {
    return this.displayName.valid && this.email.valid && this.password.valid;
  }

  get checkoutComplete(): boolean {
    return this.plan.valid && this.stripeCardComplete;
  }

  // Form controls
  get displayName(): AbstractControl { return this.form.get('displayName'); }
  get title(): AbstractControl { return this.form.get('title'); }
  get email(): AbstractControl { return this.form.get('email'); }
  get password(): AbstractControl { return this.form.get('password'); }
  get plan(): AbstractControl { return this.form.get('plan'); }
  get coupon(): AbstractControl { return this.form.get('coupon'); }
}
