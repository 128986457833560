import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ReportModel } from '@ezspeek/models';
import { Observable } from 'rxjs/Observable';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { AppState } from '@ezspeek/store/state';
import { map, tap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';

@Component({
  template: `
    <client-dashboard 
      [reports]="reports$ | async" 
      [displayedKeyword]="displayedKeyword" 
      [keywordColorClass]="keywordColor">
    </client-dashboard>
  `
})
export class ClientDashboardContainer implements OnInit, OnDestroy {

  reports$: Observable<ReportModel[]>;

  readonly keywords: string[] = [ 'Freely', 'Safely', 'Easy'];
  readonly keywordColors: string[] = [ 'primary', 'accent2', 'accent' ];

  private _displayedKeyword = '';
  private _currentKeywordIndex = 0;
  private _currentNewMessageCount = 0;

  headingKeywordInterval;

  constructor(private store: Store, private af: AngularFireService, private toaster: ToasterService) {}

  ngOnInit() {
    this.typeKeyword().then(() => this.typeNextKeyword());

    const { aid, uid } = this.store.selectSnapshot(AppState.authenticatedUser);
    this.reports$ = this.af.getReportsCollectionRef(aid, ref => ref.where('reportedBy', '==', uid))
      .valueChanges()
      .pipe(
        tap(filteredReports => {
          const newMsgCount = filteredReports.filter(filteredReport => filteredReport.newMessageFromAdmin).length;
          if (newMsgCount > this._currentNewMessageCount) {
            const msg = newMsgCount > 1 ? 'You have new unread messages' : 'You have a new message';
            this.toaster.pop('warning', '', msg);
            this._currentNewMessageCount = newMsgCount;
          }
          return filteredReports;
        })
      );
  }

  typeNextKeyword() {
    return this.typeKeyword().then(() => this.typeNextKeyword());
  }

  typeKeyword() {
    const currentKeyword = this.currentKeyword;
    return new Promise((res) => {
      const intervalId = setInterval(() => {
        this.displayedKeyword += currentKeyword[this.displayedKeyword.length];

        if (this.displayedKeyword === currentKeyword) {
          clearInterval(intervalId);
          setTimeout(res, 1250);
        }
      }, 40);
    }).then(() => this.deleteKeyword());
  }

  deleteKeyword() {
    return new Promise(res => {
      const intervalId = setInterval(() => {
        this.displayedKeyword = this.displayedKeyword.slice(0, -1);

        if (this.displayedKeyword === '') {
          clearInterval(intervalId);
          this.loadNextKeyword();
          setTimeout(res, 500);
          // res();
        }
      }, 40);
    });
  }

  private loadNextKeyword() {
    if (this._currentKeywordIndex === this.keywords.length - 1) {
      this._currentKeywordIndex = 0;
    } else {
      this._currentKeywordIndex++;
    }
  }

  set displayedKeyword(phrase) {
    this._displayedKeyword = phrase;
  }

  get displayedKeyword(): string {
    return this._displayedKeyword;
  }

  get keywordColor(): string {
    return this.keywordColors[this._currentKeywordIndex];
  }

  get currentKeyword(): string { return this.keywords[this._currentKeywordIndex]; }

  ngOnDestroy() {
    clearInterval(this.headingKeywordInterval);
  }
}
