<mat-card *ngIf="!!user" class="fadeInUp animated">
  <mat-card-content>
    <div class="report-details-container" *ngIf="!!report">

      <div class="report-header">
        <div class="flex-section status-date">
          <span class="report-date">
            <span>Reported </span>{{report.createdAt | moment:'MMM D, YYYY h:mm a'}}
          </span>
          <span class="flex-spacer"></span>
          <span class="report-status">
            <report-status-badge [archived]="report.archived" [status]="report.status" size="md"></report-status-badge>
            <report-status-badge [archived]="report.archived" [status]="report.status" size="sm"></report-status-badge>
          </span>
          <div *ngIf="showReportMenu">
            <mat-icon class="report-menu-icon" [matMenuTriggerFor]="reportMenu">more_vert</mat-icon>
            <mat-menu #reportMenu="matMenu">
              <button mat-menu-item *ngIf="canWork" (click)="workIncident()">
                <mat-icon class="color-primary">work</mat-icon>Work this Incident
              </button>
              <button mat-menu-item *ngIf="canSubmit" (click)="submitForReview()">
                <mat-icon class="color-caution">assignment</mat-icon>Submit for Review
              </button>
              <button mat-menu-item *ngIf="canClose" (click)="resolve()">
                <mat-icon class="color-success">assignment_turned_in</mat-icon>Resolve
              </button>
              <button mat-menu-item *ngIf="canClose" (click)="markAsFalseReport()">
                <mat-icon class="color-warn">report</mat-icon>Flag False Report
              </button>
              <button mat-menu-item *ngIf="isAcctMgr && isClosed" (click)="archive()">
                <mat-icon class="color-mute">archive</mat-icon>Archive
              </button>
            </mat-menu>
          </div>
        </div>
      </div>

      <div class="report-details">
        <div class="main-details-wrapper">
          <div class="flex-section flex-inline">
            <h3 class="report-category">Incident Type: <span>{{report.category}}</span></h3>
            <h3 class="incident-date detail-label">Occurred <span>{{incidentDate | moment: 'MMM D, YYYY'}}</span></h3>
          </div>

          <div class="flex-section">
            <div class="details-section-col" *ngIf="report.victims">
              <h3 class="detail-label">Victim(s)</h3>
              <p class="items-array">
                <span class="victim" *ngFor="let victim of report.victims">{{victim}}</span>
              </p>
            </div>
            <div class="details-section-col" *ngIf="report.otherPeopleInvolved">
              <h3 class="detail-label">Others Involved</h3>
              <p class="items-array">
                <span class="others" *ngFor="let person of report.otherPeopleInvolved">{{person}}</span>
              </p>
            </div>
          </div>

          <h3 class="detail-label">Description</h3>
          <p>{{report.description}}</p>
        </div>

        <div class="report-evidence" *ngIf="hasAttachments">
          <h3><mat-icon>attachment</mat-icon> Attachments</h3>

          <mat-tab-group class="tab-group">

            <mat-tab *ngIf="hasImages" label="images">
              <ng-template mat-tab-label>
                <mat-icon>photo</mat-icon><span class="tab-label">Images</span>
              </ng-template>
              <div class="tab-content">
                <div class="image-attachments">
                  <img (click)="viewEvidence('Images', imageIndex)" *ngFor="let image of imageAttachments; let imageIndex = index" [src]="image.url">
                </div>
              </div>
            </mat-tab>

            <mat-tab *ngIf="hasVideos" label="videos">
              <ng-template mat-tab-label>
                <mat-icon>videocam</mat-icon><span class="tab-label">Videos</span>
              </ng-template>
              <div class="tab-content">
                <video (click)="viewEvidence('Videos', videoIndex)" *ngFor="let video of videoAttachments; let videoIndex = index" [src]="video.url"></video>
              </div>
            </mat-tab>

            <mat-tab *ngIf="hasAudio" label="audio">
              <ng-template mat-tab-label>
                <mat-icon>mic</mat-icon><span class="tab-label">Audio</span>
              </ng-template>
              <div class="tab-content">
                audio evidence
                <audio *ngFor="let audio of audioAttachments" [src]="audio.url"></audio>
              </div>
            </mat-tab>

            <mat-tab *ngIf="hasOther" label="other">
              <ng-template mat-tab-label>
                <mat-icon>folder</mat-icon><span class="tab-label">Other</span>
              </ng-template>
              <div class="tab-content">
                <a *ngFor="let file of otherAttachments" [href]="file.url">{{file.name}}</a>
              </div>
            </mat-tab>

          </mat-tab-group>

        </div>
      </div>
      <div class="report-messages">
        <h3 class="icon-heading"><mat-icon>question_answer</mat-icon> Chat</h3>
        <div id="chat" class="message-container">
          <div class="flex-spacer"></div>
          <div class="messages" #chatContent>
            <ezs-chat-message
              *ngFor="let chatMessage of chatMessages"
              [message]="chatMessage"
              [userPhotoURL]="getPhotoURL(chatMessage.uid)"
              [clientView]="isClientView">
            </ezs-chat-message>
          </div>
          <div *ngIf="!!sendChatMessageError">{{sendChatMessageError}}</div>
          <div class="message-input">
            <div class="message-input-wrapper">
              <textarea *ngIf="showChatInput" [(ngModel)]="chatInput" autofitContent placeholder="Type message..."></textarea>
            </div>
            <mat-icon *ngIf="showChatInput" [class.disabled]="isSendChatDisabled" (click)="newChatMessage()">send</mat-icon>
          </div>
        </div>
      </div>

      <div class="report-notes" *ngIf="!isClientView">
        <h3 class="icon-heading"><mat-icon>comment</mat-icon> Notes</h3>
        <div id="notes" class="message-container">
          <div class="message-input">
            <div class="message-input-wrapper">
              <textarea *ngIf="showNoteInput" [(ngModel)]="noteInput" autofitContent placeholder="Type note..."></textarea>
            </div>
            <mat-icon *ngIf="showNoteInput" [class.disabled]="isAddNoteDisabled" (click)="newNote()">send</mat-icon>
          </div>
          <div *ngIf="!!addNoteError">{{addNoteError}}</div>
          <div class="messages">
            <ezs-report-note
              *ngFor="let note of notes"
              [note]="note"
              [userDisplayName]="getDisplayName(note.uid)"
              [userPhotoURL]="getPhotoURL(note.uid)">
            </ezs-report-note>
          </div>
          <div class="flex-spacer"></div>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>
<div *ngIf="lightbox.show" class="media-lightbox">
  <mat-icon class="close-btn" (click)="closeLightbox()">close</mat-icon>
  <div class="lightbox-header">
    <span>{{lightbox.evidenceType}} {{lightbox.currentIndex + 1}} / {{lightbox.evidenceSources.length}}</span>
  </div>
  <div class="evidence-container" [ngSwitch]="lightbox.evidenceType">
    <mat-icon *ngIf="lightbox.currentIndex > 0" (click)="previousAttachment()" class="change-index-icon previous-index">chevron_left</mat-icon>
    <div *ngSwitchCase="'Images'" class="lightbox-image">
      <img [src]= "currentLightboxSrc">
    </div>
    <video *ngSwitchCase="'Videos'" controls [src]="currentLightboxSrc"></video>
    <mat-icon *ngIf="lightbox.currentIndex+1 < lightbox.evidenceSources.length" (click)="nextAttachment()" class="change-index-icon next-index">chevron_right</mat-icon>
  </div>
</div>
