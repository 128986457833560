import { Component, OnInit } from '@angular/core';
import { SetAdminPageTitle } from '@ezspeek/store/actions/app.actions';
import { Select, Store } from '@ngxs/store';
import { MessageModel, REPORT_STATUS, ReportModel, REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { Observable } from 'rxjs';
import { AppState, FirestoreState } from '@ezspeek/store/state';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Toast, ToasterService } from 'angular2-toaster';
import { QueryFn } from '@angular/fire/firestore';
import * as moment from 'moment';

@Component({
  template: `    
    <ezs-reports-list 
      *ngIf="!viewingReport" 
      [reports]="reports$ | async"
      [user]="authenticatedUser$ | async"
      [showArchived]="showArchived"
      [applyNewMessageFilter]="newMessageFilterActive"
      (updateStatusFilter)="updateStatusFilter($event)"
      [statusFilters]="statusFilters"
      (toggleNewMessageFilter)="toggleNewMessages()"
      (toggleArchived)="toggleArchived()">
    </ezs-reports-list>
    <ezs-report-details 
      *ngIf="viewingReport"
      [user]="authenticatedUser$ | async"
      [userPhotos]="userPhotos$ | async"
      [userDisplayNames]="userDisplayNames$ | async"
      [report]="selectedReport$ | async"
      [sendChatMessageStatus]="sendChatMessageStatus"
      [sendChatMessageError]="sendChatMessageError"
      [chatMessages]="chatMessages$ | async"
      [notes]="notes$ | async"
      [addNoteStatus]="addNoteStatus"
      [addNoteError]="addNoteError"
      (addNote)="addNote($event)"
      (sendChatMessage)="sendChatMessage($event)"
      (updateReport)="updateReport($event)">
    </ezs-report-details>
  `,
})
export class AdminReportsContainer  implements OnInit {
  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<UserModel>;
  @Select(FirestoreState.userPhotoUrls) userPhotos$: Observable<{ photoURL: string, uid: string }[]>;
  @Select(FirestoreState.userDisplayNames) userDisplayNames$: Observable<{ displayName: string, uid: string }>;

  reports$: Observable<ReportModel[]>;
  selectedReport$: Observable<ReportModel>;
  chatMessages$: Observable<MessageModel[]>;
  notes$: Observable<MessageModel[]>;

  sendChatMessageStatus: REQUEST_STATUS;
  sendChatMessageError: string;

  addNoteStatus: REQUEST_STATUS;
  addNoteError: string;

  private selectedReportId: string;

  private _showArchived = false;

  private _newMessageFilterActive = false;

  readonly defaultDateFilterStart: Date = moment().add(-1, 'years').toDate();
  private _dateFilterStart: Date;

  readonly defaultStatusFilters: REPORT_STATUS[] = [REPORT_STATUS.NEW, REPORT_STATUS.VIEWED, REPORT_STATUS.ACTIVE, REPORT_STATUS.PENDING];
  private _statusFilters: REPORT_STATUS[] = null;

  constructor(
    private store: Store,
    private af: AngularFireService,
    private route: ActivatedRoute,
    private toaster: ToasterService
  ) {

    const qp = route.snapshot.queryParams;

    this._newMessageFilterActive = !!qp['newMsgFilter'];

    let statusFilter: string = qp['statusFilter'];

    if (statusFilter) {
      statusFilter = statusFilter.toUpperCase();

      if (['NEW', 'PENDING'].indexOf(statusFilter) >= 0)
        this._statusFilters = [REPORT_STATUS[statusFilter]];
    }
  }

  ngOnInit() {
    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);
    this.store.dispatch(new SetAdminPageTitle('Reports', 'View and Manage Incident Reports'));

    this.filterReports();

    this.route.params.subscribe(({ reportId }) => {
      if (reportId) {
        this.selectedReport$ = this._getReports(ref => ref.where('id', '==', reportId))
          .pipe(map(results => results[0]));

        this.chatMessages$ = this.af.getReportChatMessagesCollectionRef(
          aid, reportId, ref => ref.orderBy('createdAt', 'asc')
        ).valueChanges();

        this.notes$ = this.af.getReportNotesCollectionRef(
          aid, reportId, ref => ref.orderBy('createdAt', 'desc')
        ).valueChanges();
      }

      this.selectedReportId = reportId || null;
    });
  }

  filterReports() {
    this.reports$ = this._getReports(ref => {
      const startDate = this._dateFilterStart || this.defaultDateFilterStart;
      return ref.where('createdAt', '>=', startDate.getTime()).orderBy('createdAt', 'desc');
    });
  }

  updateReport(report: { data: Partial<ReportModel>, aid: string, id: string }) {
    this.af.getReportDocRef(report.aid, report.id)
      .update(report.data)
      .then(_ => {
        const { uid, role } = this.store.selectSnapshot(AppState.authenticatedUser);
        const { status, activeUsers, archived, viewedBy } = report.data;
        let activityText = null;

        if (archived) {
          this.toaster.pop('mute', '', 'Incident has been archived');
          activityText = 'archived this incident';
        }

        if (!!status && status !== REPORT_STATUS.VIEWED && status !== REPORT_STATUS.ACTIVE) {
          this.toaster.pop(this._getStatusUpdateToast(status));
          activityText = this._getStatusActivityText(status);
        }

        if (!!activeUsers && activeUsers.indexOf(uid) >= 0) {
          this.toaster.pop({ type: 'primary', body: 'You have been added to this report as an active admin' });
          activityText = 'joined this incident';
        }

        if (viewedBy) {
          activityText = 'viewed this incident';
        }

        if (!!activityText) {
          this.addNote({ uid, text: activityText, aid: report.aid, authRole: role, reportId: report.id, isActivity: true });
        }
      });
  }

  sendChatMessage(message: MessageModel) {
    this.sendChatMessageError = '';
    this.sendChatMessageStatus = REQUEST_STATUS.LOADING;

    this.af.addChatMessage(message)
      .then(_ => {
        this.sendChatMessageError = '';
        this.sendChatMessageStatus = REQUEST_STATUS.SUCCESS;
      })
      .catch(err => {
        this.sendChatMessageError = err.message || err;
        this.sendChatMessageStatus = REQUEST_STATUS.ERROR;
      });
  }

  addNote(message: MessageModel) {
    this.addNoteError = null;
    this.addNoteStatus = REQUEST_STATUS.LOADING;

    this.af.addNote(message)
      .then(_ => {
        this.addNoteStatus = REQUEST_STATUS.SUCCESS;
      })
      .catch(err => {
        this.addNoteError = err.message || err;
        this.addNoteStatus = REQUEST_STATUS.ERROR;
      });
  }

  toggleArchived() {
    this._showArchived = !this._showArchived;
  }

  toggleNewMessages() {
    this._newMessageFilterActive = !this._newMessageFilterActive;
  }

  updateStatusFilter(filters: REPORT_STATUS[]) {
    this._statusFilters = [ ...filters ];
  }

  private _getReports(queryFn?: QueryFn) {
    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);

    return this.af.getReportsCollectionRef(aid, queryFn).valueChanges();
  }

  private _getStatusUpdateToast(status: REPORT_STATUS): Toast {
    switch (status) {
      case REPORT_STATUS.PENDING:
        return this._makeToast('wait', 'Incident has been submitted for review');
      case REPORT_STATUS.RESOLVED:
        return this._makeToast('success', 'Incident has been resolved');
      case REPORT_STATUS.FALSE_REPORT:
        return this._makeToast('error', 'Incident has been flagged as a false report');
      default:
        return this._makeToast('info', 'Report status has been updated');
    }
  }

  private _getStatusActivityText(status: REPORT_STATUS): string {
    switch (status) {
      case REPORT_STATUS.PENDING:
        return 'submitted this incident for review';
      case REPORT_STATUS.RESOLVED:
        return 'resolved this incident';
      case REPORT_STATUS.FALSE_REPORT:
        return 'flagged this incident as a false report';
      default:
        return null;
    }
  }

  private _makeToast(type: string, body: string): Toast {
    return { type, body };
  }

  get viewingReport(): boolean {
    return !!this.selectedReportId;
  }

  get statusFilters(): REPORT_STATUS[] {
    return this._statusFilters || this.defaultStatusFilters;
  }

  get newMessageFilterActive(): boolean {
    return this._newMessageFilterActive;
  }

  get showArchived(): boolean { return this._showArchived; }
}
