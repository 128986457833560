import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { AUTH_ROLE, UserModel } from '@ezspeek/models';

@Component({
  selector: 'ezs-header',
  template: `
    <header [class.scrolled]="isWindowScrolled()">
      <div class="container">
        <nav class="navbar">
          <a [routerLink]="['/home']" class="brand">
            <img src="assets/images/ezSpeekLogo2.svg" alt="ezspeek"> ez<span>Speek</span>
          </a>
          <span class="spacer"></span>
        </nav>
      </div>
    </header>
  `,
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  @Input() user: UserModel;
  @Input() showLinks: boolean;

  constructor() { }

  @HostListener('window:scroll')
  isWindowScrolled(): boolean { return !!window && window.scrollY !== 0; }

  get isAdmin(): boolean {
    return this.user && (this.user.role === AUTH_ROLE.ACCOUNT_MGR || this.user.role === AUTH_ROLE.ADMIN);
  }
}
