import { EmergencyAlertModel } from '@ezspeek/models/common.models';

export class HideBodyBackground {
  static readonly type = '[App] Hide Body Background';
  readonly hide = true;
}

export class ShowBodyBackground {
  static readonly type = '[App] Show Body Background';
  readonly hide = false;
}

export class ToggleSidebar {
  static readonly type = '[App] Toggle Sidebar';
}

export class ToggleMenu {
  static readonly type = '[App] Toggle Menu';
}

export class SetAdminPageTitle {
  static readonly type = '[App] Set Page Title';
  constructor(public main: string, public sub: string = '') {}
}

export class ToggleProfileModal {
  static readonly type = '[App] Toggle Profile Modal';
}

export class DisplayEmergencyAlert {
  static readonly type = '[App] Display Emergency Alert';

  constructor(public alert: EmergencyAlertModel) {}
}

export class DismissEmergencyAlert {
  static readonly type = '[App] Dismiss Emergency Alert';
}
