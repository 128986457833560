
    <div class="registration-container">
      <div *ngIf="loading" class="loading-container">
        <ezs-loading [absoluteCenter]="true">
          <h2 class="text-center">Loading...</h2>
        </ezs-loading>
      </div>
      <form [formGroup]="form" class="fadeInLeft animated" *ngIf="showForm">
        <h2 class="has-subheading">Almost done! </h2>
        <span class="form-subheading">We just need a little more info to complete your registration</span>
        <div class="form-field">
          <input placeholder="Enter your full name" formControlName="displayName">
        </div>
        <div class="form-field">
          <input placeholder="Create a password" type="password" formControlName="password">
        </div>
        <div class="form-field">
          <input placeholder="Repeat your password" type="password" formControlName="repeatPassword">
        </div>
        <p class="error-msg" *ngIf="requestError">{{requestError}}</p>
        <button
          class="button button-block button-lg"
          [disabled]="!form.valid || completingRegistration"
          (click)="complete()">
          <img *ngIf="completingRegistration" class="loading-icon" src="assets/images/bars.svg"> {{updateButtonText}}
        </button>
      </form>
      <div *ngIf="reAuthRequired">
        <div class="form-container">
          <h2>Your authentication session has expired. Contact your Account Manager to resend a registration email.</h2>
          <!--<button
            class="button button-block button-lg"
            [disabled]="sendingEmail"
            (click)="complete()">
            <img *ngIf="completingRegistration" class="loading-icon" src="assets/images/bars.svg"> {{sendEmailButtonText}}
          </button>-->
        </div>
      </div>
    </div>

  