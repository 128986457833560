import { Injectable } from '@angular/core';
import { Toast, ToasterService } from 'angular2-toaster';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { NotificationModel } from '@ezspeek/models/common.models';
import { Store } from '@ngxs/store';
import { AppState } from '@ezspeek/store/state';
import { Observable, Subscription } from 'rxjs';
import { AUTH_ROLE } from '@ezspeek/models';
import { QueryFn } from '@angular/fire/firestore';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotificationAlertsService {

  private _notifications$: Observable<NotificationModel[]>[] = [];
  private _notificationCache: string[] = [];
  private _subscriptions: Subscription[] = [];

  constructor(private store: Store, private af: AngularFireService, private toaster: ToasterService) {}

  initialize() {
    const { aid, role } = this.store.selectSnapshot(AppState.authenticatedUser);

    if (aid) {
      this._addListener(aid, ref => ref.where('sendTo', '==', 'all'));

      if (role === AUTH_ROLE.CLIENT)
        this._addListener(aid, ref => ref.where('sendTo', '==', 'client'));
      else
        this._addListener(aid, ref => ref.where('sendTo', '==', 'admin'));
    }

    this._notifications$.forEach($ => {
      this._subscriptions.push($.pipe(
        debounceTime(250),
        map(alerts => {
          const time = Date.now();
          return alerts.filter(alert => alert.expiration > time);
        })
      ).subscribe(notifications => {
        notifications.forEach(notification => {
          this._displayNotification(notification);
        });
      }));
    });
  }

  cancel() {
    this._subscriptions.forEach(sub => {
      if (!sub.closed)
        sub.unsubscribe();
    });

    this._subscriptions = [];
  }

  pop(toast: Toast) {
    this.toaster.pop(toast);
  }

  private _displayNotification(notification: NotificationModel) {
    if (this._notificationCache.indexOf(notification.id) >= 0)
      return;

    this._notificationCache.push(notification.id);
    this.pop(notification);
  }

  private _addListener(aid: string, queryFn: QueryFn) {
    this._notifications$.push(
      this.af.getNotificationsCollectionRef(aid, queryFn).valueChanges()
    );
  }
}
