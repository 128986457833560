import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { PlatformService } from '@ezspeek/services/platform.service';

import { StripeJSLoader } from './stripe-js-loader';

import {
  BankAccount, BankAccountData, CardDataOptions,
  PaymentRequestOptions, Pii, PiiData,
  STRIPE_OPTIONS, STRIPE_PUBLISHABLE_KEY,
  StripeElement,
  StripeElementOptions,
  StripeElements,
  StripeElementsOptions,
  StripeOptions,
  StripeSourceData,
  StripeSourceParams,
  StripeSourceResult,
  StripeTokenResult
} from '../models';
import { StripeInstance } from '../stripe';
import { StripeUtils } from '../stripe.utils';
import { map } from 'rxjs/operators';

export interface StripeServiceInterface {
  elements(options?: StripeElementsOptions): Observable<StripeElements>;
  createToken(
    a: StripeElement | BankAccount | Pii,
    b: CardDataOptions | BankAccountData | PiiData | undefined
  ): Promise<StripeTokenResult>;
  createSource(
    a: StripeElement | StripeSourceData,
    b?: StripeSourceData | undefined
  ): Promise<StripeSourceResult>;
  retrieveSource(source: StripeSourceParams): Observable<StripeSourceResult>;
  paymentRequest(options: PaymentRequestOptions): any;
}

@Injectable()
export class StripeService implements StripeServiceInterface {
  private stripe: StripeInstance;

  constructor(
    @Inject(STRIPE_PUBLISHABLE_KEY) private key: string,
    @Inject(STRIPE_OPTIONS) private options: StripeOptions,
    private loader: StripeJSLoader,
    private platform: PlatformService
  ) {
    if (key) {
      this.stripe = new StripeInstance(loader, platform.window, key, options);
    }
  }

  public get Stripe(): Observable<any> {
    return this.loader.Stripe;
  }

  public getInstance() {
    return this.stripe.get();
  }

  public setKey(key: string, options?: StripeOptions) {
    return this.changeKey(key, options);
  }

  public changeKey(key: string, options?: StripeOptions) {
    this.stripe = new StripeInstance(this.loader, this.platform.window, key, options);

    return this.stripe;
  }

  public elements(options?: StripeElementsOptions): Observable<StripeElements> {
    return this.stripe.elements(options);
  }

  createCard(options: StripeElementOptions = StripeUtils.defaultCardStyle): Promise<StripeElement> {
    return this.elements({ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat' }]}).pipe(
      map(elements => elements.create('card', options))
    ).toPromise();
  }

  public createToken(
    a: StripeElement | BankAccount | Pii,
    b?: CardDataOptions | BankAccountData | PiiData
  ): Promise<StripeTokenResult> {
    return this.stripe.createToken(a, b);
  }

  public createSource(
    a: StripeElement | StripeSourceData,
    b?: StripeSourceData | undefined
  ): Promise<StripeSourceResult> {
    return this.stripe.createSource(a, b);
  }

  public retrieveSource(source: StripeSourceParams): Observable<StripeSourceResult> {
    return this.stripe.retrieveSource(source);
  }

  public paymentRequest(options: PaymentRequestOptions) {
    return this.stripe.paymentRequest(options);
  }

  public getCoupon(coupon: string) {

  }
}
