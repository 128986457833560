import { ReportStatus } from '@ezspeek/models';

export const REPORT_STATUSES: Array<ReportStatus> = [
  'NEW', 'VIEWED', 'ACTIVE', 'PENDING', 'RESOLVED', 'FALSE_REPORT'
];

export class Constants {
  static defaultProfilePictureUrl = 'assets/images/default-profile-picture.png';
  static get REPORT_STATUSES(): Array<ReportStatus> {
    return REPORT_STATUSES;
  }
}
