import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountModel, AUTH_ROLE, REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { EmergencyAlertModel, NotificationModel } from '@ezspeek/models/common.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ProductFeatures } from '@ezspeek/models/stripe.models';

enum ViewMode {
  SELECTION,
  NOTIFICATION,
  EMERGENCY_ALERT
}

@Component({
  selector: 'ezs-alerts',
  templateUrl: './alerts.html',
  styleUrls: ['./alerts.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsComponent {
  @Input() account: AccountModel;
  @Input() user: UserModel;
  @Input() features: ProductFeatures;
  @Input() set emergencyAlertRequestStatus(status: REQUEST_STATUS) {
    if (status === REQUEST_STATUS.SUCCESS)
      this.switchMode('select');

    this._emergencyAlertRequestStatus = status;
  }
  @Input() set notificationRequestStatus(status: REQUEST_STATUS) {
    if (status === REQUEST_STATUS.SUCCESS)
      this.switchMode('select');

    this._notificationRequestStatus = status;
  }

  @Output() activateEmergencyAlert: EventEmitter<EmergencyAlertModel> = new EventEmitter<EmergencyAlertModel>();
  @Output() previewNotification: EventEmitter<NotificationModel> = new EventEmitter<NotificationModel>();
  @Output() sendNotification: EventEmitter<NotificationModel> = new EventEmitter<NotificationModel>();

  emergencyAlertForm: FormGroup;
  notificationForm: FormGroup;

  private _viewMode: ViewMode = ViewMode.SELECTION;
  private _emergencyAlertRequestStatus: REQUEST_STATUS;
  private _notificationRequestStatus: REQUEST_STATUS;
  private _notificationStyle: string;
  private _showClose = true;

  constructor(private fb: FormBuilder) {}

  switchMode(mode: 'notification' | 'emergency' | 'select') {
    switch (mode) {
      case 'notification':
        this.buildNotificationForm();
        this._viewMode = ViewMode.NOTIFICATION;
        break;
      case 'emergency':
        this.buildEmergencyAlertForm();
        this._viewMode = ViewMode.EMERGENCY_ALERT;
        break;
      default:
        this._viewMode = ViewMode.SELECTION;
    }
  }

  buildEmergencyAlertForm() {
    this.emergencyAlertForm = this.fb.group({
      message: ['', Validators.required]
    });
  }

  buildNotificationForm() {
    this._notificationStyle  = 'primary';
    this._showClose = true;
    this.notificationForm = this.fb.group({
      sendTo: ['', Validators.required],
      title: [''],
      content: ['', Validators.required],
      expiration: ['', [Validators.max(30), Validators.min(1)]],
      duration: ['', Validators.min(0)],
    });
  }

  createEmergencyAlert() {
    if (this.alertsEnabled)
      this.activateEmergencyAlert.emit({
        activatedBy: this.user.uid,
        message: this.emergencyAlertForm.get('message').value
      });
  }

  selectStyle(style: string) {
    this._notificationStyle = style;
  }

  toggleShowClose() {
    this._showClose = !this._showClose;
  }

  preview() {
    this.previewNotification.emit(this.notification);
  }

  send() {
    this.sendNotification.emit(this.notification);
  }

  get isAccountMgr(): boolean {
    return !!this.user && this.user.role === AUTH_ROLE.ACCOUNT_MGR;
  }

  get isSelectionMode(): boolean {
    return this._viewMode === ViewMode.SELECTION;
  }

  get isNotificationMode(): boolean {
    return this._viewMode === ViewMode.NOTIFICATION;
  }

  get isEmergencyAlertMode(): boolean {
    return this._viewMode === ViewMode.EMERGENCY_ALERT;
  }

  get showAlertsUnavailableMessage(): boolean {
    return !!this.features && (!this.isAccountMgr || !this.alertsEnabled);
  }

  get alertsUnavailableMessage(): string {
    if (!this.isAccountMgr)
      return 'You do not have proper authorization to manage alerts. Please contact your account manager.';

    return 'Uh oh! Alerts are not included with your current plan';
  }

  get activatingEmergencyAlert(): boolean {
    return this._emergencyAlertRequestStatus === REQUEST_STATUS.LOADING;
  }

  get style(): string { return this._notificationStyle; }

  get showClose(): boolean { return this._showClose; }

  get calculatedExp(): number {
    let days = +this.notificationForm.get('expiration').value;
    days = days >= 1 ? days : 1;
    return moment().add(days, 'days').toDate().getTime();
  }

  get timeout(): number {
    const duration = this.notificationForm.get('duration').value;
    return typeof duration === 'number' && duration >= 0 ? duration * 1000 : 5000;
  }

  get notification(): NotificationModel {
    if (!this.notificationForm)
      return null;

    return {
      body: this.notificationForm.get('content').value,
      sendTo: this.notificationForm.get('sendTo').value,
      expiration: this.calculatedExp,
      type: this._notificationStyle,
      createdBy: this.user.uid,
      timeout: this.timeout,
      showCloseButton: this.showClose,
      title: this.notificationForm.get('title').value
    };
  }

  get notificationsEnabled(): boolean {
    return !!this.features && this.features.customNotifications;
  }

  get emergencyAlertsEnabled(): boolean {
    return !!this.features && this.features.emergencyAlerts;
  }

  get alertsEnabled(): boolean {
    return this.notificationsEnabled || this.emergencyAlertsEnabled;
  }
}
