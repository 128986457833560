export interface RGB {
  r: number;
  g: number;
  b: number;
}

export interface RGBA extends RGB {
  a: number;
}

export type Color = RGB | RGBA | string;

export class ThemeColorMap {
  primary: Color;
  primaryDark: Color;
  accent: Color;
  accent2: Color;
  accent2Light: Color;
  success: Color;
  warn: Color;
  alert: Color;
  caution: Color;
  mute: Color;
}

export class Colors {
  static purple: RGB = Colors.mapRGB(81, 76, 124);
  static darkPurple: RGB = Colors.mapRGB(75, 72, 101);
  static peach: RGB = Colors.mapRGB(255, 193, 153);
  static teal: RGB = Colors.mapRGB(44, 153, 178);
  static lightTeal: RGB = Colors.mapRGB(112, 203, 224);
  static green: RGB = Colors.mapRGB(105, 210, 147);
  static red: RGB = Colors.mapRGB(244, 67, 54);
  static orange: RGB = Colors.mapRGB(255, 152, 0);
  static yellow: RGB = Colors.mapRGB(249, 225, 71);
  static grey: RGB = Colors.mapRGB(120, 120, 120);

  static rgba = {
    purple: Colors.mapRGBA(81, 76, 124),
    darkPurple: Colors.mapRGBA(75, 72, 101),
    peach: Colors.mapRGBA(255, 193, 153),
    aqua: Colors.mapRGBA(44, 153, 178),
    lightAqua: Colors.mapRGBA(112, 203, 224),
    green: Colors.mapRGBA(105, 210, 147),
    red: Colors.mapRGBA(244, 67, 54),
    orange: Colors.mapRGBA(255, 152, 0),
    yellow: Colors.mapRGBA(249, 225, 71),
    grey: Colors.mapRGBA(210, 210, 210)
  };

  static checkAlphaValue(alpha: number) {
    if (typeof alpha !== 'number' || alpha > 1 || alpha < 0) {
      throw new Error(`Invalid alpha value: [${alpha}] must be a number between 0 - 1`);
    }
  }

  static checkRGBValue(value: number) {
    if (typeof value !== 'number' || value > 255 || value < 0) {
      throw new Error(`Invalid RGB value: [${value}] must be a number between 0 - 255`);
    }
  }

  static mapRGB(r: number, g: number, b: number): RGB {
    Colors.checkRGBValue(r);
    Colors.checkRGBValue(g);
    Colors.checkRGBValue(b);

    return { r, g, b };
  }

  static mapRGBA(r: number, g: number, b: number, a: number = 1): RGBA {
    const rgb = Colors.mapRGB(r, g, b);
    Colors.checkAlphaValue(a);

    return { ...rgb, a };
  }

  static rgbaToString({ r, g, b, a }: RGBA): string {
    Colors.checkRGBValue(r);
    Colors.checkRGBValue(g);
    Colors.checkRGBValue(b);
    Colors.checkAlphaValue(a);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
}

export class EzsColors {
  static rgbMap: ThemeColorMap = {
    primary: Colors.purple,
    primaryDark: Colors.darkPurple,
    accent: Colors.peach,
    accent2: Colors.teal,
    accent2Light: Colors.lightTeal,
    success: Colors.green,
    warn: Colors.red,
    alert: Colors.orange,
    caution: Colors.yellow,
    mute: Colors.grey
  };

  static primary = EzsColors.getFn('primary');
  static primaryDark = EzsColors.getFn('primaryDark');
  static accent = EzsColors.getFn('accent');
  static accent2 = EzsColors.getFn('accent2');
  static accent2Light = EzsColors.getFn('accent2Light');
  static success = EzsColors.getFn('success');
  static warn = EzsColors.getFn('warn');
  static alert = EzsColors.getFn('alert');
  static caution = EzsColors.getFn('caution');
  static mute = EzsColors.getFn('mute');
  static black = (alpha: number = 1) => Colors.rgbaToString({ r: 0, g: 0, b: 0, a: alpha });

  static get(colorName: keyof ThemeColorMap, alpha: number = 1): string {
    const { r, g, b }: RGB = <RGB>EzsColors.rgbMap[colorName];

    return Colors.rgbaToString({ r, g, b, a: alpha });
  }

  static getFn(colorName: keyof ThemeColorMap) {
    return (alpha: number = 1) => {
      return EzsColors.get(colorName, alpha);
    };
  }
}
