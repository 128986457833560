    
    <ezs-reports-list 
      *ngIf="!viewingReport" 
      [reports]="reports$ | async"
      [user]="authenticatedUser$ | async"
      [showArchived]="showArchived"
      [applyNewMessageFilter]="newMessageFilterActive"
      (updateStatusFilter)="updateStatusFilter($event)"
      [statusFilters]="statusFilters"
      (toggleNewMessageFilter)="toggleNewMessages()"
      (toggleArchived)="toggleArchived()">
    </ezs-reports-list>
    <ezs-report-details 
      *ngIf="viewingReport"
      [user]="authenticatedUser$ | async"
      [userPhotos]="userPhotos$ | async"
      [userDisplayNames]="userDisplayNames$ | async"
      [report]="selectedReport$ | async"
      [sendChatMessageStatus]="sendChatMessageStatus"
      [sendChatMessageError]="sendChatMessageError"
      [chatMessages]="chatMessages$ | async"
      [notes]="notes$ | async"
      [addNoteStatus]="addNoteStatus"
      [addNoteError]="addNoteError"
      (addNote)="addNote($event)"
      (sendChatMessage)="sendChatMessage($event)"
      (updateReport)="updateReport($event)">
    </ezs-report-details>
  