import { Component } from '@angular/core';
import { FirebaseAuthUser, REQUEST_STATUS } from '@ezspeek/models';
import { Select, Store } from '@ngxs/store';
import { AppState, AuthState } from '@ezspeek/store/state';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { filter, take } from 'rxjs/operators';

@Component({
  template: `
    <ezs-email-registration
      [user]="user$ | async"
      [requestStatus]="registrationStatus"
      [requestError]="registrationError"
      (registerEmail)="registerNewUser($event)">
    </ezs-email-registration>
  `
})
export class EmailRegistrationContainer {

  @Select(AuthState.userState) user$: FirebaseAuthUser;

  private _aid: string;
  private _emailLink: string;
  private _registrationStatus: REQUEST_STATUS;
  private _registrationError: string;

  constructor(private store: Store, private af: AngularFireService, private route: ActivatedRoute, private router: Router) {
    this._emailLink = this.router.url;
    this._aid = this.route.snapshot.queryParams['aid'];
    if (!af.verifyEmailLink(this.emailLink))
      this.router.navigateByUrl('/admin/sign-in');
  }


  registerNewUser(email: string) {
    this._registrationError = null;
    this._registrationStatus = REQUEST_STATUS.LOADING;

    this.af.registerNewAdminWithEmailLink(this.emailLink, email, this.aid)
      .then(res => {
        this.store.select(AppState.authenticatedUser)
          .pipe(filter(user => !!user), take(1))
          .subscribe(_ => {
            this._registrationStatus = REQUEST_STATUS.SUCCESS;
            this.store.dispatch(new Navigate(['/admin', 'continue-registration']));
          });
      })
      .catch(err => {
        this._registrationStatus = REQUEST_STATUS.ERROR;
        this._registrationError = err.message || err;
      });
  }

  get aid(): string { return this._aid; }
  get emailLink(): string { return this._emailLink; }
  get registrationStatus(): REQUEST_STATUS { return this._registrationStatus; }
  get registrationError(): string { return this._registrationError; }
}
