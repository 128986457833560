import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AUTH_ROLE, UserModel } from '@ezspeek/models';
import { EmergencyAlertModel } from '@ezspeek/models/common.models';

interface AlertDismissal {
  uid: string;
  alertId: string;
  aid: string;
}

@Component({
  selector: 'ezs-emergency-alert',
  template: `
    <div class="emergency-alert-backdrop">
      <mat-card class="scaleUp animated" *ngIf="alert">
        <mat-card-title>
          <mat-icon>priority_high</mat-icon> EMERGENCY ALERT <mat-icon>priority_high</mat-icon>
        </mat-card-title>
        <mat-card-content>
          {{message}}
        </mat-card-content>
        <mat-card-actions *ngIf="isAcctMgr">
          <button mat-button (click)="dismissAlert()">
            <mat-icon>notifications_off</mat-icon> Dismiss Alert
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  `,
  styles: [`
    .emergency-alert-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,.85);
      z-index: 999999;
      padding: 25% 1em 1em;
    }
    
    mat-card {
      position: relative;
      width: 100%;
      max-width: 400px;
      padding: 1em;
      margin: 0 auto;
      background: rgba(244, 67, 54, .85);
      color: #fff;
    }
    
    mat-card-title {
      font-size: 1.4em;
      font-family: 'Helvetica', sans-serif;
      font-weight: 700;
      text-align: center;
    }
    
    mat-card-title mat-icon {
      height: auto;
      width: auto;
      vertical-align: middle;
      margin-top: -3px;
      font-size: 1.4em;
    }
    
    mat-card-content {
      font-size: 1.15em;
      font-weight: 300;
      padding: 0 .25em .5em;
      margin: 0;
    }
    
    mat-card-actions {
      padding: .5em  1em 1em !important;
      text-align: right;
    }
    
    mat-card-actions button {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      opacity: .5;
    }

    mat-card-actions button:hover {
      opacity: 1;
    }
    
    @media (min-width: 768px) {
      .emergency-alert-backdrop {
        padding-top: 15%;
      }
    }

    @media (min-width: 350px) {
      mat-card-title {
        font-size: 1.5em;
      }

      mat-card-title mat-icon {
        font-size: 1.5em;
      }
      
      mat-card-content {
        font-size: 1.2em;
      }
    }
  `]
})
export class EmergencyAlertComponent {
  @Input() user: UserModel;
  @Input() alert: EmergencyAlertModel;

  @Output() dismiss: EventEmitter<AlertDismissal> = new EventEmitter<AlertDismissal>();

  readonly defaultMessage = 'This is an emergency alert message. Please take extreme caution and follow emergency procedures.';

  constructor() {}

  dismissAlert() {
    if (this.isAcctMgr) {
      const { uid, aid } = this.user;
      this.dismiss.emit({ alertId: this.alert.id, uid, aid });
    }
  }

  get isAcctMgr() {
    return this.user && this.user.role === AUTH_ROLE.ACCOUNT_MGR;
  }

  get message(): string {
    return this.alert.message || this.defaultMessage;
  }
}
