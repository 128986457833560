import { NgModule } from '@angular/core';
import {
  MatButtonModule, MatCardModule, MatToolbarModule,
  MatDatepickerModule, MatIconModule, MatListModule,
  MatDividerModule, MatBadgeModule, MatDialogModule,
  MatMenuModule, MatTabsModule, MatChipsModule, MatSlideToggleModule,
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

const MATERIAL_MODULES = [
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatIconModule,
  MatListModule,
  MatDividerModule,
  MatBadgeModule,
  MatDialogModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatTabsModule,
];

@NgModule({
  imports: MATERIAL_MODULES,
  exports: MATERIAL_MODULES
})
export class AppMaterialModule { }
