import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseAuthUser, REQUEST_STATUS } from '@ezspeek/models';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ezs-email-registration',
  template: `
    <div class="registration-container">
      <form [formGroup]="form" class="fadeInUp animated" *ngIf="!user && !!form">
        <h2>To register, we need to verify your email address</h2>
        <div class="form-field">
          <input placeholder="Enter your email" type="email" formControlName="email">
        </div>
        <p class="error-msg" *ngIf="requestError">{{requestError}}</p>
        <button
          class="button button-block button-lg"
          [disabled]="!form.valid || isRegistering"
          (click)="submitEmail()">
          <img *ngIf="isRegistering" class="loading-icon" src="assets/images/bars.svg"> {{registerButtonText}}
        </button>
      </form>
    </div>
  `,
  styleUrls: ['../admin-registration.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailRegistrationComponent implements OnInit {

  @Input() user: FirebaseAuthUser;
  @Input() requestError: string;
  @Input() requestStatus: REQUEST_STATUS;

  @Output() registerEmail: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  submitEmail() {
    if (!this.form.valid)
      return;

    this.registerEmail.emit(this.email.value);
  }

  get email(): AbstractControl { return this.form.get('email'); }

  get registerButtonText(): string { return this.isRegistering ? 'Registering...' : 'Register'; }

  get isRegistering(): boolean {
    return this.requestStatus === REQUEST_STATUS.LOADING;
  }
}
