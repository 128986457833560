
    <h1 class="headline">Reporting made simple</h1>
    <form [formGroup]="form" *ngIf="form" class="login-form">
      <div class="form-field">
        <input placeholder="Email" type="email" formControlName="email">
      </div>
      <div class="form-field">
        <input placeholder="Password" type="password" formControlName="password">
      </div>
      <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
      <button class="button button-block button-lg" [disabled]="!form.valid" (click)="submitForm()">Sign In</button>
      <p class="question">
        Don't have an account?
        <a [routerLink]="['/sign-up']">Sign up now</a>
      </p>
      <p class="question">
        Need to submit a report?
        <a [routerLink]="['/client/sign-in']">Sign in here</a>
      </p>
    </form>
  