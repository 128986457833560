import {AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {PlanModel} from '@ezspeek/models/stripe.models';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactUsMessage} from '@ezspeek/models/common.models';
import {REQUEST_STATUS, UserModel} from '@ezspeek/models';

@Component({
  selector: 'ezs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements AfterViewChecked {

  @Input() user: UserModel;
  @Input() plans: PlanModel[];
  @Input() displayedPhrase: string;
  @Input() contactUsStatus: REQUEST_STATUS;
  @Input() contactUsErrorMsg: string;

  @Output() submitMessage: EventEmitter<ContactUsMessage> = new EventEmitter<ContactUsMessage>();

  contactForm: FormGroup;

  private _isPlanPaymentMonthly = true;

  constructor(private router: Router, private fb: FormBuilder) { }

  ngAfterViewChecked() {
    if (this.user !== undefined && !this.contactForm) {
      this.contactForm = this.fb.group({
        name: [this.isAuthenticated ? this.user.displayName : ''],
        email: [this.isAuthenticated ? this.user.email : '', [Validators.required, Validators.email]],
        message: ['', Validators.required]
      });
    }
  }

  scrollTo(el: HTMLElement) {
    const { top } = el.getBoundingClientRect();
    window.scroll({ top: top - 70, left: 0, behavior: 'smooth' });
  }

  togglePlanPaymentType() {
    this._isPlanPaymentMonthly = !this._isPlanPaymentMonthly;
  }

  selectPlan(planName: string) {
    const plan = this.plans.find(p => {
      const interval = this.isPlanPaymentMonthly ? 'Monthly' : 'Yearly';
      return p.productName.includes(planName) && p.interval === interval;
    }).id;
    this.router.navigate(['/sign-up', { plan }]);
  }

  sendMessage() {
    const name: string = this.contactName.value;
    const email: string = this.contactEmail.value;
    const message: string = this.contactMessage.value;

    if (!email || !message) return;

    const msg: ContactUsMessage = { email, message };

    if (!!name) msg.name = name;

    if (!!this.user) {
      msg.aid = this.user.aid;
      msg.uid = this.user.uid;
    }

    this.submitMessage.emit(msg);
  }

  private _getControl(controlName: string): AbstractControl {
    return !!this.contactForm ? this.contactForm.get(controlName) : null;
  }

  private _getPlanByName(name: string): PlanModel {
    return this.displayedPlans.find(plan => plan.productName === name);
  }

  get contactName(): AbstractControl { return this._getControl('name'); }
  get contactEmail(): AbstractControl { return this._getControl('email'); }
  get contactMessage(): AbstractControl { return this._getControl('message'); }

  get contactUsButtonText(): string { return 'Send Message'; }

  get displayedPlans(): Array<PlanModel> {
    return !!this.plans ? this.plans.filter(plan => plan.interval === this.planInterval) : null;
  }

  get isAuthenticated(): boolean { return !!this.user; }

  get isPlanPaymentMonthly(): boolean { return this._isPlanPaymentMonthly; }
  get paymentType(): string { return this.isPlanPaymentMonthly ? 'month' : 'year'; }
  get paymentTypeSliderText(): string { return this.isPlanPaymentMonthly ? 'Monthly' : 'Yearly'; }
  get trialPeriod(): number { return this.isPlanPaymentMonthly ? 14 : 30; }
  get planInterval(): string { return this.isPlanPaymentMonthly ? 'Monthly' : 'Yearly'; }
  get plansSectionHeading(): string { return this.isAuthenticated ? 'Compare Plans' : 'Select Your Plan'; }
  get year(): string { return new Date().getFullYear().toString(); }

  get essentialPlan(): PlanModel { return this._getPlanByName('Essential'); }
  get premiumPlan(): PlanModel { return this._getPlanByName('Premium'); }
  get ultimatePlan(): PlanModel { return this._getPlanByName('Ultimate'); }

  get contactUsMessageSuccess() { return this.contactUsStatus === REQUEST_STATUS.SUCCESS; }
  get contactUsMessageError() { return this.contactUsStatus === REQUEST_STATUS.ERROR; }
  get processingForm() { return this.contactUsStatus === REQUEST_STATUS.LOADING; }
}
