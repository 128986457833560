import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { auth as FirebaseAuth } from 'firebase/app';

@Directive({
  selector: '[ezsRecaptcha]'
})
export class RecaptchaDirective implements OnInit {
  @Input() size = 'normal';

  private _verifier: FirebaseAuth.RecaptchaVerifier;
  private _widgetId: number;

  @Output() solved: EventEmitter<any> = new EventEmitter<any>();
  @Output() rendered: EventEmitter<number> = new EventEmitter<number>();
  @Output() verified: EventEmitter<any> = new EventEmitter<any>();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this._verifier = new FirebaseAuth.RecaptchaVerifier(this.el.nativeElement, {
      size: this.size,
      callback: (response) => {
        this.solved.emit(response);
      }
    });

    this.render();
  }

  render() {
    if (!this._verifier)
      return;

    this._verifier.render().then(widgetId => {
      this._widgetId = widgetId;
      this.rendered.emit(widgetId);
    });
  }

  verify() {
    if (!this._verifier)
      return;

    return this._verifier.verify();
  }
}
