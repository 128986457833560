
    <div *ngIf="(loading$ | async); else loadedState" class="pre-loader">
      <div class="pre-loader_inner">
        <img  class="logo" src="assets/images/ezSpeekLogo2.svg" alt="ezSpeek">
        <span class="brand">ez<span>Speek</span></span>
        <img src="assets/images/bars.svg" alt="loading...">
      </div>
    </div>
    <ng-template #loadedState>
      <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
      <div [class.client-app]="isClientApp" class="app-container">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
      <ezs-emergency-alert 
        *ngIf="(emergencyAlert$ | async) as eAlert" 
        [alert]="eAlert" 
        [user]="user$ | async" 
        (dismiss)="dismissEmergencyAlert($event)">
      </ezs-emergency-alert>
    </ng-template>
  