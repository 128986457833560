<div class="client-dashboard-container">
  <div class="dashboard-intro">
    <span class="intro-title">Speek <span class="intro-keyword keyword-{{keywordColorClass}}">{{displayedKeyword}}</span></span>
    <span class="intro-sub-title scaleUp animated">It's time to take back <span class="color-{{keywordColorClass}}">Our House</span>!</span>
  </div>
  <button class="fadeInUp animated" mat-raised-button color="primary" [routerLink]="['/client', 'report-form']">
    <mat-icon>description</mat-icon> <span class="button-text">Report Incident</span>
  </button>
  <button class="fadeInUp animated" [disabled]="reportCount < 1" mat-raised-button [color]="reportsButtonColor" [routerLink]="['/client', 'reports']">
    <mat-icon matBadge="!" [matBadgeHidden]="!hasNewMessage" class="client-dashboard-button-icon badge-color-alert">
      folder_open
    </mat-icon>
    <span class="button-text">My Reports</span>
  </button>
  <button class="fadeInUp animated" [disabled]="!voiceChatEnabled" mat-raised-button [class.button-accent2]="voiceChatEnabled">
    <mat-icon>voice_chat</mat-icon> <span class="button-text">Live Stream</span>
  </button>
</div>
