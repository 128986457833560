import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CroppieOptions } from 'croppie';
import { CroppieDirective } from '@ezspeek/common/directives/croppie.directive';

enum ProfileMode {
  VIEW,
  EDIT,
  CROP_PHOTO
}

@Component({
  selector: 'ezs-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent {

  @Input() isOpen: boolean;
  @Input() user: UserModel;
  @Input() set uploadImageRequestStatus(status: REQUEST_STATUS) {
    if (status === REQUEST_STATUS.SUCCESS) {
      this._mode = ProfileMode.EDIT;
      this._selectedPhotoSrc = null;
    }

    this._requests.uploadImage = status;
  }
  @Input() set updateNameRequestStatus(status: REQUEST_STATUS) {
    if (status === REQUEST_STATUS.SUCCESS) {
      this.name.reset(this.user.displayName);
    }

    this._requests.updateName = status;
  }
  @Input() set updateTitleRequestStatus(status: REQUEST_STATUS) {
    if (status === REQUEST_STATUS.SUCCESS) {
      this.title.reset(this.user.title);
    }

    this._requests.updateTitle = status;
  }

  @Output() toggleOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadProfileImage: EventEmitter<{ blob: Blob, uid: string }> = new EventEmitter<{ blob: Blob, uid: string }>();
  @Output() updateName: EventEmitter<{ displayName: string, uid: string }> = new EventEmitter<{ displayName: string, uid: string }>();
  @Output() updateTitle: EventEmitter<{ title: string, uid: string }> = new EventEmitter<{ title: string, uid: string }>();

  @ViewChild(CroppieDirective) croppie: CroppieDirective;

  form: FormGroup;

  private _mode: ProfileMode = ProfileMode.VIEW;
  private _selectedPhotoSrc: string;

  private _requests: { [key: string]: REQUEST_STATUS } = {
    uploadImage: null,
    updateName: null,
    updateTitle: null
  };

  constructor(private fb: FormBuilder) {}

  buildForm() {
    this.form = this.fb.group({
      'name': [this.user.displayName, [ Validators.required ]],
      'title': [this.user.title || '']
    });
  }

  edit() {
    this.buildForm();
    this._mode = ProfileMode.EDIT;
  }

  cancelEdit() {
    this._mode = ProfileMode.VIEW;
  }

  selectImage(imageInput: HTMLInputElement) {
    imageInput.click();
  }

  cropImage($event) {
    const image = $event.target.files[0];
    const reader = new FileReader();
    reader.onload = ({ target: { result } }: any) => {
      this._selectedPhotoSrc = result;
    };
    reader.readAsDataURL(image);
    this._mode = ProfileMode.CROP_PHOTO;
  }

  cancelCrop() {
    this._mode = ProfileMode.EDIT;
    this._selectedPhotoSrc = null;
  }

  rotateImg(direction: 'left' | 'right') {
    const deg = direction === 'right' ? -90 : 90;
    this.croppie.croppie.rotate(deg);
  }

  upload() {
    if (this.croppie) {
      this.croppie.croppie.result({
        size: 'viewport',
        type: 'blob',
        circle: false
      }).then(blob => {
        this.uploadProfileImage.emit({ blob, uid: this.user.uid });
      });
    }
  }

  saveName() {
    if (!this.isRequestLoading && !!this.name.value && this.nameHasChanges)
      this.updateName.emit({ displayName: this.name.value, uid: this.user.uid });
  }

  saveTitle() {
    if (!this.isRequestLoading && !!this.title.value && this.titleHasChanges)
      this.updateTitle.emit({ title: this.title.value, uid: this.user.uid });
  }

  close() {
    if (this._mode !== ProfileMode.VIEW) {
      this._selectedPhotoSrc = null;
      this._mode = ProfileMode.VIEW;
    }

    this.toggleOpen.emit();
  }

  get selectedPhotoSrc(): string { return this._selectedPhotoSrc; }

  get croppieOptions(): CroppieOptions {
    return {
      enableExif: true,
      enableOrientation: true,
      viewport: {
        height: 200,
        width: 200,
        type: 'circle'
      },
      boundary: {
        height: 260,
        width: 260
      }
    };
  }

  get name(): AbstractControl { return this.form.get('name'); }
  get title(): AbstractControl { return this.form.get('title'); }

  get isEditMode(): boolean { return this._mode === ProfileMode.EDIT; }
  get isViewMode(): boolean { return this._mode === ProfileMode.VIEW; }
  get isCropPhotoMode(): boolean { return this._mode === ProfileMode.CROP_PHOTO; }

  get uploadImageRequestStatus(): REQUEST_STATUS { return this._requests.uploadImage; }
  get uploadingImage(): boolean { return this.uploadImageRequestStatus === REQUEST_STATUS.LOADING; }
  get uploadPhotoButtonText(): string {
    return this.uploadingImage ? 'Uploading...' : 'Upload';
  }
  get userPhotoURL(): string {
    return this.user.photoURL || '/assets/images/default-profile-picture.png';
  }

  get nameHasChanges(): boolean {
    return this.form && this.name.value !== this.user.displayName;
  }

  get updatingName(): boolean {
    return this._requests.updateName === REQUEST_STATUS.LOADING;
  }

  get editingName(): boolean {
    return !this.updatingName && this.nameHasChanges;
  }

  get titleHasChanges(): boolean {
    const title = this.user.title || '';
    return this.form && this.title.value !== title ;
  }

  get updatingTitle(): boolean {
    return this._requests.updateTitle === REQUEST_STATUS.LOADING;
  }

  get editingTitle(): boolean {
    return !this.updatingTitle && this.titleHasChanges;
  }

  get isRequestLoading(): boolean {
    return this.updatingTitle || this.updatingName || this.uploadingImage;
  }
}
