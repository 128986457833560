export enum AUTH_ROLE {
  CLIENT,
  ADMIN,
  ACCOUNT_MGR,
}

export interface UserModel {
  uid: string;
  email?: string;
  aid?: string;
  emailVerified?: boolean;
  isAccountMgr?: boolean;
  isClient?: boolean;
  role?: AUTH_ROLE;
  permissions?: { [key: string]: boolean };
  title?: string;
  photoURL?: string;
  displayName?: string;
  deleted?: boolean;
}

export interface AccountModel {
  aid: string;
  email: string;
  accountMgrUid: string;
  accessId?: string;
  users?: { [key: string]: boolean };
  emergencyAlertMsg?: string;
  status?: string;
  subscriptionId?: string;
  cid?: string;
}
