import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentFormatPipe implements PipeTransform {
  transform(value: Date | string | number, format: string = 'MM-DD-YYYY'): string {
    return moment(value).format(format);
  }
}
