import { FirebaseAuthUser } from '@ezspeek/models';

export class SetAuthState {
  static readonly type = '[Auth] Set Auth State';
  constructor(public authState: FirebaseAuthUser) {}
}

export class RegisterAccount {
  static readonly type = '[Auth] Register Account';
  constructor( public uid: string, public email: string) {}
}

export class RegistrationSuccess {
  static readonly type = '[Auth] Registration Success';
}

export class RegistrationError {
  static readonly type = '[Auth] Registration Error';
  constructor(public error: string) {}
}

export class SignIn {
  static readonly type = '[Auth] SignIn';
  constructor(public email: string, public password: string) {}
}

export class SignInSuccess {
  static readonly type = '[Auth] SignIn Success';
}

export class SignInError {
  static readonly type = '[Auth] SignIn Error';
  constructor(public error: string) {}
}

export class SignOut {
  static readonly type = '[Auth] SignOut';
}

export class AuthorizeClient {
  static readonly type = '[Auth] Authorize Client';
  constructor(public accessId: string) {}
}

export class AuthorizeClientSuccess {
  static readonly type = '[Auth] Authorize Client Success';
}

export class AuthorizeClientError {
  static readonly type = '[Auth] Authorize Client Error';
}
