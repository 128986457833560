import { Component, Input } from '@angular/core';
import { AUTH_ROLE, MessageModel } from '@ezspeek/models';

@Component({
  selector: 'ezs-chat-message',
  template: `
    <div [class.client-view]="clientView" [class.client-message]="fromClient" class="ezs-message">
      <span class="ezs-message-wrapper elevation-2">{{message.text}}</span>
      <span *ngIf="!fromClient && !clientView" class="user-profile-picture">
        <img [src]="userPhotoURL"/>
      </span>
      <span class="ezs-message-timestamp">{{message.createdAt | moment:'ddd MMM D, YYYY h:mm a'}}</span>
    </div>
  `,
  styleUrls: ['./ezs-message.scss']
})
export class ChatMessageComponent {
  @Input() message: MessageModel;
  @Input() clientView: boolean;
  @Input() userPhotoURL: string;

  constructor() {}

  get fromClient() {
    return this.message.authRole === AUTH_ROLE.CLIENT;
  }
}
