import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HideBodyBackground, SetAdminPageTitle } from '@ezspeek/store/actions/app.actions';
import { LoadReports } from '@ezspeek/store/actions/firestore.actions';
import {AppState, FirestoreState} from '@ezspeek/store/state';
import {AccountModel, ReportModel, UserModel} from '@ezspeek/models';
import { Observable } from 'rxjs/observable';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { ProductFeatures } from '@ezspeek/models/stripe.models';

@Component({
  template: `
    <ezs-dashboard 
      [user]="authenticatedUser$ | async"
      [account]="account$ | async"
      [reports]="reports$ | async"
      [features]="features$ | async"
      (init)="onComponentInit()">
    </ezs-dashboard>
  `
})
export class DashboardContainer implements OnInit {
  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<UserModel>;
  @Select(FirestoreState.account) account$: Observable<AccountModel>;
  @Select(AppState.accountFeatures) features$: Observable<ProductFeatures>;
  reports$: Observable<ReportModel[]>;

  constructor(private store: Store, private af: AngularFireService) {}

  ngOnInit() {
    this.store.dispatch(new SetAdminPageTitle('Dashboard', 'Overview of Incident Reports and Statistics'));
    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);
    this.store.dispatch(new LoadReports(aid));
    this.reports$ = this.af.getReportsCollectionRef(aid).valueChanges();
  }

  onComponentInit() {
    this.store.dispatch(new HideBodyBackground());
  }
}
