import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {AccountModel, REPORT_STATUS, ReportModel, UserModel} from '../../models/index';
import * as moment from 'moment';
import { EzsColors } from '@ezspeek/common/theme';
import { ChartConfig, ChartData } from '@ezspeek/models/chart.models';
import { ProductFeatures } from '@ezspeek/models/stripe.models';

interface MonthlyReportStats {
  totalReports: number;
  totalOpen: number;
  totalResolved: number;
}

@Component({
  selector: 'ezs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements AfterViewInit {
  @Input() user: UserModel;
  @Input() account: AccountModel;
  @Input() reports: ReportModel[];
  @Input() features: ProductFeatures;

  @Output() init: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit() {
    this.init.emit();
  }

  getIsReportOpen(status: REPORT_STATUS): boolean {
    return status !== REPORT_STATUS.RESOLVED &&
      status !== REPORT_STATUS.FALSE_REPORT;
  }

  get accountInactive(): boolean {
    return !!this.account && this.account.status === 'inactive';
  }

  get recentReports(): ReportModel[] {
    if (!this.reports)
      return [];

    return this.reports.sort((a, b) => {
      return b.createdAt - a.createdAt;
    }).slice(0, 4);
  }

  get outstandingReports(): ReportModel[] {
    if (!this.reports)
      return [];

    return this.reports
      .filter(report => {
        const reportDate = moment(report.createdAt);
        const today = moment();

        return !report.archived && this.getIsReportOpen(report.status) && today.diff(reportDate, 'days') > 7;
      })
      .sort((a, b) => {
        return a.createdAt - b.createdAt;
      })
      .slice(0, 4);
  }

  get noRecentReports(): boolean {
    return this.recentReports.length < 1;
  }

  get noOutstandingReports(): boolean {
    return this.outstandingReports.length < 1;
  }

  get currentMonthReports(): ReportModel[] {
    if (!this.reports)
      return [];

    const currentMoment = moment();
    return this.reports.filter(report => {
      const reportMoment = moment(report.createdAt);
      return (currentMoment.year() === reportMoment.year()) && (currentMoment.month() === reportMoment.month());
    });
  }

  get currentMonthStats(): MonthlyReportStats {
    return {
      totalReports: this.currentMonthReports.length,
      totalResolved: this.currentMonthReports.filter(report => {
        return report.status === REPORT_STATUS.RESOLVED;
      }).length,
      totalOpen: this.currentMonthReports.filter(report => {
        return !report.archived &&
          report.status !== REPORT_STATUS.RESOLVED &&
          report.status !== REPORT_STATUS.FALSE_REPORT;
      }).length
    };
  }

  get currentMonth(): string { return moment().format('MMMM'); }

  get showChart(): boolean {
    return this.currentMonthReports.length > 0;
  }

  get byCategoryChartData(): ChartData {
    const categoryCounts = {
      bullying: 0,
      harassment: 0,
      'drugs/alcohol': 0,
      'gun/weapon': 0,
      other: 0
    };

    this.currentMonthReports.forEach(report => {
      const category = report.category.toLowerCase();

      if (categoryCounts[category] >= 0) {
        categoryCounts[category]++;
      } else {
        categoryCounts.other++;
      }
    });

    const labels: string[] = ['Gun/Weapon', 'Bullying', 'Drugs/Alcohol', 'Harassment', 'Other'];
    const data: number[] = labels.map(category => categoryCounts[category.toLowerCase()]);

    return {
      labels,
      datasets: [{
        data,
        backgroundColor: [
          EzsColors.warn(.5),
          EzsColors.alert(.5),
          EzsColors.accent2(.5),
          EzsColors.caution(.5),
          EzsColors.mute(.5)
        ],
        borderColor: [
          EzsColors.warn(),
          EzsColors.alert(),
          EzsColors.accent2(),
          EzsColors.caution(),
          EzsColors.mute()
        ],
        borderWidth: 1
      }]
    };
  }

  get byCategoryChartConfig(): ChartConfig {
    const max = this.byCategoryChartData.datasets[0].data.sort((a, b) => b - a)[0];

    return {
      type: 'horizontalBar',
      data: this.byCategoryChartData,
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Incidents by Category',
          fontSize: 14,
          fontFamily: 'Montserrat',
          fontStyle: 'bold'
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              fontSize: 10
            }
          }],
          xAxes: [{
            gridLines: {
              drawBorder: false,
              tickMarkLength: 0,
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
              stepSize: 1,
              suggestedMax: max <= 3 ? 5 : max + (Math.round(max * .25)),
            },
            scaleLabel: {
              padding: 5
            }
          }]
        }
      }
    };
  }
}
