
    <h1 class="headline fadeInUp animated">Provide as much detail as possible</h1>
    <form [formGroup]="form" *ngIf="form" class="login-form fadeInUp animated">
      <div class="form-field">
        <select placeholder="Email" formControlName="category">
          <option disabled selected value="">Select a category</option>
          <option *ngFor="let category of categories" [value]="category">{{category}}</option>
        </select>
      </div>
      <div class="form-field has-icon-right">
        <input [matDatepicker]="incidentDate" placeholder="When did this happen?" formControlName="incidentDate"/>
        <mat-datepicker-toggle [for]="incidentDate"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="true" #incidentDate></mat-datepicker>
      </div>
      
      <div [class.with-badge-list]="targets.length" class="form-field has-icon-right">
        <input #targetInput formControlName="target" placeholder="Who did this happen to?">
        <mat-icon class="accent2-color" (click)="addTarget(targetInput)">person_add</mat-icon>
      </div>
      <div *ngIf="targets.length" class="person-list-container accent2-color">
        <div class="person-list-item" *ngFor="let t of targets; let ti = index">
          <span>{{t}}</span>
          <mat-icon class="remove-item-icon" (click)="removeTarget(ti)">clear</mat-icon>
        </div>
      </div>
      
      <div [class.with-badge-list]="othersInvolved.length" class="form-field has-icon-right">
        <input #othersInput formControlName="othersInvolved" placeholder="Who was responsible/involved?">
        <mat-icon color="accent" (click)="addOthers(othersInput)">person_add</mat-icon>
      </div>
      <div *ngIf="othersInvolved.length" class="person-list-container">
        <div class="person-list-item" *ngFor="let name of othersInvolved; let oi = index">
          <span>{{name}}</span>
          <mat-icon class="remove-item-icon" (click)="removeOthersInvolved(oi)">clear</mat-icon>
        </div>
      </div>
      
      <div class="form-field">
        <textarea autofitContent placeholder="Incident Description" formControlName="description"></textarea>
      </div>
      <div class="form-field">
        <mat-list class="file-list" [class.file-list-empty]="fileCount === 0">
          <div mat-subheader class="file-list-header">
            <span>Evidence</span>
            <mat-icon [class.disabled]="maxFilesReached" class="add-files-icon" (click)="selectFiles(fileInput)">playlist_add</mat-icon>
          </div>
          <mat-list-item 
            [class.bordered]="i > 0"
            [class.invalid-file-type]="getFileTypeIcon(file.type) === 'error'"
            class="file-list-item" 
            *ngFor="let file of selectedFiles; let i = index">
            <mat-icon 
              matListIcon 
              class="file-type-icon">{{getFileTypeIcon(file.type)}}</mat-icon>
            <span class="file-name">{{file.name}}</span>
            <mat-icon class="remove-file-icon" (click)="removeFile(i)">clear</mat-icon>
          </mat-list-item>
          <mat-list-item class="file-type-error-msg" *ngIf="isFileTypeError">
            <div class="error-msg-content">
              <mat-icon class="error-msg-icon">error</mat-icon>
              <span>file must be an image, video, audio, or pdf.</span>
            </div>
          </mat-list-item>
        </mat-list>
        <input class="file-select" type="file" #fileInput (change)="addFiles($event)" multiple accept="audio/*|video/*|image/*|.pdf">
      </div>
      <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
      <button 
        mat-raised-button 
        color="primary" 
        class="button button-block button-submit" 
        [disabled]="!readyToSubmit || isSending" 
        (click)="submitForm()">{{buttonText}}</button>
    </form>
  