
    <ezs-continue-registration
      [user]="user$ | async"
      [requestStatus]="requestStatus"
      [requestError]="requestError"
      [loading]="reAuthenticating"
      [reAuthRequestStatus]=""
      [reAuthRequired]="reAuthRequired"
      (submit)="completeRegistration($event)" 
      (reAuthenticate)="sendReAuthEmail($event)">
    </ezs-continue-registration>
  