import { AuthState } from '@ezspeek/store/state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { Component, OnInit } from '@angular/core';
import { SignIn } from '@ezspeek/store/actions/auth.actions';
import { ShowBodyBackground } from '@ezspeek/store/actions/app.actions';

@Component({
  template: `
    <ezs-admin-sign-in
      *ngIf="!(isAuthenticated$ | async)"
      [errorMsg]="authError$ | async" 
      (signIn)="signIn($event)">
    </ezs-admin-sign-in>
  `
})
export class AdminSignInContainer implements OnInit {

  @Select(AuthState.error) authError$: Observable<any>;
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new ShowBodyBackground());
  }

  signIn({ email, password }) {
    this.store.dispatch(new SignIn(email, password));
  }
}
