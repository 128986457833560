import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AUTH_ROLE, REPORT_STATUS, ReportModel, UserModel } from '@ezspeek/models';
import { getReportStatusText } from '@ezspeek/common/helpers';

@Component({
  selector: 'ezs-reports-list-item',
  template: `
    <div
      class="reports-list-item reports-list-item-{{statusClass}}"
      [routerLink]="[routeRoot, 'reports', report.id]">
      <span class="report-category">{{report.category}}</span>
      <span class="report-submit-date">{{report.createdAt | moment:'MMM D, YYYY'}}</span>
      <span class="report-description">{{report.description}}</span>
      <mat-icon class="new-message-icon" *ngIf="hasNewMessage">question_answer</mat-icon>
      <mat-icon class="archived-icon" *ngIf="report.archived">archive</mat-icon>
      <mat-icon class="attachment-icon" *ngIf="report.evidence">attach_file</mat-icon>
      <report-status-badge [archived]="report.archived" [status]="report.status" size="sm"></report-status-badge>
    </div>
  `,
  styleUrls: ['./reports-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsListItemComponent {
  @Input() report: ReportModel;
  @Input() authRole: AUTH_ROLE;

  constructor() {}

  get routeRoot(): string { return this.isClient ? '/client' : '/admin'; }

  get hasNewMessage(): boolean {
    if (this.isClient)
      return this.report.newMessageFromAdmin;
    else
      return this.report.newMessageFromClient;
  }

  get statusClass(): string {
    if (this.report.archived)
      return 'archived';

    return getReportStatusText(this.report.status).toLowerCase().replace(' ', '-');
  }

  get isClient(): boolean { return this.authRole === AUTH_ROLE.CLIENT; }
}
