import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Credentials { email: string; password: string; }

@Component({
  selector: 'ezs-admin-sign-in',
  template: `
    <div *ngIf="!isAuthenticated">
      <h1 class="headline">Reporting made simple</h1>
      <form [formGroup]="form" *ngIf="form" class="login-form">
        <div class="form-field">
          <input placeholder="Email" type="email" formControlName="email">
        </div>
        <div class="form-field">
          <input placeholder="Password" type="password" formControlName="password">
        </div>
        <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
        <button class="button button-block button-lg" [disabled]="!form.valid" (click)="submitForm()">Sign In</button>
        <p class="question">
          Don't have an account?
          <a [routerLink]="['/admin/sign-up']">Sign up now</a>
        </p>
        <p class="question">
          Need to submit a report?
          <a [routerLink]="['/client/sign-in']">Sign in here</a>
        </p>
      </form>
    </div>
    <div *ngIf="isAuthenticated" style="width: 100%">
      <img class="loading-spinner" [src]="loadingSrc" alt="loading">
    </div>
  `,
  styles: [`
    .headline {
      margin-top: 2em;
    }

    .login-form {
      max-width: 400px;
      margin: 0 auto;
      padding: 1em;
    }

    button {
      margin-top: 1.5em;
      margin-bottom: 1em;
    }

    .question {
      text-align: center;
      color: #fff;
    }

    .question a {
      color: #FFC199;
      text-decoration: none;
    }
    
    img.loading-spinner {
      margin: 2em auto;
      display: block;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSignInComponent implements OnInit {

  @Input() errorMsg: string;
  @Input() isAuthenticated: boolean;

  @Output() signIn: EventEmitter<Credentials> = new EventEmitter<Credentials>();

  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [ Validators.required, Validators.email]],
      password: ['', [ Validators.required ]]
    });
  }

  submitForm() {
    if (this.form.valid)
      this.signIn.emit(this.form.value);
  }

  get loadingSrc(): string {
    const fileName: string = this.isAuthenticated ? 'bars_primary-dark.svg' : 'bars.svg';
    return `assets/images/${fileName}`;
  }
}
