import { FirebaseTimestamp } from './firebase.models';
import { AUTH_ROLE } from '@ezspeek/models/auth.models';

export enum REPORT_STATUS {
  NEW,
  VIEWED,
  ACTIVE,
  PENDING,
  RESOLVED,
  FALSE_REPORT
}

export type ReportStatus = 'NEW' | 'VIEWED' | 'ACTIVE' | 'PENDING' | 'RESOLVED' | 'FALSE_REPORT';

export interface AttachmentModel {
  url: string;
  name?: string;
  size?: number;
  type?: string;
}

export interface MessageModel {
  text: string;
  aid: string;
  uid: string;
  authRole: AUTH_ROLE;
  reportId: string;
  timestamp?: FirebaseTimestamp;
  createdAt?: number;
  id?: string;
  isActivity?: boolean;
}

export interface ReportModel {
  id?: string;
  description: string;
  evidence?: { [key: string]: AttachmentModel[] };
  resolution?: string;
  notes?: string[];
  status: REPORT_STATUS;
  activeUsers?: string[];
  aid: string;
  category: string;
  reportedBy: string;
  incidentDate?: Date | string | FirebaseTimestamp | number;
  archived?: boolean;
  toDate?: Date | string;
  victims?: string[];
  otherPeopleInvolved?: string[];
  createdAt?: number;
  viewedBy?: { [key: string]: boolean };
  lastViewedBy?: string;
  lastViewedOn?: Date | string | number;
  newMessageFromAdmin?: boolean;
  newMessageFromClient?: boolean;
  resolvedOn?: Date | string | number;
  resolvedBy?: string;
  flaggedOn?: Date | string | number;
  flaggedBy?: string;
  archivedOn?: Date | string | number;
  archivedBy?: string;
  submittedForReviewOn?: Date | string | number;
  submittedForReviewBy?: string;
  workStartedOn?: Date | string | number;
  workStartedBy?: string;
}
