import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { REQUEST_STATUS, UserModel } from '../models/index';
import { AppState, AuthState, FirestoreState } from '../store/state/index';
import { Observable } from 'rxjs/Observable';
import { ShowBodyBackground } from '../store/actions/app.actions';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { RecaptchaDirective } from '@ezspeek/common/directives/recaptcha.directive';
import { PlanModel } from '@ezspeek/models/stripe.models';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { StripeService } from '@stripe/services/stripe.service';
import {Coupon, StripeElement} from '@stripe/models';
import { LoadUser } from '@ezspeek/store/actions/firestore.actions';
import {ToasterService} from 'angular2-toaster';

@Component({
  template: `
    <ezs-header [user]="user$ | async"></ezs-header>
    <ezs-sign-up
      [errorMsg]="authError$ | async"
      [requestStatus]="signUpRequestStatus"
      [requestError]="signUpRequestError"
      [stripeCardError]="stripeCardError"
      [stripeCardComplete]="stripeCardComplete"
      [plans]="plans$ | async"
      [selectedPlanId]="selectedPlanId$ | async"
      [appliedCoupon]="appliedCoupon"
      (validateCoupon)="validateCoupon($event)"
      (removeCoupon)="removeCoupon()"
      (signUp)="signUp($event)"
      (complete)="complete()">
      <div #cardForm></div>
    </ezs-sign-up>    
    <div
      ezsRecaptcha
      [style.display]="'none'"
      size="invisible"
      (rendered)="recaptchaRendered($event)">
    </div>
  `
})
export class SignUpContainer implements OnInit, AfterViewInit {

  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;
  @Select(AuthState.error) authError$: Observable<any>;

  plans$: Observable<PlanModel[]>;
  selectedPlanId$: Observable<string>;

  @ViewChild(RecaptchaDirective) recaptcha;

  @ViewChild('cardForm') cardForm: ElementRef;

  stripeCard: StripeElement;

  private _signUpRequestStatus: REQUEST_STATUS;
  private _signUpRequestError: string;

  private _recaptchaRendered = false;
  private _stripeCardError = '';
  private _stripeCardComplete = false;

  private _validatedCoupons: Array<Coupon> = [];
  private _appliedCoupon: Coupon;

  constructor(
    private store: Store,
    private af: AngularFireService,
    private route: ActivatedRoute,
    private stripe: StripeService,
    private toaster: ToasterService
  ) {
    this.selectedPlanId$ = this.route.params.pipe(map(qp => qp.plan));
  }

  ngOnInit() {
    this.plans$ = this.af.getPlansCollectionRef().valueChanges();
    this.store.dispatch(new ShowBodyBackground());
  }

  async ngAfterViewInit() {
    if (!!this.cardForm) {
      this.stripeCard = await this.stripe.createCard();

      this.stripeCard.mount(this.cardForm.nativeElement);

      this.stripeCard.on('change', ({ error, complete }) => {
        this._stripeCardComplete = complete;

        if (error) {
          this._stripeCardError = error.message;
        } else {
          this._stripeCardError = '';
        }
      });
    }
  }

  async signUp({ email, password, displayName, title, plan, coupon }) {
    this._signUpRequestError = '';
    this._signUpRequestStatus = REQUEST_STATUS.LOADING;
    this.stripeCard.update({ disabled: true });

    try {
      await this.recaptcha.verify();

      const { token, error } = await this.stripe.createToken(this.stripeCard);

      if (error) {
        throw error;
      }

      const uid = await this.af.createUser(email, password, displayName, title);
      await this.af.createAccount({ source: token.id, plan, coupon });
      await this.store.dispatch(new LoadUser(uid, true)).toPromise();

      this._signUpRequestStatus = REQUEST_STATUS.SUCCESS;
      this.stripeCard.update({ disabled: false });
    } catch (err) {
      console.warn('ERROR => ', err);
      this.stripeCard.update({ disabled: false });
      this._signUpRequestStatus = REQUEST_STATUS.ERROR;
      this._signUpRequestError = err.message || err;
    }
  }

  complete() {
    this.store.dispatch(new Navigate(['/admin']));
  }

  recaptchaRendered(result: any) {
    this._recaptchaRendered = true;
  }

  async validateCoupon(id: string) {
    if (!!this.appliedCoupon)
      return;

    let isValid = false;
    let body = 'invalid coupon';
    let coupon: Coupon = this._validatedCoupons.find(c => c.id === id);

    if (!!coupon) {
      isValid = true;
    } else {
      try {
        coupon = await this.af.getCoupon(id);
        if (coupon.valid) {
          this._validatedCoupons.push(coupon);
          isValid = true;
        } else {
          body = 'coupon expired';
        }
      } catch (err) {
        console.warn('COUPON ERROR => ', err);
      }
    }

    if (isValid)
      this._appliedCoupon = coupon;

    this.toaster.pop({
      type: isValid ? 'success' : 'error',
      body: isValid ? 'coupon applied!' : body,
      timeout: 3000
    });
  }

  removeCoupon() {
    this._appliedCoupon = null;
  }

  get signUpRequestStatus(): REQUEST_STATUS { return this._signUpRequestStatus; }
  get signUpRequestError(): string { return this._signUpRequestError; }
  get stripeCardError(): string { return this._stripeCardError; }
  get stripeCardComplete(): boolean { return this._stripeCardComplete; }
  get appliedCoupon(): Coupon { return this._appliedCoupon; }
}
