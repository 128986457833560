import { REPORT_STATUS, ReportModel } from '@ezspeek/models';

type ReportFilter = (report: ReportModel) => boolean;

export class ReportsFilters {
  static status(status: REPORT_STATUS): ReportFilter {
    return (report: ReportModel) => {
      return report.status === status;
    };
  }

  static newMessageFromClient(report: ReportModel): boolean {
    return report.newMessageFromClient;
  }

  static afterDate(date): ReportFilter {
    return (report: ReportModel) => {
      return report.createdAt > date;
    };
  }

  static beforeDate(date): ReportFilter {
    return (report: ReportModel) => {
      return report.createdAt < date;
    };
  }

  static dateRange(startDate, endDate): ReportFilter {
    return (report: ReportModel) => {
      return report.createdAt >= startDate && report.createdAt <= endDate;
    };
  }
}
