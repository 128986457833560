import {
  BankAccount,
  BankAccountData,
  Pii,
  PiiData,
  StripeSourceData
} from './models';

export class StripeUtils {
  static defaultCardStyle = {
    style: {
      base: {
        color: '#666',
        fontFamily: 'Montserrat',
        fontWeight: '300',
        '::placeholder': {
          color: '#999'
        },
        ':disabled': {
          color: '#bbb'
        }
      }
    }
  };

  static isBankAccount(account: any): account is BankAccount {
    return account === 'bank_account';
  }

  static isBankAccountData(bankAccountData: any): bankAccountData is BankAccountData {
    return (
      'country' in bankAccountData &&
      'currency' in bankAccountData &&
      'routing_number' in bankAccountData &&
      'account_number' in bankAccountData &&
      'account_holder_name' in bankAccountData &&
      'account_holder_type' in bankAccountData &&
      (bankAccountData.account_holder_type === 'individual' ||
        bankAccountData.account_holder_type === 'company')
    );
  }

  static isPii(pii: any): pii is Pii {
    return pii === 'pii';
  }

  static isPiiData(piiData: any): piiData is PiiData {
    return 'personal_id_number' in piiData;
  }

  static isSourceData(sourceData: any): sourceData is StripeSourceData {
    return 'type' in sourceData;
  }
}
