<div id="intro">
  <div class="container">
    <h1 class="intro-headline">
      Empower
      <span class="headline-phrase-wrapper">
        <span class="headline-phrase">{{displayedPhrase}}</span>
      </span>
    </h1>
    <p class="intro-text">
      Combining the effectiveness of technology and social awareness, ezSpeek incorporates powerful solutions to help
      neutralize the increasing threats of violence and negative behavior in our schools and communities.
    </p>
    <div class="cta-button-container">
      <button mat-raised-button color="accent" class="ezs-button button-accent2-light" (click)="scrollTo(plansHeadline)">Get Started</button>
      <button mat-button class="ezs-button" (click)="scrollTo(featureHeadline)">Learn More <mat-icon>expand_more</mat-icon></button>
    </div>
    <img class="laptop" src="assets/images/dashboard_both_screenshot.png">
  </div>
</div>

<div id="features">
  <div class="container">
    <div class="feature-more-button">
      <mat-icon (click)="scrollTo(featureHeadline)">expand_more</mat-icon>
    </div>
    <h1 #featureHeadline class="headline" animateInView [offset]=".4" [checkAnimateOnInit]="false">Impact Features</h1>
    <div class="feature-list">
      <div class="feature-item" animateInView [offset]=".4">
        <div class="feature-icon">
          <div class="feature-icon-bg-circle"></div>
          <div class="feature-icon-bg-half-circle"></div>
          <mat-icon>verified_user</mat-icon>
        </div>
        <span class="feature-heading">100% Anonymous</span>
        <span class="feature-text">
          Student and Administrator identities are protected to promote a safe reporting environment and confidential
          communication line.
        </span>
      </div>

      <div class="feature-item" animateInView [offset]=".4">
        <div class="feature-icon ezs-alert">
          <div class="feature-icon-bg-circle"></div>
          <div class="feature-icon-bg-half-circle"></div>
          <mat-icon>question_answer</mat-icon>
        </div>
        <span class="feature-heading">Live Chat</span>
        <span class="feature-text">
          The chat messaging system allows instant feedback which can assist in the investigation and help expedite
          the incident resolution process.
        </span>
      </div>

      <div class="feature-item" animateInView [offset]=".4">
        <div class="feature-icon ezs-warn">
          <div class="feature-icon-bg-circle"></div>
          <div class="feature-icon-bg-half-circle"></div>
          <mat-icon>notification_important</mat-icon>
        </div>
        <span class="feature-heading">Emergency Alerts</span>
        <span class="feature-text">
          Activate a high priority mass notification  to alert all users of an emergency, so safety procedures and
          immediate action can be taken.
        </span>
      </div>

      <div class="feature-item" animateInView [offset]=".4">
        <div class="feature-icon ezs-accent2">
          <div class="feature-icon-bg-circle"></div>
          <div class="feature-icon-bg-half-circle"></div>
          <mat-icon>bar_chart</mat-icon>
        </div>
        <span class="feature-heading">Incident Statistics</span>
        <span class="feature-text">
          Access extensive statistics which can highlight key data factors, such as target problem areas, incident
          resolution rate, and more...
        </span>
      </div>
    </div>
  </div>
</div>

<div class="cta-section">
  <div class="cta-section-wrapper elevation-5" animateInView [offset]=".5">
    <div class="container">
      <h1>In the fight against school violence and negative student behavior, we must take initiatives to restore control in our schools and develop safer <span>learning environments</span></h1>
      <!--<button (click)="scrollTo(paymentToggle)" mat-raised-button color="accent" class="ezs-button">Get Started Today</button>-->
    </div>
  </div>
</div>

<div id="plans">
  <h1 #plansHeadline class="headline" animateInView [offset]=".4" [checkAnimateOnInit]="false">{{plansSectionHeading}}</h1>
  <span class="sub-headline">We offer a variety of plan options to give you the flexibility to choose the right fit for your needs</span>

  <h3 *ngIf="!isAuthenticated" [class.yearly]="!isPlanPaymentMonthly" class="trial">{{planInterval}} plans include a {{trialPeriod}}-day risk free trial</h3>
  <div #paymentToggle class="payment-type-toggle">
    <div (click)="togglePlanPaymentType()" [class.selected]="isPlanPaymentMonthly" class="toggle-section">Monthly</div>
    <div (click)="togglePlanPaymentType()" [class.selected]="!isPlanPaymentMonthly" class="toggle-section">Yearly</div>
    <div [class.yearly]="!isPlanPaymentMonthly" class="toggle-slider">{{paymentTypeSliderText}}</div>
  </div>
  <span *ngIf="!isAuthenticated && isPlanPaymentMonthly" class="sub-headline savings">Save up to 18% and get a 30-day trial with a yearly subscription</span>
  <div class="plans-wrapper" *ngIf="!!displayedPlans">
    <mat-card class="plan essential-plan" animateInView [offset]=".5">
      <mat-card-content>
        <div class="plan-header">
          <span class="plan-title">{{essentialPlan.productName}}</span>
          <span class="plan-price">{{essentialPlan.price}}<span>/{{paymentType}}</span> </span>
        </div>
        <div class="plan-checklist">
          <ul>
            <li>3 admin accounts</li>
            <li>100% Anonymous</li>
            <li>live chat</li>
            <li>upload photo evidence</li>
          </ul>
        </div>
      </mat-card-content>
      <button *ngIf="!isAuthenticated" mat-raised-button class="ezs-button button-primary-dark" (click)="selectPlan('Essential')">Select Plan</button>
    </mat-card>
    <mat-card class="plan premium-plan most-popular" animateInView [offset]=".5">
      <mat-card-content>
        <div class="plan-header">
          <span class="plan-title">{{premiumPlan.productName}}</span>
          <span class="plan-price">{{premiumPlan.price}}<span>/{{paymentType}}</span> </span>
        </div>
        <div class="plan-checklist">
          <ul>
            <li class="included">*includes Essential features</li>
            <li>6 admin accounts</li>
            <li>upload video evidence</li>
            <li>custom notifications</li>
            <li>statistic reports</li>
          </ul>
        </div>
      </mat-card-content>
      <button *ngIf="!isAuthenticated" mat-raised-button class="ezs-button button-accent2" (click)="selectPlan('Premium')">Select Plan</button>
    </mat-card>
    <mat-card class="plan platinum-plan" animateInView [offset]=".5">
      <mat-card-content>
        <div class="plan-header">
          <span class="plan-title">{{ultimatePlan.productName}}</span>
          <span class="plan-price">{{ultimatePlan.price}}<span>/{{paymentType}}</span> </span>
        </div>
        <div class="plan-checklist">
          <ul>
            <li class="included">*includes Premium features</li>
            <li>20 admin accounts</li>
            <li>emergency alert system</li>
            <li>live streaming <span>(coming soon)</span></li>
          </ul>
        </div>
      </mat-card-content>
      <button *ngIf="!isAuthenticated" mat-raised-button class="ezs-button button-alert" (click)="selectPlan('Ultimate')">Select Plan</button>
    </mat-card>
  </div>
  <span class="sub-headline more-features">*More features are under development and will be added to continue to improve functionality and user experience.</span>
</div>

<div id="contact" [class.extra-padding]="contactUsMessageSuccess">
  <div class="contact-form" *ngIf="!contactUsMessageSuccess">
    <h1 class="headline title" animateInView [checkAnimateOnInit]="false">Contact Us</h1>
    <span class="sub-headline">Got a question or comment? <span>We'd love to hear from you!</span></span>
    <form [formGroup]="contactForm" *ngIf="!!contactForm">
      <div class="form-field">
        <input formControlName="name" placeholder="Name (optional)" [readOnly]="isAuthenticated">
      </div>
      <div class="form-field">
        <input formControlName="email" placeholder="Email" [readOnly]="isAuthenticated">
      </div>
      <div class="form-field">
        <textarea autofitContent formControlName="message" placeholder="Message"></textarea>
      </div>
      <button
        mat-raised-button
        class="button button-block button-success button-submit"
        [disabled]="!contactForm.valid"
        (click)="sendMessage()">{{contactUsButtonText}}</button>
      <p class="error-msg" *ngIf="contactUsErrorMsg">{{contactUsErrorMsg}}</p>
    </form>
  </div>
  <div *ngIf="contactUsMessageSuccess">
    <mat-card class="success-message fadeIn animated">
      <animated-check [thin]="true" width="150px"></animated-check>
      <h1>Thank You!</h1>
      <p>your message has been sent</p>
    </mat-card>
  </div>
</div>

<div id="footer">
  <div class="social-links">
    <a href="https://www.facebook.com/ezspeek" target="_blank">
      <span class="fab fa-facebook-square fa-3x"></span>
    </a>
    <a href="https://www.instagram.com/ezspeeking" target="_blank">
      <span class="fab fa-instagram fa-3x"></span>
    </a>
  </div>
  <span class="copyright">© {{year}} <span>ezSpeek</span> all rights reserved</span>
</div>
