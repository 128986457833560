
    <div class="emergency-alert-backdrop">
      <mat-card class="scaleUp animated" *ngIf="alert">
        <mat-card-title>
          <mat-icon>priority_high</mat-icon> EMERGENCY ALERT <mat-icon>priority_high</mat-icon>
        </mat-card-title>
        <mat-card-content>
          {{message}}
        </mat-card-content>
        <mat-card-actions *ngIf="isAcctMgr">
          <button mat-button (click)="dismissAlert()">
            <mat-icon>notifications_off</mat-icon> Dismiss Alert
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  