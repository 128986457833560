<div *ngIf="isAuthorized" id="sidebar" [class.sidebar-collapsed]="isCollapsed">
  <div class="sidebar-container">
    <div>
      <div>
        <div class="nav-user-wrapper">
          <div class="media" *ngIf="!!user">
            <div class="media-left">
              <a [matMenuTriggerFor]="userMenu" class="profile-card-photo elevation-2">
                <img alt="" [src]="profilePictureUrl">
              </a>
            </div>
            <div class="media-body">
              <span class="media-heading">{{user.displayName}}</span>
              <div class="media-subheading">{{user.title}}</div>
            </div>


            <mat-icon [matMenuTriggerFor]="userMenu" class="user-menu-icon">more_vert</mat-icon>
            <mat-menu #userMenu="matMenu">
              <button (click)="toggleProfileModal.emit()" mat-menu-item><mat-icon>person</mat-icon>My Profile</button>
              <button (click)="signOut.emit(isMenuOpen)" mat-menu-item><mat-icon>exit_to_app</mat-icon>Sign Out</button>
            </mat-menu>

          </div>
        </div>

        <nav>
          <ul>
            <li *ngFor="let link of links">
              <a [class.disabled]="link.disabled" (click)="closeMenu()" routerLink="/admin/{{link.route}}" routerLinkActive="active">
                <mat-icon>{{link.icon}}</mat-icon>
                <span>{{link.text}}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
