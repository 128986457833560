import { Directive, AfterViewInit, OnDestroy, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import * as Croppie from 'croppie';

@Directive({
  selector: 'img[croppieOptions]'
})
export class CroppieDirective implements AfterViewInit, OnDestroy {

  public croppie: Croppie;

  @Input()
  public croppieOptions: Croppie.CroppieOptions;

  @Output()
  public update: EventEmitter<Croppie> = new EventEmitter<Croppie>();

  constructor(private elRef: ElementRef) {}

  public ngAfterViewInit() {
    (this.croppieOptions as any).update = (data) => this.update.emit(data);
    this.croppie = new Croppie(
      this.element,
      this.croppieOptions
    );
  }

  public ngOnDestroy() {
    if (!!this.croppie) {
      this.croppie.destroy();
    }
  }

  get element(): HTMLImageElement {
    return this.elRef.nativeElement;
  }
}
