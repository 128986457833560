import { Component } from '@angular/core';

@Component({
  template: `
    <ezs-account-registration 
      (registerAccount)="registerAccount($event)">
    </ezs-account-registration>
  `
})
export class AccountRegistrationContainer {
  constructor() {}

  registerAccount(credentials) {

  }
}
