import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ReportModel, UserModel } from '@ezspeek/models';
import { AppState, FirestoreState } from '@ezspeek/store/state';
import { Observable } from 'rxjs/observable';
import { CreateReport } from '@ezspeek/store/actions/firestore.actions';
import { ReportFormModel } from './report-form.component';

@Component({
  template: `
    <div class="report-form-wrapper">
      <ezs-report-form
        *ngIf="!showComplete"
        [client]="client$ | async"
        [errorMsg]="sendReportError$ | async"
        (sendReport)="sendReport($event)">
      </ezs-report-form>
      <ezs-report-complete *ngIf="showComplete" (newReport)="showForm()"></ezs-report-complete>
      <mat-icon *ngIf="!showComplete" [routerLink]="['/client', 'dashboard']" class="back-arrow">arrow_back</mat-icon>
    </div>
  `,
  styles: [`
    .report-form-wrapper {
      padding: 0 1em;
    }
  `]
})
export class ReportFormContainer {

  showComplete = false;

  @Select(AppState.authenticatedUser) client$: Observable<UserModel>;
  @Select(FirestoreState.isSendingReport) isReportSending$: Observable<boolean>;
  @Select(FirestoreState.sendReportError) sendReportError$: Observable<string>;

  constructor(private store: Store) {
  }

  sendReport(report: ReportFormModel) {
    this.store.dispatch(new CreateReport(report.data, report.files))
      .toPromise().then(_ => {
          this.showComplete = true;
      });
  }

  showForm() {
    this.showComplete = false;
  }
}
