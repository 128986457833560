import { State, Selector, Action, StateContext } from '@ngxs/store';
import { AuthState } from './auth.state';
import { FirestoreState } from './firestore.state';
import {
  DismissEmergencyAlert,
  DisplayEmergencyAlert,
  HideBodyBackground,
  SetAdminPageTitle,
  ShowBodyBackground,
  ToggleMenu,
  ToggleProfileModal,
  ToggleSidebar
} from '@ezspeek/store/actions/app.actions';
import { AUTH_ROLE, UserModel } from '@ezspeek/models';
import { ToasterService } from 'angular2-toaster';
import { EmergencyAlertModel } from '@ezspeek/models/common.models';
import { ProductFeatures } from '@ezspeek/models/stripe.models';

export interface AppStateModel {
  initialized?: false;
  hideBodyBackground: boolean;
  sidebarCollapsed: boolean;
  adminPageTitle?: { main: string, sub: string };
  menuOpen: boolean;
  profileModalOpen: boolean;
  emergencyAlert?: EmergencyAlertModel;
  lightboxOpen: boolean;
}

type AppContext = StateContext<AppStateModel>;

const initialState: AppStateModel = {
  hideBodyBackground: false,
  sidebarCollapsed: false,
  menuOpen: false,
  profileModalOpen: false,
  lightboxOpen: false
};

@State<AppStateModel>({
  name: 'root',
  defaults: { ...initialState },
  children: [
    AuthState,
    FirestoreState
  ]
})
export class AppState {
  constructor(private toaster: ToasterService) {}

  @Selector()
  static authenticatedUser({ firestore, auth }) {
    const users = firestore.users || {};
    const uid = auth.state ? auth.state.uid : null;
    return uid ? users[uid] : auth.state;
  }

  @Selector()
  static accountUsers({ firestore, auth }): UserModel[] {
    const users = firestore.users || {};
    const uid = auth.state ? auth.state.uid : null;
    const { role } = users[uid];
    const userCollection: UserModel[] = [];

    Object.keys(users).forEach(uidKey => {
      const user = users[uidKey];

      if (uidKey !== uid && (role === AUTH_ROLE.ACCOUNT_MGR || (user.role !== null && user.role !== undefined))) {
        userCollection.push(user);
      }
    });

    return userCollection;
  }

  @Selector()
  static loadingAuthenticatedUser({ firestore, auth }): boolean {
    if (auth.state === undefined) {
      return true;
    } else {
      const users = firestore.users || {};
      return auth.state !== null && !users[auth.state.uid];
    }
  }

  @Selector()
  static accountFeatures({ firestore, auth }): ProductFeatures {
    if (auth.state === undefined || auth.state === null)
      return null;

    const { planId } = firestore.account;
    const plans = firestore.plans;
    const products = firestore.products;

    if (!planId)
      return null;

    if (!!plans && !!products) {
      const { productId } = plans.find(plan => plan.id === planId);
      const { features } = products.find(product => product.id === productId);
      return features;
    }

    return null;
  }

  @Selector()
  static accountStatus({ firestore, auth }): string {
    if (auth.state === undefined || firestore.account === undefined)
      return undefined;

    if (auth.state === null)
      return 'n/a';

    console.warn('account => ', firestore.account);
    return firestore.account.status;
  }

  @Selector()
  static isBgHidden({ hideBodyBackground }) {
    return hideBodyBackground;
  }

  @Selector()
  static isSidebarCollapsed({ sidebarCollapsed }) {
    return sidebarCollapsed;
  }

  @Selector()
  static isMenuOpen({ menuOpen }) {
    return menuOpen;
  }

  @Selector()
  static isProfileModalOpen({ profileModalOpen }) {
    return profileModalOpen;
  }

  @Selector()
  static adminPageTitle({ adminPageTitle }) {
    return adminPageTitle;
  }

  @Selector()
  static emergencyAlert({ emergencyAlert }) {
    return emergencyAlert;
  }

  @Selector()
  static lightboxOpen({ lightboxOpen }) {
    return lightboxOpen;
  }

  @Action([HideBodyBackground, ShowBodyBackground])
  toggleBodyBackground({ patchState }: AppContext, { hide }: HideBodyBackground | ShowBodyBackground) {
    patchState({ hideBodyBackground: hide });
  }

  @Action(ToggleSidebar)
  toggleSideBar({ patchState, getState }: AppContext) {
    const { sidebarCollapsed } = getState();
    patchState({ sidebarCollapsed: !sidebarCollapsed });
  }

  @Action(ToggleMenu)
  toggleMenu({ patchState, getState }: AppContext) {
    const { menuOpen } = getState();
    patchState({ menuOpen: !menuOpen });
  }

  @Action(SetAdminPageTitle)
  setAdminPageTitle({ patchState }: AppContext, { main, sub }: SetAdminPageTitle) {
    patchState({ adminPageTitle: { main, sub }});
  }

  @Action(ToggleProfileModal)
  toggleProfileModal({ patchState, getState }: AppContext) {
    const { profileModalOpen } = getState();
    patchState({ profileModalOpen: !profileModalOpen });
  }

  @Action(DisplayEmergencyAlert)
  displayEmergencyAlert({ patchState }: AppContext, { alert }: DisplayEmergencyAlert) {
    patchState({ emergencyAlert: alert });
  }

  @Action(DismissEmergencyAlert)
  dismissEmergencyAlert({ patchState }: AppContext) {
    patchState({ emergencyAlert: undefined });
  }
}

export const ROOT_STATE = [
  AppState,
  AuthState,
  FirestoreState
];
