import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ezs-loading',
  template: `
    <div [class.absolute-center]="absoluteCenter" class="loading-container">
      <ng-content></ng-content>
      <img [src]="src" alt="loading" [style.opacity]="opacity">
    </div>
  `,
  styles: [
    `
      .absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {

  @Input() absoluteCenter: boolean;
  @Input() dark: boolean;
  @Input() opacity = '1';

  constructor() {}

  get src(): string {
    const fileName: string = this.dark ? 'bars_primary-dark.svg' : 'bars.svg';
    return `assets/images/${fileName}`;
  }
}
