import { Inject, Injectable } from '@angular/core';
import { STRIPE_OPTIONS, STRIPE_PUBLISHABLE_KEY, StripeOptions } from '../models';
import { PlatformService } from '@ezspeek/services/platform.service';
import { StripeJSLoader } from './stripe-js-loader';
import { StripeInstance } from '../stripe';

@Injectable()
export class StripeFactoryService {
  constructor(
    @Inject(STRIPE_PUBLISHABLE_KEY) private baseKey: string,
    @Inject(STRIPE_OPTIONS) private baseOptions: string,
    private loader: StripeJSLoader,
    private platform: PlatformService
  ) {}

  public create(key: string, options?: StripeOptions): StripeInstance {
    return new StripeInstance(this.loader, this.platform.window, key, options);
  }
}
