import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MessageModel } from '@ezspeek/models';

@Component({
  selector: 'ezs-report-note',
  template: `
    <div class="ezs-message ezs-report-note">
      <span class="user-profile-picture">
        <img [src]="userPhotoURL"/>
      </span>
      <div *ngIf="!note.isActivity; else activity" class="ezs-report-note-content">
        <div class="ezs-report-note-header">
          <span class="user-display-name">{{userDisplayName}}</span>
          <span class="ezs-message-timestamp">{{note.createdAt | moment:'ddd MMM D, YYYY h:mm a'}}</span>
        </div>
        <span class="ezs-message-wrapper elevation-2">{{note.text}}</span>
      </div>
      <ng-template #activity>
        <div class="ezs-report-note-content activity-note">
          <div class="ezs-report-note-header">
            <span class="user-display-name">{{userDisplayName}}</span>
            <span class="activity-note-text">{{note.text}}</span>
          </div>
          <span class="ezs-message-timestamp">{{note.createdAt | moment:'ddd MMM D, YYYY h:mm a'}}</span>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./ezs-message.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportNoteComponent {
  @Input() note: MessageModel;
  @Input() userPhotoURL: string;
  @Input() userDisplayName: string;

  constructor() {}

  get isActivity(): boolean { return this.note.isActivity; }
}
