import {Component, OnInit} from '@angular/core';
import {AppState, FirestoreState} from '@ezspeek/store/state';
import {Select, Store} from '@ngxs/store';
import {AccountModel, AUTH_ROLE, REPORT_STATUS, ReportModel, REQUEST_STATUS, UserModel} from '@ezspeek/models';
import {Observable} from 'rxjs/Observable';
import {SignOut} from '@ezspeek/store/actions/auth.actions';
import {ToggleMenu, ToggleProfileModal, ToggleSidebar} from '@ezspeek/store/actions/app.actions';
import {AngularFireService} from '@ezspeek/services/angular-fire.service';
import {first, map, shareReplay, tap} from 'rxjs/operators';
import {LoadAdmin} from '@ezspeek/store/actions/firestore.actions';
import {ToasterService} from 'angular2-toaster';
import {ReportsFilters} from '@ezspeek/admin/reports/reports-filters';
import {of, Subscription} from 'rxjs';

@Component({
  template: `
    <admin-sidebar
      [user]="user$ | async"
      [account]="account$ | async"
      [isCollapsed]="isSidebarCollapsed$ | async"
      [isMenuOpen]="isMenuOpen$ | async"
      (toggleMenu)="toggleMenu()"
      (toggleProfileModal)="toggleProfileModal()"
      (signOut)="signOut($event)">
    </admin-sidebar>
    <div [class.authenticated]="authorized$ | async" class="admin-container">
      <admin-header
        [user]="user$ | async"
        [newReportCount]="newReportsCount$ | async"
        [newMessageCount]="newMessageCount$ | async"
        [pendingReportCount]="pendingReviewCount$ | async"
        [isSidebarCollapsed]="isSidebarCollapsed$ | async"
        [isMenuOpen]="isMenuOpen$ | async"
        (toggleSidebar)="toggleSidebar()"
        (toggleMenu)="toggleMenu()"
        (signOut)="signOut()">
      </admin-header>
      <div
        [class.sidebar-collapsed]="isSidebarCollapsed$ | async"
        [class.menu-open]="isMenuOpen$ | async"
        class="main-content">
        <div class="page-title-wrapper">
          <div *ngIf="pageTitle$ | async as pageTitle">
            <span class="page-title">{{pageTitle.main}}</span>
            <span class="page-subtitle">{{pageTitle.sub}}</span>
          </div>
        </div>
        
        <div class="content-wrapper">
          <!--<div [@routerAnimation]="getRouteAnimation(outlet)">-->
          <router-outlet #outlet="outlet"></router-outlet>
          <!--</div>-->
        </div>
      </div>

    </div>
    <ezs-profile-modal 
      [isOpen]="isProfileModalOpen$ | async"
      [user]="user$ | async"
      [uploadImageRequestStatus]="uploadProfilePhotoRequestStatus"
      [updateNameRequestStatus]="updateNameRequestStatus"
      [updateTitleRequestStatus]="updateTitleRequestStatus"
      (uploadProfileImage)="uploadProfileImage($event)"
      (updateName)="updateDisplayName($event)"
      (updateTitle)="updateJobTitle($event)"
      (toggleOpen)="toggleProfileModal()">
    </ezs-profile-modal>
  `,
  styleUrls: [ './admin-router.scss' ]
  // animations: ROUTING_ANIMATIONS
})
export class AdminRouter implements OnInit {

  @Select(AppState.loadingAuthenticatedUser) loading$: Observable<boolean>;
  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;
  newReportsCount$: Observable<number>;
  newMessageCount$: Observable<number>;
  pendingReviewCount$: Observable<number>;
  @Select(AppState.isSidebarCollapsed) isSidebarCollapsed$: Observable<boolean>;
  @Select(AppState.isMenuOpen) isMenuOpen$: Observable<boolean>;
  @Select(AppState.adminPageTitle) pageTitle$: Observable<{ main: string, sub: string }>;
  @Select(AppState.isProfileModalOpen) isProfileModalOpen$: Observable<boolean>;
  @Select(FirestoreState.account) account$: Observable<AccountModel>;

  private _activeAdmins: string[] = [];
  private _currentNewReportCount = 0;
  private _currentNewMessageCount = 0;
  private _currentPendingReportCount = 0;

  private _uploadProfilePhotoRequestStatus;
  private _updateNameRequestStatus;
  private _updateTitleRequestStatus;

  private _adminsListener: Subscription;

  constructor(private store: Store, private af: AngularFireService, private toaster: ToasterService) {}

  ngOnInit() {
    this.user$.subscribe(user => {
      if (!!user) {
        const reports$ = this.af.getReportsCollectionRef(user.aid).valueChanges().pipe(shareReplay(1));
        this._monitorAccountAdmins(user.aid, user.uid);
        this._setupHeaderNotificationIcons(reports$, user.role);
      } else {
        this._cancelAdminListener();
        this._resetHeaderNotificationIcons();
      }
    });
  }

  signOut(closeMenu?: boolean) {
    if (closeMenu)
      this.toggleMenu();

    this.store.dispatch(new SignOut());
  }

  toggleSidebar() {
    this.store.dispatch(new ToggleSidebar());
  }

  toggleMenu() {
    this.store.dispatch(new ToggleMenu());
  }

  toggleProfileModal() {
    if (this.store.selectSnapshot(AppState.isMenuOpen))
      this.store.dispatch(new ToggleMenu());

    this.store.dispatch(new ToggleProfileModal());
  }

  uploadProfileImage({ blob, uid }) {
    this._uploadProfilePhotoRequestStatus = REQUEST_STATUS.LOADING;

    this.af.uploadProfileImage(blob, uid)
      .then(_ => {
        this._uploadProfilePhotoRequestStatus = REQUEST_STATUS.SUCCESS;
        this.toaster.pop('success', '', 'Profile photo updated successfully.');
      })
      .catch(err => {
        this._uploadProfilePhotoRequestStatus = REQUEST_STATUS.ERROR;
        this.toaster.pop('error', 'Upload failed', err);
      });
  }

  updateDisplayName({ displayName, uid }) {
    this._updateNameRequestStatus = REQUEST_STATUS.LOADING;

    this.af.updateUserDisplayName(displayName, uid)
      .then(_ => {
        this._updateNameRequestStatus = REQUEST_STATUS.SUCCESS;
        this.toaster.pop('success', '', 'Your name has been updated');
      })
      .catch(err => {
        this._updateNameRequestStatus = REQUEST_STATUS.SUCCESS;
        this.toaster.pop('error', '', err);
      });
  }

  updateJobTitle({ title, uid}) {
    this._updateTitleRequestStatus = REQUEST_STATUS.LOADING;

    this.af.updateUserJobTitle(title, uid)
      .then(_ => {
        this._updateTitleRequestStatus = REQUEST_STATUS.SUCCESS;
        this.toaster.pop('success', '', 'Your job title has been updated');
      })
      .catch(err => {
        this._updateTitleRequestStatus = REQUEST_STATUS.SUCCESS;
        this.toaster.pop('error', '', err);
      });
  }

  private _monitorAccountAdmins(aid: string, currentUserId: string) {
    this._adminsListener = this.af.getAccountDocRef(aid).collection('admins')
      .valueChanges()
      .subscribe((admins: { uid, deleted }[]) => {
        admins.forEach(admin => {
          if (this._activeAdmins.indexOf(admin.uid) < 0) {
            this._activeAdmins.push(admin.uid);

            this.af.getUserDocRef(admin.uid)
              .valueChanges()
              .subscribe(userDoc => {
                this.store.dispatch(new LoadAdmin(userDoc));
              });
          }
          if (admin.deleted && admin.uid === currentUserId) {
            this.store.dispatch(new SignOut());
          }
        });
      });
  }

  private _cancelAdminListener() {
    if (this._adminsListener && !this._adminsListener.closed)
      this._adminsListener.unsubscribe();
  }

  private _setupHeaderNotificationIcons(reports$: Observable<ReportModel[]>, role: AUTH_ROLE) {
    this.newReportsCount$ = reports$.pipe(
      map(reports => reports.filter(report => report.status === REPORT_STATUS.NEW).length),
      tap(newReportCount => {
        if (newReportCount > this._currentNewReportCount) {
          const total = newReportCount - this._currentNewReportCount;
          const msg = total > 1 ? `${total} new incidents have been reported` : 'A new incident has been reported';
          this.toaster.pop({ type: 'info', body: msg, timeout: 8000 });
          this._currentNewReportCount = newReportCount;
        }
        return newReportCount;
      })
    );

    this.newMessageCount$ = reports$.pipe(
      map(reports => reports.filter(report => report.newMessageFromClient).length),
      tap(newMsgCount => {
        if (newMsgCount > this._currentNewMessageCount) {
          const total = newMsgCount - this._currentNewMessageCount;
          const msg = total > 1 ? `${total} reports have unread messages` : 'A report message has been received';
          this.toaster.pop({ type: 'warning', body: msg, timeout: 8000 });
          this._currentNewMessageCount = newMsgCount;
        }
        return newMsgCount;
      })
    );

    if (role === AUTH_ROLE.ACCOUNT_MGR)
      this.pendingReviewCount$ = reports$.pipe(
        map(reports => reports.filter(ReportsFilters.status(REPORT_STATUS.PENDING)).length),
        tap(pendingCount => {
          if (pendingCount > this._currentPendingReportCount) {
            const total = pendingCount - this._currentPendingReportCount;
            const msg = total > 1 ? `There are ${total} reports pending review` : 'A report has been submitted for review';
            this.toaster.pop({ type: 'wait', body: msg, timeout: 8000 });
            this._currentPendingReportCount = pendingCount;
          }
        })
    );
  }

  private _resetHeaderNotificationIcons() {
    this.newReportsCount$ = of(0);
    this.newMessageCount$ = of(0);
    this.pendingReviewCount$ = of(0);
  }

  get authorized$(): Observable<boolean> {
    return this.user$.pipe(map(user => !!user && !!user.displayName && !!user.email));
  }

  get uploadProfilePhotoRequestStatus(): REQUEST_STATUS {
    return this._uploadProfilePhotoRequestStatus;
  }

  get updateNameRequestStatus(): REQUEST_STATUS {
    return this._updateNameRequestStatus;
  }

  get updateTitleRequestStatus(): REQUEST_STATUS {
    return this._updateTitleRequestStatus;
  }
}
