import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetAdminPageTitle } from '@ezspeek/store/actions/app.actions';

@Component({
  template: `
    <ezs-account-settings></ezs-account-settings>
  `,
})
export class AccountSettingsContainer implements OnInit {
  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new SetAdminPageTitle('Settings', 'Manage account settings'));
  }
}
