
    <admin-sidebar
      [user]="user$ | async"
      [account]="account$ | async"
      [isCollapsed]="isSidebarCollapsed$ | async"
      [isMenuOpen]="isMenuOpen$ | async"
      (toggleMenu)="toggleMenu()"
      (toggleProfileModal)="toggleProfileModal()"
      (signOut)="signOut($event)">
    </admin-sidebar>
    <div [class.authenticated]="authorized$ | async" class="admin-container">
      <admin-header
        [user]="user$ | async"
        [newReportCount]="newReportsCount$ | async"
        [newMessageCount]="newMessageCount$ | async"
        [pendingReportCount]="pendingReviewCount$ | async"
        [isSidebarCollapsed]="isSidebarCollapsed$ | async"
        [isMenuOpen]="isMenuOpen$ | async"
        (toggleSidebar)="toggleSidebar()"
        (toggleMenu)="toggleMenu()"
        (signOut)="signOut()">
      </admin-header>
      <div
        [class.sidebar-collapsed]="isSidebarCollapsed$ | async"
        [class.menu-open]="isMenuOpen$ | async"
        class="main-content">
        <div class="page-title-wrapper">
          <div *ngIf="pageTitle$ | async as pageTitle">
            <span class="page-title">{{pageTitle.main}}</span>
            <span class="page-subtitle">{{pageTitle.sub}}</span>
          </div>
        </div>
        
        <div class="content-wrapper">
          <!--<div [@routerAnimation]="getRouteAnimation(outlet)">-->
          <router-outlet #outlet="outlet"></router-outlet>
          <!--</div>-->
        </div>
      </div>

    </div>
    <ezs-profile-modal 
      [isOpen]="isProfileModalOpen$ | async"
      [user]="user$ | async"
      [uploadImageRequestStatus]="uploadProfilePhotoRequestStatus"
      [updateNameRequestStatus]="updateNameRequestStatus"
      [updateTitleRequestStatus]="updateTitleRequestStatus"
      (uploadProfileImage)="uploadProfileImage($event)"
      (updateName)="updateDisplayName($event)"
      (updateTitle)="updateJobTitle($event)"
      (toggleOpen)="toggleProfileModal()">
    </ezs-profile-modal>
  