import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/observable';
import { AppState, AuthState, FirestoreState } from './store/state';
import { UserModel } from './models';
import { SignOut } from './store/actions/auth.actions';
import { AUTH_ROLE } from '@ezspeek/models';
import { ToasterConfig } from 'angular2-toaster';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { Subscription } from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import { DismissEmergencyAlert, DisplayEmergencyAlert } from '@ezspeek/store/actions/app.actions';
import { EmergencyAlertModel } from '@ezspeek/models/common.models';
import { NotificationAlertsService } from '@ezspeek/services/notification-alerts.service';
import { LoadPlans, LoadProducts, SetAccount } from '@ezspeek/store/actions/firestore.actions';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Navigate} from '@ngxs/router-plugin';

@Component({
  selector: 'body',
  template: `
    <div *ngIf="(loading$ | async); else loadedState" class="pre-loader">
      <div class="pre-loader_inner">
        <img  class="logo" src="assets/images/ezSpeekLogo2.svg" alt="ezSpeek">
        <span class="brand">ez<span>Speek</span></span>
        <img src="assets/images/bars.svg" alt="loading...">
      </div>
    </div>
    <ng-template #loadedState>
      <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
      <div [class.client-app]="isClientApp" class="app-container">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
      <ezs-emergency-alert 
        *ngIf="(emergencyAlert$ | async) as eAlert" 
        [alert]="eAlert" 
        [user]="user$ | async" 
        (dismiss)="dismissEmergencyAlert($event)">
      </ezs-emergency-alert>
    </ng-template>
  `
})
export class AppContainer implements OnInit {

  @Select(AuthState.loading) loading$: Observable<boolean>;
  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;
  @Select(FirestoreState.newReportsCount) newReportsCount$: Observable<number>;
  @Select(AppState.emergencyAlert) emergencyAlert$: Observable<EmergencyAlertModel>;

  private _emergencyAlertListener: Subscription;
  private _accountListener: Subscription;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private el: ElementRef,
    private af: AngularFireService,
    private notifications: NotificationAlertsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const body = this.el.nativeElement;
    this.store.select(AppState.isBgHidden).subscribe(hide => {
      if (hide)
        this.renderer.addClass(body, 'remove-bg');
      else
        this.renderer.removeClass(body, 'remove-bg');
    });

    this.store.select(AppState.isMenuOpen).subscribe(isOpen => {
      if (isOpen)
        this.renderer.addClass(body, 'menu-open');
      else
        this.renderer.removeClass(body, 'menu-open');
    });

    this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event.url.includes('/admin/'))
        this.renderer.addClass(body, 'admin');
      else this.renderer.removeClass(body, 'admin');
    });

    this.af.getProductsCollectionRef().valueChanges().subscribe(products => {
        this.store.dispatch(new LoadProducts(products));
      });

    this.af.getPlansCollectionRef().valueChanges().subscribe(plans => {
        this.store.dispatch(new LoadPlans(plans));
      });

    this.user$.pipe(filter(user => user !== undefined)).subscribe(user => {
      if (!!user) {
        this._setupEmergencyAlertsListener(user.aid);
        this._setupAccountListener(user.aid);
        this.notifications.initialize();
      } else {
        this._cancelEmergencyAlertsListener();
        this._cancelAccountListener();
        this.notifications.cancel();
      }
    });
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  signOut() {
    this.store.dispatch(new SignOut());
  }

  dismissEmergencyAlert({ uid, alertId, aid }) {
    this.af.dismissEmergencyAlert(uid, alertId, aid);
  }

  private _setupEmergencyAlertsListener(aid: string) {
    if (!!this._emergencyAlertListener && !this._emergencyAlertListener.closed) {
      this._emergencyAlertListener.unsubscribe();
    }

    this._emergencyAlertListener = this.af.getEmergencyAlertsCollectionRef(aid, ref => {
      return ref.where('isActive', '==', true);
    }).valueChanges()
      .pipe(map(alerts => !!alerts ? alerts[0] : null))
      .subscribe(alert => {
        if (!!alert)
          this.store.dispatch(new DisplayEmergencyAlert({
            id: alert.id,
            message: alert.message,
            isActive: alert.isActive,
            activatedBy: alert.activatedBy
          }));
        else
          this.store.dispatch(new DismissEmergencyAlert());
      });
  }

  private _cancelEmergencyAlertsListener() {
    if (!this._emergencyAlertListener)
      return;

    if (!this._emergencyAlertListener.closed)
      this._emergencyAlertListener.unsubscribe();
  }

  private _setupAccountListener(aid: string) {
    if (!!this._accountListener && !this._accountListener.closed) {
      this._accountListener.unsubscribe();
    }

    this._accountListener = this.af.getAccountDocRef(aid)
      .valueChanges()
      .subscribe(account => {
        this.store.dispatch(new SetAccount(account));

        if (account.status === 'inactive')
          this.store.dispatch(new Navigate(['/dashboard']));
      });
  }

  private _cancelAccountListener() {
    if (!this._accountListener)
      return;

    if (!this._accountListener.closed) {
      this._accountListener.unsubscribe();
      this.store.dispatch(new SetAccount(undefined));
    }
  }

  get isClientApp(): boolean {
    const user = this.store.selectSnapshot(AppState.authenticatedUser);
    return !!user && user.role === AUTH_ROLE.CLIENT;
  }

  get toasterConfig(): ToasterConfig {
    return new ToasterConfig({
      mouseoverTimerStop: true,
      tapToDismiss: false,
      animation: 'flyRight',
      showCloseButton: true,
      closeHtml: '<button class="toast-close-button" type="button">×</button>',
      typeClasses: {
        error: 'toast-error',
        info: 'toast-info',
        wait: 'toast-wait',
        success: 'toast-success',
        warning: 'toast-warning',
        primary: 'toast-primary',
        accent: 'toast-accent',
        mute: 'toast-mute'
      },
    });
  }
}
