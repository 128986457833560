import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Credentials { email: string; password: string; }

@Component({
  selector: 'client-sign-in',
  template: `
    <div class="client-sign-in-container">
      <h3 class="headline">Enter your school <span>access ID</span></h3>
      <form [formGroup]="form" *ngIf="form" class="login-form">
        <div class="form-field">
          <input placeholder="Access ID" formControlName="accessId">
        </div>
        <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
        <button mat-raised-button color="primary" class="button button-block" [disabled]="!form.valid" (click)="submitForm()">
          Sign In
        </button>
        <p class="question">
          Are you an Admin?
          <a [routerLink]="['/sign-in']">Sign in here</a>
        </p>
      </form>
    </div>
  `,
  styleUrls: ['./client-sign-in.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSignInComponent implements OnInit {

  @Input() errorMsg: string;

  @Output() signIn: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      accessId: ['', [ Validators.required ]]
    });
  }

  submitForm() {
    if (this.form.valid)
      this.signIn.emit(this.form.get('accessId').value);
  }
}
