import { AccountModel, REPORT_STATUS, ReportModel, UserModel } from '@ezspeek/models';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  CreateReport,
  CreateReportError,
  CreateReportSuccess,
  LoadAccount, LoadAdmin, LoadAdmins, LoadPlans, LoadProducts,
  LoadReports,
  LoadUser, SetAccount,
  SetUser
} from '../actions/firestore.actions';
import { tap, take } from 'rxjs/operators';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { PlanModel, ProductModel } from '@ezspeek/models/stripe.models';

export interface FirestoreStateModel {
  users?: { [key: string]: UserModel };
  account?: AccountModel;
  reports?: ReportModel[];
  isSendingReport?: boolean;
  sendReportError?: string;
  products?: ProductModel[];
  plans?: PlanModel[];
}

type FirestoreContext = StateContext<FirestoreStateModel>;

@State<FirestoreStateModel>({
  name: 'firestore',
  defaults: {}
})
export class FirestoreState {
  constructor(private af: AngularFireService) {}

  @Selector()
  static isSendingReport({ isSendingReport }: FirestoreStateModel): boolean {
    return isSendingReport;
  }

  @Selector()
  static sendReportError({ sendReportError }: FirestoreStateModel): string {
    return sendReportError;
  }

  @Selector()
  static reports({ reports }: FirestoreStateModel): ReportModel[] {
    return reports;
  }

  @Selector()
  static newReportsCount({ reports }: FirestoreStateModel): number {
    if (!reports)
      return 0;

    return reports.filter(report => report.status === REPORT_STATUS.NEW).length;
  }

  @Selector()
  static account({ account }: FirestoreStateModel): AccountModel {
    return account;
  }

  @Selector()
  static users({ users }: FirestoreStateModel): UserModel[] {
    const userCollection: UserModel[] = [];

    if (!!users) {
      Object.keys(users).forEach(uid => {
        userCollection.push(users[uid]);
      });
    }

    return userCollection;
  }

  @Selector()
  static userPhotoUrls({ users }: FirestoreStateModel): { photoURL: string, uid: string }[] {
    let userMap = [];

    if (!!users) {
      userMap = Object.keys(users).map(uid => {
        return { photoURL: users[uid].photoURL, uid };
      });
    }
    return userMap;
  }

  @Selector()
  static userDisplayNames({ users }: FirestoreStateModel): { displayName: string, uid: string }[] {
    let userMap = [];

    if (!!users) {
      userMap = Object.keys(users).map(uid => {
        return { displayName: users[uid].displayName, uid };
      });
    }
    return userMap;
  }

  @Selector()
  static products({ products }: FirestoreStateModel): ProductModel[] {
    return products;
  }

  @Selector()
  static plans({ plans }: FirestoreStateModel): PlanModel[] {
    return plans;
  }

  @Action(LoadUser)
  loadUser({ patchState, getState, dispatch }: FirestoreContext, { uid, loadAccount }: LoadUser) {
    return this.af.getUserDocRef(uid)
    .valueChanges()
    .pipe(
      take(1),
      tap(user => {
        if (!!user) {
          patchState({
            users: {
              [uid]: user
            }
          });

          // if (loadAccount && user.aid)
          //   dispatch(new LoadAccount(user.aid));

          return user;
        }
      })
    );
  }

  @Action(LoadAdmin)
  loadAdmin({ patchState, getState }: FirestoreContext, { user }: LoadAdmin) {
    const state = getState();

    patchState({
      users: {
        ...state.users,
        [user.uid]: user
      }
    });
  }

  @Action(LoadAdmins)
  loadAdmins({ patchState }: FirestoreContext, { admins }: LoadAdmins) {
    const adminRequests = admins.map(({ uid }) => this.af.getUserDocRef(uid).ref.get().then(adminDoc => {
      return adminDoc.data();
    }));

    return Promise.all(adminRequests).then((res: UserModel[]) => {
      const adminCollection = {};
      res.forEach(admin => {
        adminCollection[admin.uid] = admin;
      });
      patchState({
        users: { ...adminCollection }
      });
    });
  }

  @Action(LoadAccount)
  loadAccount({ patchState, getState }: FirestoreContext, { aid }: LoadAccount) {
    return this.af.getAccountDocRef(aid)
      .valueChanges()
      .pipe(
        take(1),
        tap(account => {
          if (!!account)
            patchState({
              account: account
            });
        })
      );
  }

  @Action(SetAccount)
  setAccount({ patchState }: FirestoreContext, { account }: SetAccount) {
    patchState({
      account: account
    });
  }

  @Action(LoadReports)
  loadReports({ patchState, getState }: FirestoreContext, { aid }: LoadReports) {
    return this.af.getReportsCollectionRef(aid)
      .valueChanges()
      .pipe(
        take(1),
        tap(reports => {
          patchState({
            reports
          });
        })
      );
  }

  @Action(LoadProducts)
  loadProducts({ patchState }: FirestoreContext, { products }: LoadProducts) {
    patchState({
      products
    });
  }

  @Action(LoadPlans)
  loadPlans({ patchState }: FirestoreContext, { plans }: LoadPlans) {
    patchState({
      plans
    });
  }

  @Action(SetUser)
  setUser({ dispatch }: FirestoreContext, { user }: SetUser) {
    return this.af.getUserDocRef(user.uid).set({ ...user } as UserModel)
      .then();
  }

  @Action(CreateReport)
  createReport({ dispatch, patchState }: FirestoreContext, { report, files }: CreateReport) {
    patchState({
      isSendingReport: true
    });
    return this.af.addReport(report, files)
      .then(_ => dispatch(new CreateReportSuccess()))
      .catch(error => dispatch(new CreateReportError(error.message || error)));
  }

  @Action(CreateReportSuccess)
  createReportSuccess({ patchState }: FirestoreContext, action: CreateReportSuccess) {
    patchState({
      isSendingReport: false,
      sendReportError: undefined
    });
  }

  @Action(CreateReportError)
  createReportError({ patchState }: FirestoreContext, { error }: CreateReportError) {
    patchState({
      isSendingReport: false,
      sendReportError: error
    });
  }
}
