import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ezs-report-complete',
  template: `
    <mat-card class="thank-you-message fadeInUp animated">
      <mat-card-content>
        <div class="checkmark-wrapper">
          <animated-check [thin]="true" width="150px"></animated-check>
        </div>
        <h1>Thank You!</h1>
        <h2>Your report has been submitted</h2>
        <button mat-raised-button class="ezs-button button-accent2 button-block" (click)="newReport.emit()">Send New Report</button>
        <button mat-raised-button class="ezs-button button-success button-block" routerLink="/client/dashboard">Done</button>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `      
      button {
        margin: 1em auto;
      }
    `
  ]
})
export class ReportCompleteComponent {

  @Output() newReport: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
