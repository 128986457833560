import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AUTH_ROLE, REPORT_STATUS, ReportModel, UserModel } from '@ezspeek/models';

@Component({
  selector: 'ezs-reports-list',
  template: `
    <div class="filters" *ngIf="!isClientView">
      <mat-chip-list class="bordered-list-wrapper" [multiple]="true">
        <mat-chip (click)="toggleFilter('new')" class="mat-chip-accent2-light" [selected]="showNew">
          New <mat-icon *ngIf="showNew">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('viewed')" [selected]="showViewed">
          Viewed <mat-icon *ngIf="showViewed">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('active')" class="mat-chip-primary" [selected]="showActive">
          Active <mat-icon *ngIf="showActive">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('pending')" class="mat-chip-caution" [selected]="showPending">
          Pending <mat-icon *ngIf="showPending">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('resolved')" class="mat-chip-success" [selected]="showResolved">
          Resolved <mat-icon *ngIf="showResolved">close</mat-icon>
        </mat-chip>
        <mat-chip (click)="toggleFilter('false_report')" class="mat-chip-warn" [selected]="showFalseReports">
          False Report <mat-icon *ngIf="showFalseReports">close</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <mat-slide-toggle class="mat-slide-toggle-sm ezs-accent2"
                        [checked]="showArchived"
                        (change)="toggleArchived.emit()">
        <span>Show Archived</span>
      </mat-slide-toggle>
      <mat-slide-toggle class="mat-slide-toggle-sm ezs-alert"
                        [checked]="applyNewMessageFilter"
                        (change)="toggleNewMessageFilter.emit()">
        <span>New Messages</span>
      </mat-slide-toggle>
    </div>
    <mat-card *ngIf="hasReports" class="reports-list fadeInUp animated">
      <mat-card-content>
        <ezs-reports-list-item 
          *ngFor="let report of filteredReports" 
          [report]="report" 
          [authRole]="user.role">
        </ezs-reports-list-item>
        <div *ngIf="showNoFilteredResultsMessage" [class.client-message]="isClientView" class="no-results-message">
          <h2>There are no incident reports that match the current filters</h2>
        </div>
      </mat-card-content>
    </mat-card>
    <div *ngIf="showNoReportsMessage" [class.client-message]="isClientView" class="no-reports-message">
      <h2>{{noReportsMessage}}</h2>
      <button
        *ngIf="isClientView"
        mat-raised-button
        color="primary"
        class="button"
        [routerLink]="[routeRoot, 'report-form']">Report An Incident</button>
      <button 
        mat-raised-button 
        color="primary"
        class="button button-accent2"
        [routerLink]="[routeRoot, 'dashboard']">Go To Dashboard</button>
    </div>
  `,
  styleUrls: ['./reports-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsListComponent {
  @Input() reports: ReportModel[];
  @Input() user: UserModel;
  @Input() statusFilters: REPORT_STATUS[];
  @Input() applyNewMessageFilter: boolean;
  @Input() dateFilterStart: number;
  @Input() dateFilterEnd: number;
  @Input() showArchived: boolean;

  @Output() updateStatusFilter: EventEmitter<REPORT_STATUS[]> = new EventEmitter<REPORT_STATUS[]>();
  @Output() applyDateFilter: EventEmitter<{ start: number, end: number }> = new EventEmitter<{ start: number, end: number }>();
  @Output() toggleNewMessageFilter: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleArchived: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  toggleFilter(status: string) {
    let reportStatus: REPORT_STATUS;
    const filters: REPORT_STATUS[] = [ ...this.statusFilters ];

    switch (status.toLowerCase()) {
      case 'new':
        reportStatus = REPORT_STATUS.NEW;
        break;
      case 'viewed':
        reportStatus = REPORT_STATUS.VIEWED;
        break;
      case 'active':
        reportStatus = REPORT_STATUS.ACTIVE;
        break;
      case 'pending':
        reportStatus = REPORT_STATUS.PENDING;
        break;
      case 'resolved':
        reportStatus = REPORT_STATUS.RESOLVED;
        break;
      case 'false_report':
        reportStatus = REPORT_STATUS.FALSE_REPORT;
        break;
    }

    const index = this.statusFilters.indexOf(reportStatus);

    if (index >= 0)
      filters.splice(index, 1);
    else
      filters.push(reportStatus);

    this.updateStatusFilter.emit(filters);
  }

  get routeRoot(): string {
    return this.isClientView ? '/client' : '/admin';
  }

  get hasReports(): boolean {
    return !!this.reports && this.reports.length > 0;
  }

  get hasFilteredResults(): boolean {
    return this.hasReports && this.filteredReports.length > 0;
  }

  get showNoReportsMessage(): boolean {
    return this.reports !== undefined && this.reports !== null && !this.hasReports;
  }

  get showNoFilteredResultsMessage(): boolean {
    return this.hasReports && !this.hasFilteredResults;
  }

  get isClientView(): boolean {
    return this.user.role === AUTH_ROLE.CLIENT;
  }

  get noReportsMessage(): string {
    return this.isClientView ? `Looks like you haven't reported any incidents yet` :
      `Looks like there hasn't been any incidents reported yet`;
  }

  get filteredReports(): ReportModel[] {
    return this.reports.filter(report => {
      let isIncluded = true;

      if (!!this.statusFilters && this.statusFilters.length > 0) {
        isIncluded = this.statusFilters.indexOf(report.status) >= 0;
      }

      if (!this.showArchived) {
        isIncluded = isIncluded && !report.archived;
      }

      if (this.applyNewMessageFilter) {
        isIncluded = isIncluded && this.isClientView ? report.newMessageFromAdmin : report.newMessageFromClient;
      }

      return isIncluded;
    });
  }

  get showNew(): boolean {
    return this.statusFilters.indexOf(REPORT_STATUS.NEW) >= 0;
  }

  get showViewed(): boolean {
    return this.statusFilters.indexOf(REPORT_STATUS.VIEWED) >= 0;
  }

  get showActive(): boolean {
    return this.statusFilters.indexOf(REPORT_STATUS.ACTIVE) >= 0;
  }

  get showPending(): boolean {
    return this.statusFilters.indexOf(REPORT_STATUS.PENDING) >= 0;
  }

  get showResolved(): boolean {
    return this.statusFilters.indexOf(REPORT_STATUS.RESOLVED) >= 0;
  }

  get showFalseReports(): boolean {
    return this.statusFilters.indexOf(REPORT_STATUS.FALSE_REPORT) >= 0;
  }
}
