import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FirebaseAuthUser, REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators, runDiscreteValidation } from '@ezspeek/common';
import { Subscription } from 'rxjs';

interface RegistrationData { email: string; password: string; displayName: string; }

@Component({
  selector: 'ezs-continue-registration',
  template: `
    <div class="registration-container">
      <div *ngIf="loading" class="loading-container">
        <ezs-loading [absoluteCenter]="true">
          <h2 class="text-center">Loading...</h2>
        </ezs-loading>
      </div>
      <form [formGroup]="form" class="fadeInLeft animated" *ngIf="showForm">
        <h2 class="has-subheading">Almost done! </h2>
        <span class="form-subheading">We just need a little more info to complete your registration</span>
        <div class="form-field">
          <input placeholder="Enter your full name" formControlName="displayName">
        </div>
        <div class="form-field">
          <input placeholder="Create a password" type="password" formControlName="password">
        </div>
        <div class="form-field">
          <input placeholder="Repeat your password" type="password" formControlName="repeatPassword">
        </div>
        <p class="error-msg" *ngIf="requestError">{{requestError}}</p>
        <button
          class="button button-block button-lg"
          [disabled]="!form.valid || completingRegistration"
          (click)="complete()">
          <img *ngIf="completingRegistration" class="loading-icon" src="assets/images/bars.svg"> {{updateButtonText}}
        </button>
      </form>
      <div *ngIf="reAuthRequired">
        <div class="form-container">
          <h2>Your authentication session has expired. Contact your Account Manager to resend a registration email.</h2>
          <!--<button
            class="button button-block button-lg"
            [disabled]="sendingEmail"
            (click)="complete()">
            <img *ngIf="completingRegistration" class="loading-icon" src="assets/images/bars.svg"> {{sendEmailButtonText}}
          </button>-->
        </div>
      </div>
    </div>

  `,
  styleUrls: ['../admin-registration.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContinueRegistrationComponent implements OnInit, OnDestroy {

  form: FormGroup;

  @Input() user: FirebaseAuthUser;
  @Input() requestError: string;
  @Input() requestStatus: REQUEST_STATUS;
  @Input() loading = true;
  @Input() reAuthRequestStatus: REQUEST_STATUS;
  @Input() reAuthRequired: boolean;

  @Output() submit: EventEmitter<RegistrationData> = new EventEmitter<RegistrationData>();
  @Output() reAuthenticate: EventEmitter<string> = new EventEmitter<string>();

  private passwordCtrlSub: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      displayName: ['', [Validators.required]],
      password: ['', [CustomValidators.password]],
      repeatPassword: ['', [CustomValidators.repeatPassword(() => {
        return !!this.form ? this.password : null;
      })]]
    });

    this.passwordCtrlSub = this.password.valueChanges.subscribe(_ => {
      runDiscreteValidation(this.repeatPassword);
    });
  }

  complete() {
    if (!this.form.valid)
      return;

    this.submit.emit({
      email: this.user.email,
      password: this.password.value,
      displayName: this.displayName.value
    });
  }

  sendReAuthEmail() {
    if (!!this.user && this.user.email)
      this.reAuthenticate.emit(this.user.email);
  }

  get completingRegistration(): boolean {
    return this.requestStatus === REQUEST_STATUS.LOADING;
  }

  get showForm(): boolean {
    return !this.loading && !this.reAuthRequired && !!this.user && !!this.form;
  }

  get reAuthEmailSent(): boolean {
    return this.reAuthRequestStatus === REQUEST_STATUS.SUCCESS;
  }

  get sendingEmail(): boolean {
    return this.reAuthRequestStatus === REQUEST_STATUS.LOADING;
  }

  get sendEmailButtonText(): string { return this.sendingEmail ? 'Sending...' : 'Send Email'; }

  get updateButtonText(): string { return this.completingRegistration ? 'Completing...' : 'Complete Registration'; }

  get displayName(): AbstractControl { return this.form.get('displayName'); }
  get password(): AbstractControl { return this.form.get('password'); }
  get repeatPassword(): AbstractControl { return this.form.get('repeatPassword'); }

  ngOnDestroy() {
    if (!!this.passwordCtrlSub && !this.passwordCtrlSub.closed)
      this.passwordCtrlSub.unsubscribe();
  }
}
