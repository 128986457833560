
    <header [class.scrolled]="displayScrolled || !!user">
      <nav class="navbar">
        <span class="spacer"></span>
        <a [routerLink]="['/home']" class="brand">
          <img src="assets/images/ezSpeekLogo2.svg" alt="ezspeek"> ez<span>Speek</span>
        </a>
        <span class="spacer"></span>
      </nav>
    </header>
  