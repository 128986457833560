import { AccountModel, ReportModel, UserModel } from '@ezspeek/models';
import { PlanModel, ProductModel } from '@ezspeek/models/stripe.models';

export class LoadUser {
  static readonly type = '[Firestore] Load User';
  constructor(public uid: string, public loadAccount: boolean = false) {}
}

export class LoadAdmin {
  static readonly type = '[Firestore] Load Admin';
  constructor(public user: UserModel) {}
}

export class RemoveAdmin {
  static readonly type = '[Firestore] Remove Admin';
  constructor(public user: UserModel) {}
}

export class LoadAdmins {
  static readonly type = '[Firestore] Load Admins';
  constructor(public admins: { uid: string }[]) {}
}

export class LoadAccount {
  static readonly type = '[Firestore] Load Account';
  constructor(public aid: string) {}
}

export class SetAccount {
  static readonly type = '[Firestore] Set Account';
  constructor(public account: AccountModel) {}
}

export class LoadReports {
  static readonly type = '[Firestore] Load Reports';
  constructor(public aid: string) {}
}

export class LoadProducts {
  static readonly type = '[Firestore] Load Products';
  constructor(public products: ProductModel[]) {}
}

export class LoadPlans {
  static readonly type = '[Firestore] Load Plans';
  constructor(public plans: PlanModel[]) {}
}


export class SetUser {
  static readonly type = '[Firestore] Set User';
  constructor(public user: UserModel) {}
}

export class CreateReport {
  static readonly type = '[Firestore] Create Report';
  constructor(public report: ReportModel, public files: File[]) {}
}

export class CreateReportSuccess {
  static readonly type = '[Firestore] Create Report Success';
}

export class CreateReportError {
  static readonly type = '[Firestore] Create Report Error';
  constructor(public error: string) {}
}
