import { NgModule, ModuleWithProviders } from '@angular/core';

import { PlatformService } from '@ezspeek/services/platform.service';

import { StripeJSLoader } from './services/stripe-js-loader';
import { StripeService } from './services/stripe.service';
import { StripeFactoryService } from './services/stripe-factory.service';

import {
  StripeOptions,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_OPTIONS
} from './models';


@NgModule()
export class NgxStripeModule {
  public static forRoot(
    publishableKey?: string,
    options?: StripeOptions
  ): ModuleWithProviders {
    return {
      ngModule: NgxStripeModule,
      providers: [
        StripeJSLoader,
        StripeService,
        StripeFactoryService,
        PlatformService,
        {
          provide: STRIPE_PUBLISHABLE_KEY,
          useValue: publishableKey
        },
        {
          provide: STRIPE_OPTIONS,
          useValue: options
        }
      ]
    };
  }
}
