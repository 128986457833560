import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClientHeaderComponent } from './header/client-header.component';
import { ClientRouter } from './client.router';
import { ReportFormContainer } from './report-form/report-form.container';
import { ReportFormComponent } from './report-form/report-form.component';
import { ReportCompleteComponent } from './report-form/report-complete.component';
import { AppCommonModule } from '@ezspeek/common/app-common.module';
import { ClientSignInComponent } from './sign-in/client-sign-in.component';
import { ClientSignInContainer } from './sign-in/client-sign-in.container';
import { ClientDashboardContainer } from './dashboard/dashboard.container';
import { ClientDashboardComponent } from './dashboard/dashboard.component';
import { ClientReportsContainer } from './reports/client-reports.container';

const DECLARATIONS = [
  ClientRouter,
  ClientHeaderComponent,
  ReportFormContainer,
  ReportFormComponent,
  ReportCompleteComponent,
  ClientSignInContainer,
  ClientSignInComponent,
  ClientDashboardContainer,
  ClientDashboardComponent,
  ClientReportsContainer
];

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    AppCommonModule,
    RouterModule,
  ],
  exports: [
    ...DECLARATIONS
  ]
})
export class ClientModule {}
