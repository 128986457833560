import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MessageModel, REPORT_STATUS, ReportModel, REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { Observable, of } from 'rxjs';
import { AppState } from '@ezspeek/store/state';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { QueryFn } from '@angular/fire/firestore';

@Component({
  template: `
    <div class="client-content-wrapper">
      <ezs-reports-list
        *ngIf="!viewingReport"
        [reports]="reports$ | async"
        [user]="authenticatedUser$ | async"
        [showArchived]="false"
        [applyNewMessageFilter]="false"
        [statusFilters]="statusFilters">
      </ezs-reports-list>
      <ezs-report-details
        *ngIf="viewingReport"
        [user]="authenticatedUser$ | async"
        [report]="selectedReport$ | async"
        [sendChatMessageStatus]="sendChatMessageStatus"
        [sendChatMessageError]="sendChatMessageError"
        [chatMessages]="chatMessages$ | async"
        (updateReport)="updateReport($event)"
        (sendChatMessage)="sendChatMessage($event)">
      </ezs-report-details>
      <mat-icon [routerLink]="['/client', backRoute]" class="back-arrow">arrow_back</mat-icon>
    </div>
  `
})
export class ClientReportsContainer  implements OnInit {
  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<UserModel>;
  reports$: Observable<ReportModel[]> = of(undefined);
  selectedReport$: Observable<ReportModel>;
  chatMessages$: Observable<MessageModel[]>;

  sendChatMessageStatus: REQUEST_STATUS;
  sendChatMessageError: string;

  private selectedReportId: string;

  readonly defaultStatusFilters: REPORT_STATUS[] = [
    REPORT_STATUS.NEW,
    REPORT_STATUS.VIEWED,
    REPORT_STATUS.ACTIVE,
    REPORT_STATUS.PENDING,
    REPORT_STATUS.RESOLVED,
    REPORT_STATUS.FALSE_REPORT
  ];
  private _statusFilters: REPORT_STATUS[] = null;

  constructor(private store: Store, private af: AngularFireService, private route: ActivatedRoute) {}

  ngOnInit() {
    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);

    this.filterReports();

    this.route.params.subscribe(({ reportId }) => {
      if (reportId) {
        this.selectedReport$ = this._getReports(ref => ref.where('id', '==', reportId))
          .pipe(map(results => results[0]));

        this.chatMessages$ = this.af.getReportChatMessagesCollectionRef(
          aid, reportId, ref => ref.orderBy('createdAt', 'asc')
        ).valueChanges();
      }

      this.selectedReportId = reportId || null;
    });
  }

  filterReports() {
    const { uid } = this.store.selectSnapshot(AppState.authenticatedUser);
    this.reports$ = this._getReports(ref => {
      return ref.where('reportedBy', '==', uid).orderBy('createdAt', 'desc');
    });
  }

  updateReport(report: { data: Partial<ReportModel>, aid: string, id: string }) {
    this.af.getReportDocRef(report.aid, report.id).update(report.data);
  }

  sendChatMessage(message: MessageModel) {
    this.sendChatMessageError = '';
    this.sendChatMessageStatus = REQUEST_STATUS.LOADING;

    this.af.addChatMessage(message)
      .then(_ => {
        this.sendChatMessageError = '';
        this.sendChatMessageStatus = REQUEST_STATUS.SUCCESS;
      })
      .catch(err => {
        this.sendChatMessageError = err.message || err;
        this.sendChatMessageStatus = REQUEST_STATUS.ERROR;
      });
  }

  private _getReports(queryFn?: QueryFn) {
    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);

    return this.af.getReportsCollectionRef(aid, queryFn).valueChanges();
  }

  get viewingReport(): boolean {
    return !!this.selectedReportId;
  }

  get backRoute(): string {
    return this.viewingReport ? 'reports' : 'dashboard';
  }

  get statusFilters(): REPORT_STATUS[] {
    return this._statusFilters || this.defaultStatusFilters;
  }
}
