import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserModel } from '@ezspeek/models';
import { AppState } from '@ezspeek/store/state';
import { Observable } from 'rxjs/Observable';

@Component({
  template: `
    <client-header
      [displayScrolled]="isMainContentScrolled"
      [user]="user$ | async">
    </client-header>
    <div class="main-content" (scroll)="updateContentScrolled($event)">
      <!--<div [@routerAnimation]="getRouteAnimation(outlet)">-->
        <router-outlet #outlet="outlet"></router-outlet>
      <!--</div>-->
    </div>
  `,
  styleUrls: ['./client-router.scss']
  // animations: ROUTING_ANIMATIONS
})
export class ClientRouter {

  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;

  private _contentScrolled = false;

  constructor() {}

  updateContentScrolled({ target }) {
    this._contentScrolled = target.scrollTop !== 0;
  }

  get isMainContentScrolled(): boolean {
    return this._contentScrolled;
  }
}
