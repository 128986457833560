import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class PlatformService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  public get window(): Window {
    if (isPlatformBrowser(this.platformId)) {
      return window;
    }
    return {} as Window;
  }

  public get document(): Document {
    if (isPlatformBrowser(this.platformId)) {
      return document;
    }
    return {} as Document;
  }
}
