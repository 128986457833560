
    <div
      class="reports-list-item reports-list-item-{{statusClass}}"
      [routerLink]="[routeRoot, 'reports', report.id]">
      <span class="report-category">{{report.category}}</span>
      <span class="report-submit-date">{{report.createdAt | moment:'MMM D, YYYY'}}</span>
      <span class="report-description">{{report.description}}</span>
      <mat-icon class="new-message-icon" *ngIf="hasNewMessage">question_answer</mat-icon>
      <mat-icon class="archived-icon" *ngIf="report.archived">archive</mat-icon>
      <mat-icon class="attachment-icon" *ngIf="report.evidence">attach_file</mat-icon>
      <report-status-badge [archived]="report.archived" [status]="report.status" size="sm"></report-status-badge>
    </div>
  