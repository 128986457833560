import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REPORT_STATUS, ReportModel, UserModel } from '@ezspeek/models';
import * as moment from 'moment';

export interface ReportFormModel {
  data: ReportModel;
  files: File[];
}

@Component({
  selector: 'ezs-report-form',
  template: `
    <h1 class="headline fadeInUp animated">Provide as much detail as possible</h1>
    <form [formGroup]="form" *ngIf="form" class="login-form fadeInUp animated">
      <div class="form-field">
        <select placeholder="Email" formControlName="category">
          <option disabled selected value="">Select a category</option>
          <option *ngFor="let category of categories" [value]="category">{{category}}</option>
        </select>
      </div>
      <div class="form-field has-icon-right">
        <input [matDatepicker]="incidentDate" placeholder="When did this happen?" formControlName="incidentDate"/>
        <mat-datepicker-toggle [for]="incidentDate"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="true" #incidentDate></mat-datepicker>
      </div>
      
      <div [class.with-badge-list]="targets.length" class="form-field has-icon-right">
        <input #targetInput formControlName="target" placeholder="Who did this happen to?">
        <mat-icon class="accent2-color" (click)="addTarget(targetInput)">person_add</mat-icon>
      </div>
      <div *ngIf="targets.length" class="person-list-container accent2-color">
        <div class="person-list-item" *ngFor="let t of targets; let ti = index">
          <span>{{t}}</span>
          <mat-icon class="remove-item-icon" (click)="removeTarget(ti)">clear</mat-icon>
        </div>
      </div>
      
      <div [class.with-badge-list]="othersInvolved.length" class="form-field has-icon-right">
        <input #othersInput formControlName="othersInvolved" placeholder="Who was responsible/involved?">
        <mat-icon color="accent" (click)="addOthers(othersInput)">person_add</mat-icon>
      </div>
      <div *ngIf="othersInvolved.length" class="person-list-container">
        <div class="person-list-item" *ngFor="let name of othersInvolved; let oi = index">
          <span>{{name}}</span>
          <mat-icon class="remove-item-icon" (click)="removeOthersInvolved(oi)">clear</mat-icon>
        </div>
      </div>
      
      <div class="form-field">
        <textarea autofitContent placeholder="Incident Description" formControlName="description"></textarea>
      </div>
      <div class="form-field">
        <mat-list class="file-list" [class.file-list-empty]="fileCount === 0">
          <div mat-subheader class="file-list-header">
            <span>Evidence</span>
            <mat-icon [class.disabled]="maxFilesReached" class="add-files-icon" (click)="selectFiles(fileInput)">playlist_add</mat-icon>
          </div>
          <mat-list-item 
            [class.bordered]="i > 0"
            [class.invalid-file-type]="getFileTypeIcon(file.type) === 'error'"
            class="file-list-item" 
            *ngFor="let file of selectedFiles; let i = index">
            <mat-icon 
              matListIcon 
              class="file-type-icon">{{getFileTypeIcon(file.type)}}</mat-icon>
            <span class="file-name">{{file.name}}</span>
            <mat-icon class="remove-file-icon" (click)="removeFile(i)">clear</mat-icon>
          </mat-list-item>
          <mat-list-item class="file-type-error-msg" *ngIf="isFileTypeError">
            <div class="error-msg-content">
              <mat-icon class="error-msg-icon">error</mat-icon>
              <span>file must be an image, video, audio, or pdf.</span>
            </div>
          </mat-list-item>
        </mat-list>
        <input class="file-select" type="file" #fileInput (change)="addFiles($event)" multiple accept="audio/*|video/*|image/*|.pdf">
      </div>
      <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
      <button 
        mat-raised-button 
        color="primary" 
        class="button button-block button-submit" 
        [disabled]="!readyToSubmit || isSending" 
        (click)="submitForm()">{{buttonText}}</button>
    </form>
  `,
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit {

  @Input() client: UserModel;
  @Input() isSending: boolean;
  @Input() errorMsg: string;
  @Input() categories: string[] = ['Bullying', 'Drugs/Alcohol', 'Harassment', 'Gun/Weapon', 'Other'];

  @Output() sendReport: EventEmitter<ReportFormModel> = new EventEmitter<ReportFormModel>();

  form: FormGroup;

  private _files: File[] = [];
  private _targets: string[] = [];
  private _othersInvolved: string[] = [];

  readonly maxFileCount: number = 5;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      category: ['', [ Validators.required]],
      description: ['', [ Validators.required ]],
      incidentDate: [''],
      target: [''],
      othersInvolved: ['']
    });
  }

  addTarget(input) {
    if (this.targetCtrl.value) {
      this._targets.push(this.targetCtrl.value);
      this.targetCtrl.reset();
    } else if (input) {
      input.focus();
    }
  }

  removeTarget(index: number) {
    this._targets.splice(index, 1);
  }

  addOthers(input) {
    if (this.othersInvolvedCtrl.value) {
      this._othersInvolved.push(this.othersInvolvedCtrl.value);
      this.othersInvolvedCtrl.reset();
    } else if (input) {
      input.focus();
    }
  }

  removeOthersInvolved(index: number) {
    this._othersInvolved.splice(index, 1);
  }

  selectFiles(input: HTMLInputElement) {
    if (!this.maxFilesReached)
      input.click();
  }

  addFiles($event) {
    const fileList: FileList = $event.target.files;
    if (!this.maxFilesReached) {
      for (let i = 0; i < fileList.length; i++) {
        if (!this.maxFilesReached)
          this._files.push(fileList.item(i));
      }
    }
  }

  removeFile(index: number) {
    this._files.splice(index, 1);
  }

  getFileTypeIcon(fileType: string) {
    const MIME = fileType.split('/');
    switch (MIME[0]) {
      case 'image':
        return 'photo';
      case 'video':
        return 'videocam';
      case 'audio':
        return 'mic';
      default:
        if (MIME[1] === 'pdf')
          return 'picture_as_pdf';
        else
          return 'error';
    }
  }

  submitForm() {
    if (this.readyToSubmit) {
      this.sendReport.emit(this.formData);
    }
  }

  get buttonText() {
    return this.isSending ? 'Sending...' : 'Send Report';
  }

  get formData() {
    const form = this.form.value;

    const report: ReportModel = {
      status: REPORT_STATUS.NEW,
      description: form.description,
      category: form.category,
      aid: this.client.aid,
      reportedBy: this.client.uid,
    };

    if (this.othersInvolvedCtrl.value)
      this.addOthers(null);

    if (this.targetCtrl.value)
      this.addTarget(null);

    if (this.targets.length)
      report.victims = [ ...this.targets ];

    if (this.othersInvolved.length)
      report.otherPeopleInvolved = [ ...this.othersInvolved ];

    if (form.incidentDate)
      report.incidentDate = moment(form.incidentDate).toDate().getTime();

    return { data: report, files: [...this.selectedFiles] };
  }

  get selectedFiles(): File[] { return this._files; }

  get fileCount(): number { return this._files.length; }

  get maxFilesReached(): boolean { return this.fileCount === this.maxFileCount; }

  get isFileTypeError(): boolean {
    let hasError = false;

    this._files.forEach(file => {
      if (this.getFileTypeIcon(file.type) === 'error')
        hasError = true;
    });

    return hasError;
  }

  get readyToSubmit() {
    return this.form && this.form.valid && !this.isFileTypeError;
  }

  get targets(): string[] { return this._targets; }

  get othersInvolved(): string[] { return this._othersInvolved; }

  get targetCtrl(): AbstractControl { return this.form.get('target'); }
  get othersInvolvedCtrl(): AbstractControl { return this.form.get('othersInvolved'); }
}
