
    <mat-card [class.add-user-container]="isAddingUser" class="fadeInUp animated">
      <mat-card-content *ngIf="!isAddingUser">
        <div *ngIf="isAccountMgr" class="action-button-wrapper">
          <div class="flex-spacer"></div>
          <button 
            mat-button color="primary" *ngIf="!isDeletingUser" [disabled]="!canAddUser || isDeletingUser" (click)="isAddingUser = true">
            <mat-icon>person_add</mat-icon> Add User
          </button>
          <div *ngIf="isDeletingUser" class="loading-msg">
            <img src="assets/images/bars_primary-dark.svg" alt=""><span>Removing user...</span>
          </div>
        </div>
        <div *ngIf="hasUsers" class="users-list fadeIn animated">
          <ezs-user-row 
            *ngFor="let user of activeUsers; let i = index"
            [enableManagement]="isAccountMgr"
            [hideBorder]="!isAccountMgr && i === 0"
            [user]="user" 
            [disableOptions]="isDeletingUser"
            (deleteUser)="deleteUser.emit($event)" 
            (resendEmail)="resendEmail.emit($event)">
          </ezs-user-row>
        </div>
        <div *ngIf="!hasUsers" class="no-users-message fadeIn animated">
          <h4>No other users to manage</h4>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="isAccountMgr && isAddingUser">
        <div class="action-button-wrapper">
          <button
            class="fadeInLeft animated"
            mat-button color="primary" (click)="isAddingUser = false">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <div class="flex-spacer"></div>
        </div>
        <form [formGroup]="form" class="add-user-form elevation-3 fadeIn animated">
          <p>Invite a user to be added to this account. Once added, the user will have access to reports and statistics</p>
          <div class="form-field">
            <input type="email" formControlName="email" placeholder="Enter User's email">
          </div>
          <button (click)="sendEmail()" [disabled]="!canAddUser || isSendingEmail || !form.valid" mat-raised-button class="button">
            {{sendButtonText}}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  