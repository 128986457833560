import { Component, Input } from '@angular/core';
import { REPORT_STATUS } from '@ezspeek/models';
import { getReportStatusText } from '@ezspeek/common/helpers';

export type ReportStatusBadgeColor = 'accent' | 'accent2' | 'accent2-light' | 'primary' | 'warn' | 'success' | 'alert' | 'caution' | 'primary-dark' | 'default' | 'disabled';
export type ReportStatusBadgeSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

@Component({
  selector: 'report-status-badge',
  template: `
    <span 
      class="report-status-badge report-status-badge-{{statusColor}} report-status-badge-{{size}}">
      {{statusText}}
    </span>
  `
})
export class ReportStatusBadgeComponent {

  @Input() status: REPORT_STATUS;
  @Input() color: ReportStatusBadgeColor;
  @Input() size: ReportStatusBadgeSize = 'md';
  @Input() archived: boolean;

  constructor() {}

  get statusText(): string {
    return getReportStatusText(this.status);
  }

  get statusColor(): ReportStatusBadgeColor {
    if (this.color)
      return this.color;

    if (this.archived)
      return 'disabled';

    switch (this.status) {
      case REPORT_STATUS.NEW:
        return 'accent2-light';
      case REPORT_STATUS.VIEWED:
        return 'default';
      case REPORT_STATUS.ACTIVE:
        return 'primary';
      case REPORT_STATUS.PENDING:
        return 'caution';
      case REPORT_STATUS.RESOLVED:
        return 'success';
      case REPORT_STATUS.FALSE_REPORT:
        return 'warn';
      default:
        return 'default';
    }
  }
}
