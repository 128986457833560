
    <div *ngIf="enabled" class="ezs-page-container">
      <!--<mat-card class="statistics-options">
        <h2 class="stats-heading">Enter options to generate a <span>Statistics Chart.</span></h2>
        <form *ngIf="!!form">
          
        </form>
      </mat-card>

      <div [class.showing-chart]="showChart" class="monthly-stats-wrapper ezs-chart-container">
        <ezs-chart *ngIf="showChart" [data]="chartData" [config]="chartConfig"></ezs-chart>
      </div>-->
      <div class="feature-unavailable-message">
        <h2>The Statistics feature is still under development, but will be available soon</h2>
      </div>
    </div>
    <div *ngIf="!enabled" class="feature-unavailable-message">
      <h2>Uh oh! Statistics are not included with your current plan.</h2>
      <button
        mat-raised-button
        color="primary"
        class="button button-accent2"
        [routerLink]="['/admin', 'dashboard']">Go To Dashboard</button>     
    </div>
  