import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@ezspeek/common';

interface Credentials { email: string; password: string; }

@Component({
  selector: 'ezs-account-registration',
  template: `
    <h1 class="headline">Reporting made simple</h1>
    <form [formGroup]="form" *ngIf="form" class="login-form">
      <div class="form-field">
        <input placeholder="Email" type="email" formControlName="email">
      </div>
      <div class="form-field">
        <input placeholder="Password" type="password" formControlName="password">
      </div>
      <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
      <button class="button button-block button-lg" [disabled]="!form.valid" (click)="submitForm()">Sign In</button>
      <p class="question">
        Don't have an account?
        <a [routerLink]="['/sign-up']">Sign up now</a>
      </p>
      <p class="question">
        Need to submit a report?
        <a [routerLink]="['/client/sign-in']">Sign in here</a>
      </p>
    </form>
  `,
  styles: [`
    .headline {
      margin-top: 2em;
    }

    .login-form {
      max-width: 400px;
      margin: 0 auto;
      padding: 1em;
    }

    button {
      margin-top: 1.5em;
      margin-bottom: 1em;
    }

    .question {
      text-align: center;
      color: #fff;
    }

    .question a {
      color: #FFC199;
      text-decoration: none;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountRegistrationComponent implements OnInit {

  @Input() errorMsg: string;

  @Output() registerAccount: EventEmitter<Credentials> = new EventEmitter<Credentials>();

  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      displayName: ['', Validators.required],
      email: ['', [ Validators.required, CustomValidators.email]],
      password: ['', [ CustomValidators.password ]]
    });
  }

  submitForm() {
    if (this.form.valid)
      this.registerAccount.emit(this.form.value);
  }
}
