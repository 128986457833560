import { Pipe, PipeTransform } from '@angular/core';
import { FirebaseTimestamp } from '@ezspeek/models';

@Pipe({
  name: 'firebaseTimestamp'
})
export class FirebaseTimestampPipe implements PipeTransform {
  transform(timestamp: FirebaseTimestamp): number {
    return timestamp.seconds * 1000;
  }
}
