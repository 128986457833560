<header [class.sidebar-collapsed]="isAuthorized && isSidebarCollapsed" [class.menu-open]="isMenuOpen">
  <div class="navbar-header">
    <a [routerLink]="['/home']" class="brand">
      <img src="assets/images/ezSpeekLogo2.svg" alt="ezspeek"> <span class="brand-text">ez<span>Speek</span></span>
    </a>
  </div>
  <nav class="navbar">
    <div *ngIf="isAuthorized" class="sidebar-toggle-wrapper">
      <mat-icon class="sidebar-toggle" (click)="toggleSidebar.emit()">menu</mat-icon>
      <mat-icon class="menu-toggle" (click)="toggleMenu.emit()">menu</mat-icon>
    </div>

    <a [routerLink]="['/home']" class="brand">
      <img src="assets/images/ezSpeekLogo2.svg" alt="ezSpeek"> <span class="brand-text">ez<span>Speek</span></span>
    </a>

    <span class="spacer"></span>
    <mat-icon
      *ngIf="showNewReportsIcon"
      routerLink="/admin/reports"
      [queryParams]="{ statusFilter: 'NEW' }"
      class="notification-icon badge-sm badge-color-accent2"
      matBadgeSize="small"
      matBadge="{{displayedNewReportsCount}}">description</mat-icon>
    <mat-icon
      *ngIf="showNewMessageIcon"
      class="notification-icon badge-sm badge-color-alert"
      routerLink="/admin/reports"
      [queryParams]="{ newMsgFilter: true }"
      matBadgeSize="small"
      matBadge="{{displayedNewMessageCount}}">question_answer</mat-icon>
    <mat-icon
      *ngIf="showPendingReportsIcon"
      routerLink="/admin/reports"
      [queryParams]="{ statusFilter: 'PENDING' }"
      class="notification-icon badge-sm badge-color-caution"
      matBadgeSize="small"
      matBadge="{{displayedPendingReportsCount}}">event_note</mat-icon>
  </nav>
</header>
