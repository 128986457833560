
    <ezs-users
      [authUser]="user$ | async"
      [users]="users$ | async"
      [sendEmailStatus]="sendEmailStatus"
      [deleteUserStatus]="deleteUserStatus"
      [features]="features$ | async"
      (addUser)="addUser($event)"
      (deleteUser)="deleteUser($event)"
      (resendEmail)="resendEmail($event)">
    </ezs-users>
  