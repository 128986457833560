export { User as FirebaseAuthUser } from 'firebase/app';

export interface FirebaseTimestamp {
  seconds: number;
  nanoseconds: number;
}

export enum REQUEST_STATUS {
  LOADING,
  SUCCESS,
  ERROR
}
