import { FirebaseAuthUser } from '@ezspeek/models';
import {State, Action, StateContext, Selector, Store} from '@ngxs/store';
import * as AuthActions from '../actions/auth.actions';
import { LoadUser } from '@ezspeek/store/actions/firestore.actions';
import { Navigate } from '@ngxs/router-plugin';
import {AngularFireService} from '@ezspeek/services/angular-fire.service';

export interface AuthStateModel {
  state?: FirebaseAuthUser;
  error?: string;
  loading: boolean;
}

type AuthContext = StateContext<AuthStateModel>;

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loading: false
  }
})
export class AuthState {
  constructor(private af: AngularFireService, private store: Store) {
    this.initialize();
  }

  @Selector()
  static loading(auth: AuthStateModel): boolean {
    return auth.state === undefined || auth.loading;
  }

  @Selector()
  static error(auth: AuthStateModel): string {
    return auth.error;
  }

  @Selector()
  static isAuthenticated(auth: AuthStateModel): boolean {
    return !!auth.state;
  }

  @Selector()
  static userState({ state }: AuthStateModel): FirebaseAuthUser {
    return state;
  }

  @Action(AuthActions.SetAuthState)
  setAuthState({ patchState, dispatch }: AuthContext, { authState }: AuthActions.SetAuthState) {
    patchState({
      state: authState
    });

    if (!!authState) {
        return dispatch(new LoadUser(authState.uid, true));
    }
  }

  @Action([AuthActions.RegistrationError, AuthActions.SignInError])
  setError(ctx: AuthContext, { error }: AuthActions.RegistrationError | AuthActions.SignInError) {
    ctx.patchState({ error, loading: false });
  }

  @Action([AuthActions.RegistrationSuccess, AuthActions.SignInSuccess])
  authenticationSuccess({ patchState, dispatch }: AuthContext) {
    patchState({ error: undefined, loading: false });

    return dispatch(new Navigate(['/dashboard']));
  }

  @Action(AuthActions.SignOut)
  signOut({ dispatch }: AuthContext) {
    return this.af.signOut()
      .then(_ => {
        location.reload(true);
        // return dispatch(new Navigate(['/admin/sign-in']));
      });
  }

  @Action(AuthActions.SignIn)
  signIn({ dispatch, patchState }: AuthContext, { email, password }: AuthActions.SignIn) {
    patchState({ loading: true });
    return this.af.signIn(email, password)
      .then(_ => {
        return dispatch(new AuthActions.SignInSuccess());
      })
      .catch(error => dispatch(new AuthActions.SignInError(error.message || error)));
  }

  @Action(AuthActions.AuthorizeClient)
  authorizeClient({ dispatch }: AuthContext, { accessId }: AuthActions.AuthorizeClient) {
    this.af.authorizeClient(accessId).then(client => {
        return dispatch([new LoadUser(client.uid), new Navigate(['/client', 'dashboard'])]);
      }).catch(error => {
        console.error('ERROR: ', error);
      });
  }

  initialize() {
    // let timestamp = new Date().getTime();
    // let metadataRefreshSub: Subscription = null;

    this.af.authState.subscribe(user => {
      this.store.dispatch(new AuthActions.SetAuthState(user));

      // if (metadataRefreshSub && !metadataRefreshSub.closed)
      //   metadataRefreshSub.unsubscribe();
      //
      // if (user) {
      //   metadataRefreshSub = this.af.getMetaRef(user.uid).valueChanges()
      //     .pipe(map(meta => !!meta ? meta.claimsUpdatedOn : null))
      //     .subscribe(claimsUpdatedOn => {
      //       if (claimsUpdatedOn > timestamp) {
      //         user.getIdToken(true);
      //         timestamp = new Date().getTime();
      //       }
      //     });
      // }
    });
  }
}
