<mat-card *ngIf="isRegistrationComplete" class="thank-you-message fadeInUp animated">
  <mat-card-content>
    <div class="checkmark-wrapper">
      <animated-check [thin]="true" width="150px"></animated-check>
    </div>
    <h1>Thank You!</h1>
    <h2>Your account has been registered. You will receive a welcome email shortly, with your school's Access ID</h2>
    <button
      mat-raised-button
      class="ezs-button button-block button-accent2-light"
      (click)="complete.emit()">
      Go To Dashboard
    </button>
  </mat-card-content>
</mat-card>
<form [formGroup]="form" *ngIf="showForm" class="login-form" (submit)="submitForm($event)">
  <h1 class="headline">
    Get started today! <span class="no-wrap">Risk Free</span>
  </h1>

  <mat-card class="fadeInUp animated">
    <h3 [class.completed]="userComplete" class="form-group-title">
      <mat-icon class="auto-align">{{userComplete ? 'done' : 'person'}}</mat-icon> Account Manager
    </h3>

    <div class="form-field">
      <input
        placeholder="Name" formControlName="displayName">
    </div>

    <div class="form-field">
      <input
        placeholder="Title (optional)" formControlName="title">
    </div>

    <div class="form-field">
      <input
        placeholder="Email" type="email" formControlName="email">
    </div>

    <div class="form-field">
      <input
        placeholder="Password" type="password" formControlName="password">
    </div>

    <h3 [class.completed]="checkoutComplete" class="form-group-title">
      <mat-icon class="auto-align">{{checkoutComplete ? 'done' : 'shopping_cart'}}</mat-icon> Checkout
    </h3>

    <div class="form-field">
      <select formControlName="plan">
        <option class="place-holder" disabled value="">Select a Plan</option>
        <option *ngFor="let plan of plans" [value]="plan.id">
          {{plan.productName}} ({{plan.price | currency}} / {{plan.interval}})
        </option>
      </select>
    </div>

    <div [class.disabled]="processing" [class.invalid]="stripeCardError" class="form-field stripe-card-element-wrapper">
      <ng-content></ng-content>
    </div>

    <div class="form-field with-button" *ngIf="!appliedCoupon">
      <input placeholder="Promo Code" formControlName="coupon">
      <button mat-flat-button class="ezs-button button-success" (click)="applyCoupon()" [disabled]="coupon.value === '' || processing">Apply</button>
    </div>

    <div *ngIf="!!appliedCoupon">
      <p class="applied-coupon">
        <span><b>{{appliedCoupon.id}}</b> promo code applied</span>
        <mat-icon (click)="removeCoupon.emit()" *ngIf="!processing">delete_forever</mat-icon>
      </p>
    </div>

    <p class="error-msg" *ngIf="stripeCardError">{{stripeCardError}}</p>
    <p class="error-msg" *ngIf="requestError">{{requestError}}</p>
    <button mat-raised-button class="ezs-button button-block button-lg button-success" [disabled]="submitDisabled">
      <img *ngIf="processing" class="loading-icon" src="assets/images/bars.svg"> {{submitButtonText}}
    </button>
    <p class="question">
      Already have an account?
      <a [routerLink]="['/admin/sign-in']">Sign in</a>
    </p>
    <p class="question">
      Need to <a [routerLink]="['/client/sign-in']">report an incident</a>?
    </p>
  </mat-card>

</form>
