import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { AppMaterialModule } from './app-material.module';
import { EzsAngularFireModule } from './ezs-angular-fire.module';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import { ROOT_STATE } from './store/state/app.state';
import { HeaderComponent } from './header/header.component';
import { AppContainer } from './app.container';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUpContainer } from './sign-up/sign-up.container';
import { AdminModule } from './admin/admin.module';
import { ClientModule } from './client/client.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeContainer } from './home/home.container';
import { ToasterModule } from 'angular2-toaster';
import { EmergencyAlertComponent } from '@ezspeek/common/components/emergency-alert.component';
import { AppCommonModule } from '@ezspeek/common/app-common.module';
import { NgxStripeModule } from '../stripe/stripe.module';
import { environment } from '../environments/environment';

const developmentMode: boolean = !environment.production;

@NgModule({
  declarations: [
    HomeContainer,
    HomeComponent,
    HeaderComponent,
    AppContainer,
    SignUpComponent,
    SignUpContainer,
    EmergencyAlertComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    EzsAngularFireModule,
    NgxsModule.forRoot([...ROOT_STATE]),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ToasterModule.forRoot(),
    AppMaterialModule,
    AdminModule,
    ClientModule,
    AppCommonModule,
    NgxStripeModule.forRoot(environment.stripe.publicKey)
  ],
  bootstrap: [ AppContainer ]
})
export class AppModule { }
