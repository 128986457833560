import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlatformService } from '@ezspeek/services/platform.service';
import { StripeJS, StripeOptions } from '../models';
import { first, map } from 'rxjs/operators';

export interface StripeJSScriptStatus {
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

@Injectable()
export class StripeJSLoader {
  private status: BehaviorSubject<StripeJSScriptStatus> = new BehaviorSubject<StripeJSScriptStatus>({
    error: false,
    loaded: false,
    loading: false
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private platform: PlatformService
  ) {}

  public asStream(): Observable<StripeJSScriptStatus> {
    this.load();
    return this.status.asObservable();
  }

  public load() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const status: StripeJSScriptStatus = this.status.getValue();
    if (this._window.hasOwnProperty('Stripe')) {
      this.status.next({
        error: false,
        loaded: true,
        loading: false
      });
    } else if (!status.loaded && !status.loading) {
      this.status.next({
        ...status,
        loading: true
      });

      const script = this._document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = 'https://js.stripe.com/v3/';

      script.onload = () => {
        this.status.next({
          error: false,
          loaded: true,
          loading: false
        });
      };

      script.onerror = () => {
        this.status.next({
          error: true,
          loaded: false,
          loading: false
        });
      };

      this._document.body.appendChild(script);
    }
  }

  getStripeJS(key: string, options?: StripeOptions): Promise<StripeJS> {
    this.load();
    return this.Stripe.pipe(
      map(Stripe => {
        const stripe = !!options ? Stripe(key, options) : Stripe(key);
        return stripe as StripeJS;
      })
    ).toPromise();
  }

  get Stripe(): Observable<any> {
    return this._waitForLoaded().pipe(
      map(_ => (this._window as any).Stripe)
    );
  }

  get currentStatus(): StripeJSScriptStatus { return this.status.getValue(); }
  get loaded(): boolean { return this.currentStatus.loaded; }
  get loading(): boolean { return this.currentStatus.loading; }
  get error(): boolean { return this.currentStatus.error; }

  private _waitForLoaded(): Observable<boolean> {
    return this.status.asObservable().pipe(
      first((status: StripeJSScriptStatus) => status.loaded),
      map(s => s.loaded)
    );
  }

  private get _window(): Window { return this.platform.window; }
  private get _document(): Document { return this.platform.document; }
}
