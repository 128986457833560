
    <div class="client-sign-in-container">
      <h3 class="headline">Enter your school <span>access ID</span></h3>
      <form [formGroup]="form" *ngIf="form" class="login-form">
        <div class="form-field">
          <input placeholder="Access ID" formControlName="accessId">
        </div>
        <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
        <button mat-raised-button color="primary" class="button button-block" [disabled]="!form.valid" (click)="submitForm()">
          Sign In
        </button>
        <p class="question">
          Are you an Admin?
          <a [routerLink]="['/sign-in']">Sign in here</a>
        </p>
      </form>
    </div>
  