import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { isEmptyString, runDiscreteValidation } from '@ezspeek/common/helpers';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

type WithOtherControlValidatorFn = (getOtherControlFn: () => AbstractControl) => ValidatorFn;

export const PASSWORD_VALIDATORS = [
  Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
  Validators.minLength(6),
  Validators.maxLength(25),
  Validators.required
];

const REGEX = {
  email: new RegExp('^[A-Za-z0-9\\-]([a-zA-Z0-9_\\-\\+\\.]{0,})@((([0-9]{1,3}\\.){3})|([A-Za-z0-9]+)(([\\.\\-_]?[a-zA-Z0-9]+)*))\\.([0-9]{1,3}|([A-Za-z]{2,}))$'),
  password: new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')
};

export class CustomValidators {
  static email(c: AbstractControl): { [key: string]: any } {
    if (!isEmptyString(c.value)) {
      if (c.value.match(REGEX.email)) {
        return null;
      } else {
        return { invalidEmail: true };
      }
    }
    return null;
  }

  static password(c: AbstractControl) {
    return Validators.compose(PASSWORD_VALIDATORS)(c);
  }

  static repeatPassword(getPasswordControlFn: () => AbstractControl) {
    return (c: AbstractControl) => {
      const passwordControl = getPasswordControlFn();

      return Validators.compose([
        Validators.required,
        (ctrl: AbstractControl) => {
          if (!!passwordControl && (!!passwordControl.value || !!ctrl.value)) {
            if (passwordControl.value !== ctrl.value)
              return { passwordsMustMatch: true };
          }

          return null;
        }
      ])(c);
    };
  }

  static executeFnOnValueChange(subscribeFn: (value: any) => void, executeOnce: boolean = false): ValidatorFn {
    let subscription: Subscription = null;

    return (c: AbstractControl) => {
      if (!c.valueChanges) return null;

      if (subscription === null) {
        if (executeOnce) {
          subscription = c.valueChanges.pipe(take(1)).subscribe(subscribeFn);
        } else {
          subscription = c.valueChanges.subscribe(subscribeFn);
        }
      }

      return null;
    };
  }
}



export function updateWithOtherControlValidator(getOtherControlFn: () => AbstractControl, validator: WithOtherControlValidatorFn) {
  return Validators.compose([
    validator(getOtherControlFn),
    CustomValidators.executeFnOnValueChange(() => {
      const otherControl: AbstractControl = getOtherControlFn();
      runDiscreteValidation(otherControl);
    })
  ]);
}
