import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AngularFireService} from '@ezspeek/services/angular-fire.service';
import {Observable} from 'rxjs/observable';
import {PlanModel} from '@ezspeek/models/stripe.models';
import {ShowBodyBackground} from '@ezspeek/store/actions/app.actions';
import {AppState} from '@ezspeek/store/state';
import {ContactUsMessage} from '@ezspeek/models/common.models';
import {REQUEST_STATUS, UserModel} from '@ezspeek/models';
import Timeout = NodeJS.Timeout;

@Component({
  template: `
    <ezs-header></ezs-header>
    <ezs-home 
      [displayedPhrase]="displayedPhrase"
      [user]="user$ | async"
      [contactUsStatus]="contactUsRequestStatus"
      [contactUsErrorMsg]="contactUsErrorMsg"
      [plans]="plans$ | async" 
      (submitMessage)="sendContactUsMessage($event)">
    </ezs-home>
  `
})
export class HomeContainer implements OnInit, OnDestroy {

  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;
  plans$: Observable<PlanModel[]>;

  readonly phrases: string[] = [ 'Your Students', 'Your Schools', 'Your Community'];

  private _displayedPhrase = '';
  private _currentPhraseIndex = 0;

  private _headingPhraseInterval: Timeout;

  private _contactUsRequestStatus: REQUEST_STATUS;
  private _contactUsErrorMsg: string;

  constructor(private store: Store, private af: AngularFireService) {
    this.store.dispatch(new ShowBodyBackground());
  }

  ngOnInit() {
    this.plans$ = this.af.getPlansCollectionRef().valueChanges();
    this.typePhrase().then(() => this.typeNextPhrase());
  }

  typeNextPhrase() {
    return this.typePhrase().then(() => this.typeNextPhrase());
  }

  typePhrase() {
    const currentPhrase = this.currentPhrase;
    return new Promise((res) => {
      this._headingPhraseInterval = setInterval(() => {
        this.displayedPhrase += currentPhrase[this.displayedPhrase.length];

        if (this.displayedPhrase === currentPhrase) {
          clearInterval(this._headingPhraseInterval);
          setTimeout(res, 2000);
        }
      }, 40);
    }).then(() => this.deletePhrase());
  }

  deletePhrase() {
    return new Promise(res => {
      const intervalId = setInterval(() => {
        this.displayedPhrase = this.displayedPhrase.slice(0, -1);

        if (this.displayedPhrase === '') {
          clearInterval(intervalId);
          this.loadNextPhrase();
          setTimeout(res, 500);
          // res();
        }
      }, 40);
    });
  }

  sendContactUsMessage(msg: ContactUsMessage) {
    this._contactUsErrorMsg = null;
    this._contactUsRequestStatus = REQUEST_STATUS.LOADING;

    this.af.sendContactUsMessage(msg)
      .then(res => {
        this._contactUsRequestStatus = REQUEST_STATUS.SUCCESS;
      }).catch(err => {
        this._contactUsRequestStatus = REQUEST_STATUS.ERROR;
        this._contactUsErrorMsg = err.message || err;
    });
  }

  private loadNextPhrase() {
    if (this._currentPhraseIndex === this.phrases.length - 1) {
      this._currentPhraseIndex = 0;
    } else {
      this._currentPhraseIndex++;
    }
  }

  set displayedPhrase(phrase) {
    this._displayedPhrase = phrase;
  }

  get displayedPhrase(): string {
    return this._displayedPhrase;
  }

  get currentPhrase(): string { return this.phrases[this._currentPhraseIndex]; }

  get contactUsRequestStatus(): REQUEST_STATUS { return this._contactUsRequestStatus; }
  get contactUsErrorMsg(): string { return this._contactUsErrorMsg; }

  ngOnDestroy() {
    clearInterval(this._headingPhraseInterval);
  }
}
