import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChartService {
  private _chartRegistry: string[] = [];

  registerChart(chartId: string) {
    if (this._chartRegistry.indexOf(chartId) >= 0)
      throw new Error(`Duplicate Chart ID: Chart id [${chartId}] already exists.`);
    else
      this._chartRegistry.push(chartId);
  }

  unregisterChart(chartId: string) {
    const index = this._chartRegistry.indexOf(chartId);

    if (index < 0)
      return;

    this._chartRegistry.splice(index, 1);
  }
}
