import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState, AuthState } from '@ezspeek/store/state';
import { FirebaseAuthUser, REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { Navigate } from '@ngxs/router-plugin';
import { LoadUser } from '@ezspeek/store/actions/firestore.actions';

@Component({
  template: `
    <ezs-continue-registration
      [user]="user$ | async"
      [requestStatus]="requestStatus"
      [requestError]="requestError"
      [loading]="reAuthenticating"
      [reAuthRequestStatus]=""
      [reAuthRequired]="reAuthRequired"
      (submit)="completeRegistration($event)" 
      (reAuthenticate)="sendReAuthEmail($event)">
    </ezs-continue-registration>
  `
})
export class ContinueRegistrationContainer {

  @Select(AuthState.userState) user$: FirebaseAuthUser;

  private _aid: string;
  private _emailLink: string;
  private _requestStatus: REQUEST_STATUS;
  private _requestError: string;
  private _reAuthenticating: boolean;
  private _reAuthRequestStatus: REQUEST_STATUS;
  private _reAuthRequired: boolean;

  constructor(private store: Store, private af: AngularFireService, private route: ActivatedRoute, private router: Router) {
    this._emailLink = router.url;
    if (af.verifyEmailLink(this.emailLink)) {
      this._reAuthenticating = true;

      af.reAuthenticateWithEmailLink(this.emailLink)
        .then(_ => {
          this._reAuthenticating = false;
        })
        .catch(err => {
          this._reAuthenticating = false;
        });
    }
  }

  completeRegistration({ email, password, displayName }) {
    this._requestError = null;
    this._requestStatus = REQUEST_STATUS.LOADING;

    this.af.completeNewAdminRegistration(email, password, displayName)
      .then(res => {
        const { uid } = this.store.selectSnapshot(AppState.authenticatedUser);

        this.store.dispatch(new LoadUser(uid)).toPromise()
          .then(_ => {
            this._requestStatus = REQUEST_STATUS.SUCCESS;
            this.store.dispatch(new Navigate(['/admin', 'dashboard']));
          });
      })
      .catch(err => {
        this._requestStatus = REQUEST_STATUS.ERROR;

        if (err.code === 'auth/requires-recent-login')
          this._reAuthRequired = true;

        this._requestError = err.message || err;
      });
  }

  sendReAuthEmail(email: string) {
    this._reAuthRequestStatus = REQUEST_STATUS.LOADING;

    this.af.sendSignInLinkForContinueRegistration(email)
      .then(_ => {
        this._reAuthRequestStatus = REQUEST_STATUS.SUCCESS;
      })
      .catch(err => {
        this._reAuthRequestStatus = REQUEST_STATUS.ERROR;
      });
  }

  get aid(): string { return this._aid; }
  get emailLink(): string { return this._emailLink; }
  get requestStatus(): REQUEST_STATUS { return this._requestStatus; }
  get requestError(): string { return this._requestError; }
  get reAuthenticating(): boolean { return this._reAuthenticating; }
  get reAuthRequestStatus(): REQUEST_STATUS { return this._reAuthRequestStatus; }
  get reAuthRequired(): boolean { return this._reAuthRequired; }
}
