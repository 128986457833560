<ezs-loading style="opacity: .4" [absoluteCenter]="true" [dark]="true" *ngIf="!features"></ezs-loading>
<div class="feature-unavailable-message fadeInUp animated" *ngIf="showAlertsUnavailableMessage">
  <h2>
    {{alertsUnavailableMessage}}
  </h2>
  <button
    mat-raised-button
    color="primary"
    class="button button-accent2"
    [routerLink]="['/admin', 'dashboard']">Go To Dashboard</button>
</div>
<mat-card *ngIf="!!features && !showAlertsUnavailableMessage" class="fadeInUp animated">
  <mat-card-title *ngIf="!isSelectionMode">
    <mat-icon
      (click)="switchMode('select')"
      class="fadeIn animated primary-color">arrow_back</mat-icon>
  </mat-card-title>
  <mat-card-content
    *ngIf="isSelectionMode"
    class="fadeIn animated selection-mode">
    <h2 *ngIf="alertsEnabled" class="mode-heading">Which type of alert would <span>you like to create?</span></h2>
    <button class="ezs-button button-block button-accent2" [disabled]="!notificationsEnabled" mat-raised-button (click)="switchMode('notification')">
      <mat-icon>tap_and_play</mat-icon> Create Notification Alert
    </button>
    <p *ngIf="alertsEnabled && !notificationsEnabled">*Notification Alerts are not enabled on your current plan</p>
    <button class="ezs-button button-block" [disabled]="!emergencyAlertsEnabled" color="warn" mat-raised-button (click)="switchMode('emergency')">
      <mat-icon>notification_important</mat-icon> Create Emergency Alert
    </button>
    <p *ngIf="alertsEnabled && !emergencyAlertsEnabled">*Emergency Alerts are not enabled on your current plan</p>
  </mat-card-content>
  <mat-card-content *ngIf="isNotificationMode" class="fadeInLeft animated notification-mode">
    <h2 class="mode-heading">Select options to create a <span>Notification Alert</span></h2>
    <div class="notification-form-container">
      <h4>Notification Type(Color)</h4>
      <mat-chip-list class="ezs-chip-list">
        <mat-chip [selected]="style === 'primary'" (click)="selectStyle('primary')" class="mat-chip-primary">Default</mat-chip>
        <mat-chip [selected]="style === 'info'" (click)="selectStyle('info')" class="mat-chip-accent2-light">Info</mat-chip>
        <mat-chip [selected]="style === 'success'" (click)="selectStyle('success')" class="mat-chip-success">Success</mat-chip>
        <mat-chip [selected]="style === 'wait'" (click)="selectStyle('wait')" class="mat-chip-caution">Caution</mat-chip>
        <mat-chip [selected]="style === 'warning'" (click)="selectStyle('warning')" class="mat-chip-alert">Alert</mat-chip>
        <mat-chip [selected]="style === 'error'" (click)="selectStyle('error')" class="mat-chip-warn">Warning</mat-chip>
        <mat-chip [selected]="style === 'mute'" (click)="selectStyle('mute')">Mute</mat-chip>
      </mat-chip-list>
      <mat-slide-toggle color="primary"
                        [checked]="showClose"
                        (change)="toggleShowClose()">
        <span>Show close button</span>
      </mat-slide-toggle>
      <form *ngIf="notificationForm" [formGroup]="notificationForm" class="notification-form ezs-form">
        <div class="form-field">
          <select formControlName="sendTo">
            <option disabled selected value="">Who should receive this alert?</option>
            <option value="admin">Admins</option>
            <option value="client">Students</option>
            <option value="all">All</option>
          </select>
        </div>
        <div class="form-field-group">
          <input formControlName="duration" type="number" min="0" placeholder="Duration (seconds)">
          <input formControlName="expiration" type="number" min="1" max="30" placeholder="Expiration (1 - 30 days)">
        </div>
        <div class="form-field">
          <input formControlName="title" placeholder="Title(Optional)">
        </div>
        <div class="form-field">
          <textarea autofitContent formControlName="content" placeholder="Notification message..."></textarea>
        </div>
      </form>
      <div class="action-buttons">
        <button (click)="preview()" [disabled]="!notificationForm.valid" mat-button class="ezs-button">Preview Alert</button>
        <button color="primary" (click)="send()" [disabled]="!notificationForm.valid" mat-raised-button class="ezs-button">Send Alert</button>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="isEmergencyAlertMode" class="fadeInLeft animated emergency-alert-mode">
    <h2 class="mode-heading color-warn">Enter a message to activate the <span>Emergency Alert</span></h2>
    <div class="emergency-alert-form-container">
      <form *ngIf="emergencyAlertForm" class="emergency-alert-form ezs-form" [formGroup]="emergencyAlertForm">
        <div class="form-field">
          <textarea formControlName="message" autofitContent placeholder="Type alert message..."></textarea>
        </div>
        <button color="warn" (click)="createEmergencyAlert()" [disabled]="activatingEmergencyAlert || !emergencyAlertForm.valid" mat-raised-button class="button">
          <mat-icon>priority_high</mat-icon>Activate Emergency Alert</button>
      </form>
    </div>
  </mat-card-content>
</mat-card>
