import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AUTH_ROLE, REQUEST_STATUS, UserModel} from '@ezspeek/models';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductFeatures} from '@ezspeek/models/stripe.models';

enum ViewState {
  LIST,
  ADD
}

@Component({
  selector: 'ezs-users',
  template: `
    <mat-card [class.add-user-container]="isAddingUser" class="fadeInUp animated">
      <mat-card-content *ngIf="!isAddingUser">
        <div *ngIf="isAccountMgr" class="action-button-wrapper">
          <div class="flex-spacer"></div>
          <button 
            mat-button color="primary" *ngIf="!isDeletingUser" [disabled]="!canAddUser || isDeletingUser" (click)="isAddingUser = true">
            <mat-icon>person_add</mat-icon> Add User
          </button>
          <div *ngIf="isDeletingUser" class="loading-msg">
            <img src="assets/images/bars_primary-dark.svg" alt=""><span>Removing user...</span>
          </div>
        </div>
        <div *ngIf="hasUsers" class="users-list fadeIn animated">
          <ezs-user-row 
            *ngFor="let user of activeUsers; let i = index"
            [enableManagement]="isAccountMgr"
            [hideBorder]="!isAccountMgr && i === 0"
            [user]="user" 
            [disableOptions]="isDeletingUser"
            (deleteUser)="deleteUser.emit($event)" 
            (resendEmail)="resendEmail.emit($event)">
          </ezs-user-row>
        </div>
        <div *ngIf="!hasUsers" class="no-users-message fadeIn animated">
          <h4>No other users to manage</h4>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="isAccountMgr && isAddingUser">
        <div class="action-button-wrapper">
          <button
            class="fadeInLeft animated"
            mat-button color="primary" (click)="isAddingUser = false">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <div class="flex-spacer"></div>
        </div>
        <form [formGroup]="form" class="add-user-form elevation-3 fadeIn animated">
          <p>Invite a user to be added to this account. Once added, the user will have access to reports and statistics</p>
          <div class="form-field">
            <input type="email" formControlName="email" placeholder="Enter User's email">
          </div>
          <button (click)="sendEmail()" [disabled]="!canAddUser || isSendingEmail || !form.valid" mat-raised-button class="button">
            {{sendButtonText}}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./users.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit {

  isAddingUser = false;

  form: FormGroup;

  @Input() authUser: UserModel;
  @Input() users: UserModel[];
  @Input() features: ProductFeatures;
  @Input() deleteUserStatus: REQUEST_STATUS;
  @Input() set sendEmailStatus(status: REQUEST_STATUS) {
    this._sendEmailStatus = status;
    if (status === REQUEST_STATUS.SUCCESS) {
      this.buildForm();
      this.isAddingUser = false;
    }
  }

  @Output() addUser: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteUser: EventEmitter<string> = new EventEmitter<string>();
  @Output() resendEmail: EventEmitter<string> = new EventEmitter<string>();

  private _sendEmailStatus: REQUEST_STATUS;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  sendEmail() {
    this.addUser.emit(this.email.value);
  }

  get hasUsers(): boolean {
    return !!this.users && this.activeUsers.length > 0;
  }

  get activeUsers(): Array<UserModel> {
    return this.users.filter(user => !user.deleted);
  }

  get isAccountMgr(): boolean { return !!this.authUser && this.authUser.role === AUTH_ROLE.ACCOUNT_MGR; }

  get canAddUser(): boolean { return !!this.features && this.users.length < this.features.adminAccounts; }

  get email(): AbstractControl { return !!this.form ? this.form.get('email') : null; }

  get isSendingEmail(): boolean { return this._sendEmailStatus === REQUEST_STATUS.LOADING; }

  get sendButtonText(): string { return this.isSendingEmail ? 'Sending...' : 'Send Email'; }

  get isDeletingUser(): boolean { return this.deleteUserStatus === REQUEST_STATUS.LOADING; }
}
