import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartService } from '@ezspeek/services/chart.service';
import { ChartConfig, ChartData, ChartType } from '@ezspeek/models/chart.models';

@Component({
  selector: 'ezs-chart',
  template: `
    <canvas #chart></canvas>
  `,
  styles: [
    `
      :host {
        position: relative;
        width: 100%;
        max-width: 600px;
        margin: auto;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, OnDestroy, AfterViewInit {

  // @Input() chartId: string;
  @Input() type: ChartType = 'bar';
  @Input() set data(data: ChartData) {
    if (!!this.chart && !!data && this._checkIsUpdateRequired(data)) {
      this.chart.data = data;
      this.chart.update();
      this._chartData = data;
    }
  }
  @Input() config: ChartConfig;

  @ViewChild('chart') chartCanvas;

  chart: Chart;

  private _chartOptions;
  private _chartData: ChartData;

  constructor(private service: ChartService) {}

  ngOnInit() {
    // this.service.registerChart(this.chartId);
  }

  ngAfterViewInit() {
    if (!!this.chartCanvas) {
      this._renderChart();
    }
  }

  ngOnDestroy() {
    // this.service.unregisterChart(this.chartId);
  }

  private _checkIsUpdateRequired(data: ChartData) {
    if (!this._chartData)
      return true;

    return JSON.stringify(data.datasets[0].data) !== JSON.stringify(this._chartData.datasets[0].data);
  }

  private _renderChart() {
    this.chart = new Chart(this._ctx, this.config);
  }

  private get _ctx(): HTMLCanvasElement {
    return this.chartCanvas.nativeElement;
  }
}
