import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[autofitContent]'
})
export class AutofitContentDirective implements AfterViewInit {
  _initialHeight: number;
  _curHeight: number;

  constructor(private elRef: ElementRef) {
  }

  ngAfterViewInit() {
    this._initialHeight = this.height;
    this._curHeight = this.height;
  }

  @HostListener('input', ['$event'])
  resize({ inputType }) {
    if (inputType && inputType.includes('delete')) {
        this.shrinkToFit();
    } else {
      this.growToFit();
    }
  }

  private shrinkToFit() {
    if (this.scrollHeight !== this._initialHeight) {
      this.element.style.height = this._initialHeight + 'px';
      this.updateHeight();
    }
  }

  private growToFit() {
    if (this.scrollHeight !== this.height) {
      this.element.style.height = 'auto';
      this.updateHeight();
    }
  }

  private updateHeight() {
    this.element.style.height = this.scrollHeight + 'px';
  }

  get element(): HTMLElement {
    return this.elRef.nativeElement;
  }

  get height() { return this.element.clientHeight; }

  get scrollHeight() { return this.element.scrollHeight; }
}
