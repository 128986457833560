import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[animateInView]'
})
export class AnimateInViewDirective {
  private _animated: boolean;

  @Input() animationClasses: string[] = ['animate'];

  // used to modify the position at which the element is considered "in view" to trigger animation
  @Input() offset = 0;

  @Input() checkAnimateOnInit = true;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    // if (this.checkAnimateOnInit)
    //   this._handleScroll();
  }

  @HostListener('window:scroll')
  private _handleScroll() {
    if (this._animated) return;

    if (this.isScrolledIntoView) {
      this._animate();
    }
  }

  private _animate() {
    this._animated = true;

    this.animationClasses.forEach(className => {
      this._renderer.addClass(this.el, className);
    });
  }

  get el(): HTMLElement {
    return this._elRef.nativeElement;
  }

  get clientRect(): ClientRect {
    return this.el.getBoundingClientRect();
  }

  get isScrolledIntoView(): boolean {
    const viewport = window.innerHeight || document.documentElement.clientHeight;
    const { top, height } = this.clientRect;

    return top >= 0 && (top + (height * this.offset)) <= viewport;
  }
}
