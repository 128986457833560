
    <ezs-header [user]="user$ | async"></ezs-header>
    <ezs-sign-up
      [errorMsg]="authError$ | async"
      [requestStatus]="signUpRequestStatus"
      [requestError]="signUpRequestError"
      [stripeCardError]="stripeCardError"
      [stripeCardComplete]="stripeCardComplete"
      [plans]="plans$ | async"
      [selectedPlanId]="selectedPlanId$ | async"
      [appliedCoupon]="appliedCoupon"
      (validateCoupon)="validateCoupon($event)"
      (removeCoupon)="removeCoupon()"
      (signUp)="signUp($event)"
      (complete)="complete()">
      <div #cardForm></div>
    </ezs-sign-up>    
    <div
      ezsRecaptcha
      [style.display]="'none'"
      size="invisible"
      (rendered)="recaptchaRendered($event)">
    </div>
  