import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SetAdminPageTitle } from '@ezspeek/store/actions/app.actions';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { EmergencyAlertModel, NotificationModel } from '@ezspeek/models/common.models';
import { AppState, FirestoreState } from '@ezspeek/store/state';
import { Observable } from 'rxjs';
import { AccountModel, REQUEST_STATUS, UserModel } from '@ezspeek/models';
import { ToasterService } from 'angular2-toaster';
import { ProductFeatures } from '@ezspeek/models/stripe.models';

@Component({
  template: `
    <ezs-alerts
      [account]="account$ | async"
      [user]="user$ | async"
      [features]="features$ | async"
      [emergencyAlertRequestStatus]="emergencyAlertRequestStatus"
      [notificationRequestStatus]="notificationRequestStatus"
      (sendNotification)="sendNotification($event)"
      (previewNotification)="previewNotification($event)"
      (activateEmergencyAlert)="activateEmergencyAlert($event)">
    </ezs-alerts>
  `,
})
export class AlertsContainer implements OnInit {
  @Select(AppState.authenticatedUser) user$: Observable<UserModel>;
  @Select(FirestoreState.account) account$: Observable<AccountModel>;
  @Select(AppState.accountFeatures) features$: Observable<ProductFeatures>;

  private _emergencyAlertRequestStatus: REQUEST_STATUS;
  private _notificationRequestStatus: REQUEST_STATUS;

  constructor(
    private store: Store,
    private af: AngularFireService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.store.dispatch(new SetAdminPageTitle('Alerts', 'Create, send, and manage alerts and notifications'));
  }

  activateEmergencyAlert(alert: EmergencyAlertModel) {
    this._emergencyAlertRequestStatus = REQUEST_STATUS.LOADING;

    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);

    this.af.activateEmergencyAlert(aid, alert)
      .then(_ => {
        this._emergencyAlertRequestStatus = REQUEST_STATUS.SUCCESS;
      })
      .catch(err => {
        this._emergencyAlertRequestStatus = REQUEST_STATUS.ERROR;
        this.toaster.pop('error', '', 'Failed to activate Emergency Alert');
      });
  }

  sendNotification(notification: NotificationModel) {
    this._notificationRequestStatus = REQUEST_STATUS.LOADING;

    const { aid } = this.store.selectSnapshot(AppState.authenticatedUser);

    this.af.sendNotificationAlert(aid, notification)
      .then(_ => {
        this._notificationRequestStatus = REQUEST_STATUS.SUCCESS;
      })
      .catch(err => {
        this._notificationRequestStatus = REQUEST_STATUS.ERROR;
        this.toaster.pop('error', '', err.message || err);
      });
  }

  previewNotification(notification: NotificationModel) {
    this.toaster.pop(notification);
  }

  get emergencyAlertRequestStatus(): REQUEST_STATUS {
    return this._emergencyAlertRequestStatus;
  }
  get notificationRequestStatus(): REQUEST_STATUS {
    return this._notificationRequestStatus;
  }
}
