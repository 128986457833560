import { Component } from '@angular/core';
import { AuthState } from '@ezspeek/store/state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { AuthorizeClient } from '@ezspeek/store/actions/auth.actions';

@Component({
  template: `
    <client-sign-in 
      [errorMsg]="authError$ | async"
      (signIn)="signIn($event)">
    </client-sign-in>
  `
})
export class ClientSignInContainer {

  @Select(AuthState.error) authError$: Observable<any>;

  constructor(private store: Store) {}

  signIn(accessId) {
    this.store.dispatch(new AuthorizeClient(accessId));
  }
}
