
    <div [class.hide-border]="hideBorder" class="user-row-wrapper">
      <div class="profile-photo">
        <img [src]="profilePictureUrl" alt="{{user.displayName}}">
      </div>
      <div class="user-details">
        <span class="display-name" *ngIf="user.displayName">{{user.displayName}}</span>
        <a href="mailto:{{user.email}}">{{user.email}}</a>
        <span *ngIf="!registrationComplete" class="registration-status"><mat-icon>error_outline</mat-icon>Registration not completed</span>
      </div>
      <div class="flex-spacer"></div>
      <div *ngIf="enableManagement">
        <mat-icon class="user-options-icon" [matMenuTriggerFor]="userOptions">more_vert</mat-icon>
        <mat-menu #userOptions="matMenu">
          <button [disabled]="disableOptions" mat-menu-item *ngIf="!registrationComplete" (click)="resendRegistrationEmail()">
            <mat-icon>refresh</mat-icon>Resend registration email
          </button>
          <!--<button mat-menu-item *ngIf="registrationComplete"><mat-icon>edit</mat-icon>Edit user</button>-->
          <button [disabled]="disableOptions" mat-menu-item (click)="remove()">
            <mat-icon>delete</mat-icon>Remove user
          </button>
        </mat-menu>
      </div>
    </div>
  