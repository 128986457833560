import { InjectionToken } from '@angular/core';

import { StripeElement, StripeElements, StripeElementsOptions } from './elements';
import {
  BankAccount, BankAccountData, CardDataOptions, Pii, PiiData, StripeTokenResult
} from './token';
import { StripeSourceData, StripeSourceParams, StripeSourceResult } from './source';
import { PaymentRequestOptions } from './payment-request';

export const STRIPE_PUBLISHABLE_KEY = new InjectionToken<string>('Stripe Publishable Key');
export const STRIPE_OPTIONS = new InjectionToken<StripeOptions>('Stripe StripeOptions');

export interface StripeOptions {
  stripeAccount?: string;
}

export interface StripeJS {
  elements(options?: StripeElementsOptions): StripeElements;
  createToken(el: StripeElement, cardData?: CardDataOptions): Promise<StripeTokenResult>;
  createToken(
    account: BankAccount,
    bankAccountData: BankAccountData
  ): Promise<StripeTokenResult>;
  createToken(pii: Pii, piiData: PiiData): Promise<StripeTokenResult>;
  createSource(el: StripeElement, sourceData?: StripeSourceData): Promise<StripeSourceResult>;
  createSource(sourceData: StripeSourceData): Promise<StripeSourceResult>;
  retrieveSource(source: StripeSourceParams): Promise<StripeSourceResult>;
  paymentRequest(options: PaymentRequestOptions): any;
}

export interface Address {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}

export interface StripeError {
  type:
    | 'api_connection_error'
    | 'api_error'
    | 'authentication_error'
    | 'card_error'
    | 'invalid_request_error'
    | 'rate_limit_error';
  charge: string;
  message?: string;
  code?: string;
  decline_code: string;
  param?: string;
}

export interface Coupon {
  id?: string;
  object?: string;
  amount_off?: number;
  created?: number;
  currency?: string;
  duration: string;
  duration_in_months?: number;
  livemode?: boolean;
  max_redemptions?: number;
  metadata?: Object;
  name?: string;
  percent_off?: number;
  redeem_by?: number;
  times_redeemed?: number;
  valid?: boolean;
}
