import { Component } from '@angular/core';

@Component({
  selector: 'ezs-account-settings',
  template: `
    SETTINGS
  `,
})
export class AccountSettingsComponent {
  constructor() {}
}
