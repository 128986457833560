
    <div class="registration-container">
      <form [formGroup]="form" class="fadeInUp animated" *ngIf="!user && !!form">
        <h2>To register, we need to verify your email address</h2>
        <div class="form-field">
          <input placeholder="Enter your email" type="email" formControlName="email">
        </div>
        <p class="error-msg" *ngIf="requestError">{{requestError}}</p>
        <button
          class="button button-block button-lg"
          [disabled]="!form.valid || isRegistering"
          (click)="submitEmail()">
          <img *ngIf="isRegistering" class="loading-icon" src="assets/images/bars.svg"> {{registerButtonText}}
        </button>
      </form>
    </div>
  