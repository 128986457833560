import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {AccountModel, UserModel} from '@ezspeek/models';
import { Constants } from '@ezspeek/common';

interface SidebarLink {
  route: string;
  icon: string;
  text: string;
  disabled: boolean;
}

const buildLink = (text: string, icon: string, disabled: boolean, route?: string): SidebarLink => {
  return { text, icon, disabled, route: route || text.toLowerCase() };
};

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSidebarComponent {

  @Input() isCollapsed = true;
  @Input() isMenuOpen = false;
  @Input() user: UserModel;
  @Input() account: AccountModel;
  @Input() isProfileModalOpen: boolean;

  @Output() signOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleProfileModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  get links(): SidebarLink[] {
    return [
      buildLink('Dashboard', 'dashboard', false),
      buildLink('Reports', 'description', this.accountInactive),
      buildLink('Users', 'supervised_user_circle', this.accountInactive),
      buildLink('Alerts', 'notifications', this.accountInactive),
      buildLink('Statistics', 'poll', true, 'stats')
    ];
  }

  closeMenu() {
    if (this.isMenuOpen)
      this.toggleMenu.emit();
  }

  get isAuthorized(): boolean { return !!this.user && !!this.user.displayName && !!this.user.email; }

  get profilePictureUrl(): string {
    return this.user.photoURL || Constants.defaultProfilePictureUrl;
  }

  get accountInactive(): boolean {
    return !!this.account && this.account.status === 'inactive';
  }
}
