
    <div class="client-content-wrapper">
      <ezs-reports-list
        *ngIf="!viewingReport"
        [reports]="reports$ | async"
        [user]="authenticatedUser$ | async"
        [showArchived]="false"
        [applyNewMessageFilter]="false"
        [statusFilters]="statusFilters">
      </ezs-reports-list>
      <ezs-report-details
        *ngIf="viewingReport"
        [user]="authenticatedUser$ | async"
        [report]="selectedReport$ | async"
        [sendChatMessageStatus]="sendChatMessageStatus"
        [sendChatMessageError]="sendChatMessageError"
        [chatMessages]="chatMessages$ | async"
        (updateReport)="updateReport($event)"
        (sendChatMessage)="sendChatMessage($event)">
      </ezs-report-details>
      <mat-icon [routerLink]="['/client', backRoute]" class="back-arrow">arrow_back</mat-icon>
    </div>
  