import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  AUTH_ROLE,
  FirebaseTimestamp,
  MessageModel,
  REPORT_STATUS,
  ReportModel,
  REQUEST_STATUS,
  UserModel
} from '@ezspeek/models';
import { AngularFireService } from '@ezspeek/services/angular-fire.service';
import { Toast, ToasterService } from 'angular2-toaster';
import videojs from 'video.js';

export interface UpdateReportPayload {
  data: Partial<ReportModel>;
  aid: string;
  id: string;
}

interface LightboxState {
  show: boolean;
  evidenceType: string;
  evidenceSources: Array<string>;
  currentIndex: number;
}

const initialLightboxState: LightboxState = {
  show: false,
  currentIndex: null,
  evidenceSources: null,
  evidenceType: null
};

@Component({
  selector: 'ezs-report-details',
  templateUrl: './report-details.html',
  styleUrls: ['./report-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportDetailsComponent implements AfterViewChecked {

  reportViewedUpdated = false;
  chatInput = '';
  noteInput = '';
  lightbox: LightboxState = { ...initialLightboxState };

  @Input() report: ReportModel;
  @Input() user: UserModel;
  @Input() sendChatMessageError: string;
  @Input() addNoteError: string;
  @Input() notes: MessageModel[];
  @Input() userPhotos: { photoURL: string, uid: string }[] = [];
  @Input() userDisplayNames: { displayName: string, uid: string }[] = [];

  @Input() set chatMessages(messages: MessageModel[]) {
    this._chatMessages = messages;
    setTimeout(() => {
      this.chatScrollToBottom();
    }, 100);
  }

  @Input() set sendChatMessageStatus(status: REQUEST_STATUS) {
    this._sendChatMessageStatus = status;

    if (status && status === REQUEST_STATUS.SUCCESS) {
      this.chatInput = '';
    }
  }

  @Input() set addNoteStatus(status: REQUEST_STATUS) {
    this._addNoteStatus = status;

    if (status === REQUEST_STATUS.SUCCESS) {
      this.noteInput = '';
    }
  }

  @Output() updateReport: EventEmitter<UpdateReportPayload> = new EventEmitter<UpdateReportPayload>();
  @Output() sendChatMessage: EventEmitter<MessageModel> = new EventEmitter<MessageModel>();
  @Output() addNote: EventEmitter<MessageModel> = new EventEmitter<MessageModel>();
  @Output() addActivity: EventEmitter<MessageModel> = new EventEmitter<MessageModel>();
  @Output() toast: EventEmitter<Toast> = new EventEmitter<Toast>();

  @ViewChild('chatContent') chatContent;

  private _sendChatMessageStatus: REQUEST_STATUS;
  private _addNoteStatus: REQUEST_STATUS;
  private _canUpdateReport = true;
  private _chatMessages: MessageModel[];

  constructor(private toaster: ToasterService) {}

  ngAfterViewChecked() {
    if (this.report) {
      const { aid, id, status, newMessageFromClient, newMessageFromAdmin, viewedBy } = this.report;
      let data: Partial<ReportModel> = {};

      if (this.isClientView) {
        if (newMessageFromAdmin && this._canUpdateReport) {
          data.newMessageFromAdmin = false;
          this._canUpdateReport = false;
        }
      } else {
        if (!this.reportViewedUpdated) {
          const uid = this.user.uid;
          data = {
            lastViewedBy: uid,
            lastViewedOn: AngularFireService.timestamp.now().toMillis()
          };
          if (!viewedBy || (!!viewedBy && !viewedBy[uid])) {
            data['viewedBy'] = { ...viewedBy, [uid]: true };

          }
          this.reportViewedUpdated = true;
        }

        if (this._canUpdateReport) {
          if (status === REPORT_STATUS.NEW) {
            data.status = REPORT_STATUS.VIEWED;
            this._canUpdateReport = false;
          }

          if (newMessageFromClient) {
            data.newMessageFromClient = false;
            this._canUpdateReport = false;
          }
        }
      }

      if (Object.keys(data).length > 0) {
        this.updateReport.emit({ aid, id, data });
        if (!this._canUpdateReport)
          setTimeout(() => {
            this._canUpdateReport = true;
          }, 3000);
      }
    }
  }

  newChatMessage() {
    if (this.isSendChatDisabled)
      return;

    this.sendChatMessage.emit({
      aid: this.report.aid,
      uid: this.user.uid,
      reportId: this.report.id,
      text: this.chatInput,
      authRole: this.user.role
    });
  }

  newNote() {
    if (this.isAddNoteDisabled)
      return;

    this.addNote.emit({
      aid: this.report.aid,
      uid: this.user.uid,
      reportId: this.report.id,
      text: this.noteInput,
      authRole: this.user.role
    });
  }

  chatScrollToBottom() {
    if (!this.chatContent)
      return;

    const chatEl = this.chatContent.nativeElement;
    chatEl.scrollTop = chatEl.scrollHeight;
  }

  resolve() { this._updateStatus(REPORT_STATUS.RESOLVED); }
  markAsFalseReport() { this._updateStatus(REPORT_STATUS.FALSE_REPORT); }
  archive() {
    if (this.isClosed)
      this._update({
        archived: true,
        archivedOn: AngularFireService.timestamp.now().toMillis(),
        archivedBy: this.user.uid
      });
  }

  workIncident() {
    if (this.isActiveUser)
      return;

    if (this.report.status === REPORT_STATUS.VIEWED)
      this._updateStatus(REPORT_STATUS.ACTIVE);
    else {
      const users = this.report.activeUsers;
      this._update({
        activeUsers: [...this.report.activeUsers, this.user.uid]
      });
    }
  }

  submitForReview() { this._updateStatus(REPORT_STATUS.PENDING); }

  viewEvidence(type: string, index: number) {
    this.lightbox = {
      show: true,
      evidenceType: type,
      evidenceSources: this.report.evidence[type.toLowerCase()].map(e => e.url),
      currentIndex: index
    };
  }

  previousAttachment() {
    if (this.lightbox.currentIndex > 0)
      this.lightbox.currentIndex--;
  }

  nextAttachment() {
    if (this.lightbox.currentIndex + 1 < this.lightbox.evidenceSources.length)
      this.lightbox.currentIndex++;
  }

  closeLightbox() {
    this.lightbox = { ...initialLightboxState };
  }

  getPhotoURL(uid: string): string {
    const defaultPhoto = 'assets/images/default-profile-picture.png';
    const user = this.userPhotos.find(admin => admin.uid === uid);

    return !!user ? user.photoURL || defaultPhoto : defaultPhoto;
  }

  getDisplayName(uid: string): string {
    const user = this.userDisplayNames.find(admin => admin.uid === uid);

    return !!user ? user.displayName || '' : '';
  }

  private _update(data: Partial<ReportModel>) {
    this.updateReport.emit({
      aid: this.user.aid,
      id: this.report.id,
      data
    });
  }

  private _updateStatus(status: REPORT_STATUS) {
    const data: Partial<ReportModel> = { status };

    const timestamp = AngularFireService.timestamp.now().toMillis();
    const uid = this.user.uid;

    if (status === REPORT_STATUS.RESOLVED) {
      data.resolvedOn = timestamp;
      data.resolvedBy = uid;
    }

    if (status === REPORT_STATUS.ACTIVE) {
      data.activeUsers = [ uid ];
      data.workStartedOn = timestamp;
      data.workStartedBy = uid;
    }

    if (status === REPORT_STATUS.PENDING) {
      data.submittedForReviewOn = timestamp;
      data.submittedForReviewBy = uid;
    }

    if (status === REPORT_STATUS.FALSE_REPORT) {
      data.flaggedOn = timestamp;
      data.flaggedBy = uid;
    }

    if (!this.isArchived) {
      this._update(data);
    }
  }

  private _isStatus(status: REPORT_STATUS): boolean {
    return this.report.status === status;
  }

  get chatMessages(): MessageModel[] {
    return this._chatMessages || [];
  }

  get isSendChatDisabled(): boolean {
    return this.isSendingChatMessage || !this.chatInput;
  }

  get incidentDate(): number {
    const date = this.report.incidentDate;
    if (typeof date === 'number' )
      return date;

    return (date as FirebaseTimestamp).seconds * 1000;
  }

  get imageAttachments() {
    return this.report.evidence ? this.report.evidence.images : null;
  }

  get videoAttachments() {
    return this.report.evidence ? this.report.evidence.videos : null;
  }

  get audioAttachments() {
    return this.report.evidence ? this.report.evidence.audio : null;
  }

  get otherAttachments() {
    return this.report.evidence ? this.report.evidence.other : null;
  }

  get hasImages(): boolean { return !!this.imageAttachments && this.imageAttachments.length > 0; }

  get hasVideos(): boolean { return !!this.videoAttachments && this.videoAttachments.length > 0; }

  get hasAudio(): boolean { return !!this.audioAttachments && this.audioAttachments.length > 0; }

  get hasOther(): boolean { return !!this.otherAttachments && this.otherAttachments.length > 0; }

  get hasAttachments(): boolean {
    return this.hasImages || this.hasVideos || this.hasAudio || this.hasOther;
  }

  get hasChatMessages(): boolean { return !!this.chatMessages && this.chatMessages.length > 0; }

  get isSendingChatMessage(): boolean { return this._sendChatMessageStatus === REQUEST_STATUS.LOADING; }

  get isClientView(): boolean { return this.user.role === AUTH_ROLE.CLIENT; }

  get isAcctMgr(): boolean { return this.user.role === AUTH_ROLE.ACCOUNT_MGR; }

  get isAddNoteDisabled(): boolean { return this.isAddingNote || !this.noteInput; }

  get isAddingNote(): boolean { return this._addNoteStatus === REQUEST_STATUS.LOADING; }

  get isArchived(): boolean { return this.report.archived; }

  get isFalseReport(): boolean { return this._isStatus(REPORT_STATUS.FALSE_REPORT); }

  get isResolved(): boolean { return this._isStatus(REPORT_STATUS.RESOLVED); }

  get isPending(): boolean { return this._isStatus(REPORT_STATUS.PENDING); }

  get isWorkStarted(): boolean { return this._isStatus(REPORT_STATUS.ACTIVE); }

  get isNew(): boolean { return this._isStatus(REPORT_STATUS.NEW); }

  get isViewed(): boolean { return this._isStatus(REPORT_STATUS.VIEWED); }

  get isOpen(): boolean { return this.isNew || this.isViewed || this.isWorkStarted || this.isPending; }

  get isWaitingToBeWorked(): boolean { return this.isNew || this.isViewed; }

  get showNoteInput(): boolean {
    return !this.isClosed && !this.isArchived && (this.isActiveUser || this.isAcctMgr);
  }

  get showChatInput(): boolean {
    return !this.isClosed && !this.isArchived &&
      (this.isActiveUser || (this.isClientView && (this.isWorkStarted || this.isPending)));
  }

  get showReportMenu(): boolean {
    return !this.isClientView && !this.isArchived && (this.isAcctMgr || this.canWork || this.canSubmit);
  }

  get canWork(): boolean {
    return !this.isActiveUser && (this.isViewed || this.isWorkStarted);
  }

  get canSubmit(): boolean {
    return !this.isAcctMgr && this.isActiveUser && this.isWorkStarted;
  }

  get canClose(): boolean {
    return this.isAcctMgr && (this.isWorkStarted || this.isPending);
  }

  get isClosed(): boolean {
    return this.isFalseReport || this.isResolved;
  }

  get isActiveUser(): boolean {
    const users = this.report.activeUsers;

    return !!users && users.length > 0 && users.indexOf(this.user.uid) >= 0;
  }

  get currentLightboxSrc(): string {
    return this.lightbox.evidenceSources[this.lightbox.currentIndex];
  }
}
