export const firebaseConfig = {
  prod: {
    apiKey: 'AIzaSyC4SALL5Tef93JI7WFxkUvZKPDXTS0vero',
    authDomain: 'ezspeek-prod.firebaseapp.com',
    databaseURL: 'https://ezspeek-prod.firebaseio.com',
    projectId: 'ezspeek-prod',
    storageBucket: 'ezspeek-prod.appspot.com',
    messagingSenderId: '1032938633428'
  },
  dev: {
    apiKey: 'AIzaSyCONSsVB6TSAXCGTorTjti3xk5T8U1sEV0',
    authDomain: 'ezspeek-dev.firebaseapp.com',
    databaseURL: 'https://ezspeek-dev.firebaseio.com',
    projectId: 'ezspeek-dev',
    storageBucket: 'ezspeek-dev.appspot.com',
    messagingSenderId: '335346336947'
  },
  savr: {
    apiKey: 'AIzaSyAollvEMnL9q1E18z8k5PJQeVz-YKO3sOY',
    authDomain: 'savr-app-dev.firebaseapp.com',
    databaseURL: 'https://savr-app-dev.firebaseio.com',
    projectId: 'savr-app-dev',
    storageBucket: 'savr-app-dev.appspot.com',
    messagingSenderId: '664214705106'
  }
};
