
    <client-header
      [displayScrolled]="isMainContentScrolled"
      [user]="user$ | async">
    </client-header>
    <div class="main-content" (scroll)="updateContentScrolled($event)">
      <!--<div [@routerAnimation]="getRouteAnimation(outlet)">-->
        <router-outlet #outlet="outlet"></router-outlet>
      <!--</div>-->
    </div>
  