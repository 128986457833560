import { AppCommonModule } from '@ezspeek/common/app-common.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmailRegistrationContainer } from './email/email-registration.container';
import { EmailRegistrationComponent } from './email/email-registration.component';
import { AccountRegistrationContainer } from './account-registration.container';
import { AccountRegistrationComponent } from './account-registration.component';
import { ContinueRegistrationContainer } from './continue/continue-registration.container';
import { ContinueRegistrationComponent } from './continue/continue-registration.component';

const DECLARATIONS = [
  EmailRegistrationContainer,
  EmailRegistrationComponent,
  AccountRegistrationContainer,
  AccountRegistrationComponent,
  ContinueRegistrationContainer,
  ContinueRegistrationComponent,
];

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    AppCommonModule,
    RouterModule,
  ],
  exports: [
    ...DECLARATIONS
  ]
})
export class AdminRegistrationModule {}

