<div *ngIf="!!user && !accountInactive" class="fadeInUp animated ezs-page-container">
  <h1 class="current-month-stats-heading fadeInUp animated">{{currentMonth}} Overview</h1>
  <div class="monthly-stats-wrapper ezs-incident-status">
    <mat-card class="dashboard-card monthly-stats-card total-reports">
      <mat-card-content>
        <span class="stats-label"><span>Incidents </span>Reported</span>
        <span class="stats-value">{{currentMonthStats.totalReports}}</span>
      </mat-card-content>
      <mat-icon class="stats-icon">bar_chart</mat-icon>
    </mat-card>
    <mat-card class="dashboard-card monthly-stats-card total-open">
      <mat-card-content>
        <span class="stats-label">Open<span> Incidents</span></span>
        <span class="stats-value">{{currentMonthStats.totalOpen}}</span>
      </mat-card-content>
      <mat-icon class="stats-icon">update</mat-icon>
    </mat-card>
    <mat-card class="dashboard-card monthly-stats-card total-resolved fadeInLeft animated">
      <mat-card-content>
        <span class="stats-label"><span>Incidents </span>Resolved</span>
        <span class="stats-value">{{currentMonthStats.totalResolved}}</span>
      </mat-card-content>
      <mat-icon class="stats-icon">event_available</mat-icon>
    </mat-card>
  </div>

  <div [class.showing-chart]="showChart" class="monthly-stats-wrapper ezs-chart-container">
    <ezs-chart *ngIf="showChart" [data]="byCategoryChartData" [config]="byCategoryChartConfig"></ezs-chart>
  </div>

  <div class="reports-list-wrapper">
    <mat-card class="reports-list recent-reports">
      <mat-card-title class="reports-list-header elevation-4"><h3>Recent Incidents</h3></mat-card-title>
      <mat-card-content>
        <ezs-reports-list-item
          *ngFor="let report of recentReports"
          [report]="report"
          [authRole]="user.role">
        </ezs-reports-list-item>
        <div *ngIf="noRecentReports" class="no-reports-message">
          <h2>No reports have been submitted</h2>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="reports-list outstanding-reports">
      <mat-card-title class="reports-list-header elevation-4">
        <h3>Outstanding Incidents</h3>
      </mat-card-title>
      <mat-card-content>
        <ezs-reports-list-item
          *ngFor="let report of outstandingReports"
          [report]="report"
          [authRole]="user.role">
        </ezs-reports-list-item>
        <div *ngIf="noOutstandingReports" class="no-reports-message">
          <h2>Great job! There are no outstanding reports</h2>
        </div>
      </mat-card-content>
    </mat-card>

  </div>

</div>
<div *ngIf="!!user && accountInactive" class="feature-unavailable-message">
  <h2>This account is currently inactive. Please contact customer care for support.</h2>
</div>
