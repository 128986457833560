
    <div class="report-form-wrapper">
      <ezs-report-form
        *ngIf="!showComplete"
        [client]="client$ | async"
        [errorMsg]="sendReportError$ | async"
        (sendReport)="sendReport($event)">
      </ezs-report-form>
      <ezs-report-complete *ngIf="showComplete" (newReport)="showForm()"></ezs-report-complete>
      <mat-icon *ngIf="!showComplete" [routerLink]="['/client', 'dashboard']" class="back-arrow">arrow_back</mat-icon>
    </div>
  